import { BASE_API_URL, headers } from 'api/common';
import { AuthorizedRequest } from 'types/auth';
import { IChangePasswordRequest, IChangeEmailRequest, IFillGetStartedFormRequest } from 'types/users';

const path = 'users';

export const getMe = ({ token }: AuthorizedRequest) =>
    fetch(`${BASE_API_URL}/${path}/i/`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    });

export const changeEmail = ({ token, email, password, user_id }: IChangeEmailRequest) =>
    fetch(`${BASE_API_URL}/${path}/${user_id}/set-email`, {
        method: 'POST',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email, password }),
    });

export const changePassword = ({ token, old_password, new_password, user_id }: IChangePasswordRequest) =>
    fetch(`${BASE_API_URL}/${path}/${user_id}/set-password`, {
        method: 'POST',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ old_password, new_password }),
    });

export const fillGetStartedForm = ({ token, user_id, body }: IFillGetStartedFormRequest) =>
    fetch(`${BASE_API_URL}/${path}/${user_id}/fill-get-started-form`, {
        method: 'POST',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...body }),
    });
