import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { APP_VERSION } from 'utilities/constants';

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        lng: 'en',
        backend: {
            queryStringParams: { v: APP_VERSION },
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        load: 'languageOnly',
        supportedLngs: ['en', 'de', 'fr'],
    });

export default i18n;
