import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import { useDispatch } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';

import ProtectedRoute from 'components/protected_route';
import AvailableAssets from 'containers/available_assets';
import FinishSignup from 'containers/finish_signup';
import GetStarted from 'containers/get_started';
import LogIn from 'containers/log_in';
import NotFound from 'containers/not_found';
import Overview from 'containers/overview';
import Portfolio from 'containers/portfolio';
import ShareTransaction from 'containers/share_transactions';
import SignUp from 'containers/sign_up';
import VerifyEmail from 'containers/verify_email';
import { setMobile } from 'store/platform_slice';
import { MOBILE_DEVICE_BREAKPOINT } from 'utilities/constants';
import { useWindowWidth } from 'utilities/hooks';
import Comparison from 'containers/comparison';
import PipelineStats from 'containers/pipeline_stats';
import Simulation from 'containers/simulation';
import SharedSimulation from 'containers/shared_simulation';

countries.registerLocale(enLocale);
const countries_arr = Object.values(countries.getNames('en', { select: 'official' }));

export default function App() {
    const dispatch = useDispatch();
    const window_width = useWindowWidth();
    dispatch(setMobile(window_width <= MOBILE_DEVICE_BREAKPOINT));

    return (
        <Routes>
            <Route element={<ProtectedRoute />}>
                <Route path="/" element={<Navigate to="/overview" replace />} />
                <Route path="/overview" element={<Overview />} />
                <Route path="/transactions" element={<ShareTransaction />} />
                <Route path="/funds" element={<Portfolio />} />
                <Route path="/wallet" element={<AvailableAssets />} />
                <Route path="/comparison" element={<Comparison />} />
                <Route path="/pipeline" element={<PipelineStats />} />
                <Route path="/simulation" element={<Simulation />} />
            </Route>
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/finish-signup" element={<FinishSignup />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/get-started" element={<GetStarted countries_arr={countries_arr} />} />
            <Route path="/log-in" element={<LogIn />} />
            <Route path="/shared-simulation" element={<SharedSimulation />} />
            {/* TODO: Integrate password change/reset when ready */}
            {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
            {/* <Route path="/choose-password" element={<ChoosePassword />} /> */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}
