import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useFetcher, useInfiniteFetcher } from 'api/fetcher';
import { getFundsList } from 'api/funds';
import { getShareTransactions, getTotalTradedShares } from 'api/share_transactions';
import CustomDatePicker from 'components/custom_date_picker/CustomDatePicker';
import CustomSelectInput from 'components/custom_select_input';
import { RootState } from 'store';
import { ShareTransactionFilter } from 'types/share_transactions';
import { getISODateForDatePicker } from 'utilities/date_time';
import { deleteEmptyValues, strQueryParams } from 'utilities/obj_utils';

import TransactionCard from './TransactionCard';

import styles from './index.module.scss';

type DesktopProps = {
    active_account_id: string;
};
const ShareTransactionMobile = ({ active_account_id }: DesktopProps) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const { t } = useTranslation();
    const [filter_params, setFilterParams] = React.useState<ShareTransactionFilter>({
        fund_name: '',
        start_time: null,
        end_time: null,
    });
    const [mobile_selected_fund, setMobileSelectedFund] = React.useState(null);
    const [mobile_selected_start_date, setMobileSelectedStartDate] = React.useState(null);
    const [mobile_selected_end_date, setMobileSelectedEndDate] = React.useState(null);
    const [filterView, setFilterView] = React.useState(false);
    const [query_params, setQueryParams] = React.useState('');
    const [page_number, setPageNumber] = React.useState(1);
    const [selected_fund_id, setSelectedFundId] = React.useState(null);
    const [selected_fund_name, setSelectedFundName] = React.useState(null);
    const { response: funds } = useFetcher(getFundsList);
    const fund_items = funds?.data?.map((obj) => obj.name || obj.id);
    const { response: traded_shares } = useFetcher(getTotalTradedShares, active_account_id);
    const traded_shares_data = traded_shares?.data;
    const { response: share_transactions } = useInfiniteFetcher(
        getShareTransactions,
        active_account_id,
        null,
        query_params,
        page_number,
    );
    const share_transaction_result = share_transactions?.data?.reduce((acc, curr) => {
        return acc.concat(curr);
    }, []);
    const total_pages = share_transactions?.total_pages;
    // ---> load more button states
    const isLoadingInitialData = !share_transactions;
    const isLoadingMore =
        isLoadingInitialData || (share_transactions && typeof share_transactions.data[page_number - 1] === 'undefined');
    const isEmpty = share_transaction_result?.length === 0;
    const isReachingEnd = isEmpty || page_number === total_pages;
    // ---------
    const handleApplyFilter = () => {
        const { start_time, end_time } = filter_params;
        const tmp = {
            ...filter_params,
            ...(start_time && { start_time: getISODateForDatePicker(start_time) }),
            ...(end_time && { end_time: getISODateForDatePicker(end_time) }),
            page: 1,
        };
        setPageNumber(1);
        const tmpId = funds?.data?.find((fund) => fund.name === tmp.fund_name)?.id || null;
        setSelectedFundId(tmpId);
        setSelectedFundName(tmp.fund_name);
        setQueryParams(strQueryParams(deleteEmptyValues(tmp)));
        setFilterView(false);
    };
    const handleLoadMore = () => {
        const { start_time, end_time } = filter_params;
        setPageNumber(page_number + 1);
        const tmp = {
            ...filter_params,
            ...(start_time && { start_time: getISODateForDatePicker(start_time) }),
            ...(end_time && { end_time: getISODateForDatePicker(end_time) }),
            page: page_number + 1,
        };
        setQueryParams(strQueryParams(deleteEmptyValues(tmp)));
    };

    const handleResetFilter = () => {
        setSelectedFundName(null);
        setSelectedFundId(null);
        setPageNumber(1);
        setFilterParams({ fund_name: '', start_time: null, end_time: null });
        setQueryParams('');
        setFilterView(false);
        setMobileSelectedFund(null);
        setMobileSelectedStartDate(null);
        setMobileSelectedEndDate(null);
    };

    const total_investment = selected_fund_id
        ? traded_shares_data?.[selected_fund_id]?.invested
        : traded_shares_data &&
          Object.values(traded_shares_data).reduce((acc: number, curr: { [x: string]: number }) => {
              acc += curr.invested;
              return acc;
          }, 0);
    const total_divestment = selected_fund_id
        ? traded_shares_data?.[selected_fund_id]?.divested
        : traded_shares_data &&
          Object.values(traded_shares_data).reduce((acc: number, curr: { [x: string]: number }) => {
              acc += curr.divested;
              return acc;
          }, 0);
    const invest_width = (total_investment / (total_divestment + total_investment)) * 80;
    const divest_width = (total_divestment / (total_divestment + total_investment)) * 80;
    return (
        <div className={'mobile-view'}>
            <div
                className={classNames(styles['filters-container'], {
                    [styles['filters-container-view']]: filterView,
                })}
            >
                <div className={styles['back-button-container']}>
                    <div className={styles['back-button']} onClick={() => setFilterView(false)}>
                        <img
                            className={styles['mobile-page-filter-icon']}
                            alt="back icon"
                            src={`img/icons/arrow-left${is_dark_mode ? '-dark' : ''}.svg`}
                        />
                        <div className={styles['back-button-container-text']}>{t('back')}</div>
                    </div>
                </div>
                <div className={styles['fund-input-container']}>
                    {fund_items && (
                        <CustomSelectInput
                            value={filter_params.fund_name}
                            on_change={(item) => setFilterParams((old) => ({ ...old, fund_name: item }))}
                            items={fund_items}
                            input_placeholder={t('share_transactions.fund')}
                            mobile_selected={mobile_selected_fund}
                            setMobileSelected={setMobileSelectedFund}
                        />
                    )}
                </div>
                <CustomDatePicker
                    value={filter_params.start_time}
                    on_change={(e) => setFilterParams((old) => ({ ...old, start_time: e }))}
                    max_date={filter_params.end_time || new Date()}
                    input_placeholder={t('start_date')}
                    mobile_selected={mobile_selected_start_date}
                    setMobileSelected={setMobileSelectedStartDate}
                />
                <CustomDatePicker
                    value={filter_params.end_time}
                    on_change={(e) => setFilterParams((old) => ({ ...old, end_time: e }))}
                    max_date={new Date()}
                    min_date={filter_params.start_time}
                    input_placeholder={t('end_date')}
                    mobile_selected={mobile_selected_end_date}
                    setMobileSelected={setMobileSelectedEndDate}
                />
                <div className={styles['button-container']}>
                    <button
                        className={classNames(styles.submit, 'button-primary', 'button-48')}
                        onClick={handleApplyFilter}
                    >
                        {t('apply')}
                    </button>
                    <button
                        className={classNames(styles.submit, 'button-outline', 'button-48')}
                        onClick={handleResetFilter}
                    >
                        {t('reset')}
                    </button>
                </div>
            </div>

            <div className={classNames({ 'mobile-view-hidden': filterView })}>
                <div className={classNames(styles['mobile-page-head'])}>
                    <div className={styles['mobile-page-title']}>{t('share_transactions.title')}</div>
                    <div className={styles['mobile-page-filter-container']}>
                        <img
                            onClick={() => setFilterView(true)}
                            className={styles['mobile-page-filter-icon']}
                            alt="filter icon"
                            src={`img/icons/filter${is_dark_mode ? '-dark' : ''}.svg`}
                        />
                    </div>
                </div>
                {traded_shares && (
                    <div className={styles['trade-container']}>
                        <div className={styles['data-container']}>
                            <p className={styles['trade-header']}>
                                {selected_fund_name || t('share_transactions.shares')}
                            </p>
                            <div>
                                <span className={styles['trade-type']}>{t('invested')}</span>
                                <div className={styles['bar-container']}>
                                    <div
                                        className={classNames(styles.bar, styles['bar-invest'])}
                                        style={{ width: `${invest_width + 2}%` }}
                                    ></div>
                                    <span className={styles['bar-value']}>{`${total_investment}`}</span>
                                </div>
                            </div>
                            <div>
                                <span className={styles['trade-type']}>{t('divested')}</span>
                                <div className={styles['bar-container']}>
                                    <div
                                        className={classNames(styles.bar, styles['bar-divest'])}
                                        style={{ width: `${divest_width + 2}%` }}
                                    ></div>
                                    <span className={styles['bar-value']}>{`${total_divestment}`}</span>
                                </div>
                            </div>
                        </div>
                        <img alt="trade icon" className={styles['trade-image']} src="img/traded-share-img.svg" />
                    </div>
                )}
                {share_transaction_result?.map((row) => (
                    <TransactionCard t={t} data={row} key={nanoid()} />
                ))}
                <button
                    className={styles['load-more']}
                    disabled={isLoadingMore || isReachingEnd}
                    onClick={handleLoadMore}
                >
                    {isLoadingMore
                        ? t('share_transactions.loading')
                        : isReachingEnd
                        ? t('share_transactions.no_more')
                        : t('share_transactions.load_more')}
                </button>
            </div>
        </div>
    );
};

export default ShareTransactionMobile;
