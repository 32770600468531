import classNames from 'classnames';
import Decimal from 'decimal.js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AssetAllocation from './AssetAllocation';
import ConfigsListModal from './ConfigsListModal';
import Filters from './Filters';
import MonthlyReturnChart from './MonthlyReturnChart';
import ReturnDistribution from './ReturnDistribution';
import { useFetcher, useGetById, usePostFetcher } from 'api/fetcher';
import { getFundsList } from 'api/funds';
import { getIndices } from 'api/indices';
import { getSimulationConfigById, postSimulationConfig } from 'api/simulation';
import LineChart from 'components/line_chart';
import TinyTable from 'components/table/TinyTable';
import { Assets } from 'types/simulation';
import {
    COMPARISON_DEFAULT_PERIOD,
    COMPARISON_METRICS,
    CONSTANT_METRICS,
    FUND_COLORS,
    PERCENT_METRICS,
    PORTFOLIO_COLORS,
} from 'utilities/constants';
import { getISODateForDatePicker } from 'utilities/date_time';

import styles from './index.module.scss';
import { snake_to_readable } from 'utilities/str_utils';
import { useSearchParams } from 'react-router-dom';
import Tooltip from 'components/info_tooltip';

function valid_month_diff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months >= 6;
}
const format_data = (data) => {
    let acc = {};
    Object.entries(data).forEach(([key, obj]) => {
        Object.entries(obj).forEach(([asset_class, value]) => {
            if (acc[asset_class]) acc[asset_class][key] = parseFloat(value);
            else acc[asset_class] = { [key]: parseFloat(value) };
            if (!acc[asset_class]['portfolio_1']) acc[asset_class]['portfolio_1'] = 0;
            if (!acc[asset_class]['portfolio_2']) acc[asset_class]['portfolio_2'] = 0;
            if (!acc[asset_class]['portfolio_3']) acc[asset_class]['portfolio_3'] = 0;
        });
    });
    return acc;
};

const TODAY = new Date();
const Simulation = () => {
    const [search_params] = useSearchParams();
    const simulation_id = search_params.get('id');
    useEffect(() => {
        if (simulation_id) handleClickConfig(simulation_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { t } = useTranslation();
    const [asset_classes, setAssetClasses] = useState([]);
    const [filters, setFilters] = useState({
        initial: 1000,
        range: [COMPARISON_DEFAULT_PERIOD, TODAY],
    });
    const [assets, setAssets] = useState<Assets>({});
    const [configs_modal_open, setConfigsModal] = useState(false);
    const { response: funds } = useFetcher(getFundsList, null, null, null, false);
    const { response: indices } = useFetcher(getIndices, null, null, null, false);

    const valid_funds = funds?.data?.filter((fund) => fund.quote === 'USDT');
    useEffect(() => {
        let indices_tmp = [];
        let funds_tmp = [];
        if (indices?.data) {
            indices_tmp = Object.entries(indices.data)
                .filter(([, value]: [any, { tags: Array<string> }]) => !value.tags.includes('monthly'))
                .map(([idx, value]: [string, { label: string }]) => {
                    return { name: value.label, id: idx };
                });
        }
        if (valid_funds) {
            funds_tmp = valid_funds.map(({ name, id }) => {
                return { name, id };
            });
        }
        setAssetClasses([...funds_tmp, ...indices_tmp]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [funds, indices]);
    const portfo_1_sum = Object.values(assets).reduce((acc, curr) => acc + parseFloat(curr.portfolio_1), 0);
    const portfo_2_sum = Object.values(assets).reduce((acc, curr) => acc + parseFloat(curr.portfolio_2), 0);
    const portfo_3_sum = Object.values(assets).reduce((acc, curr) => acc + parseFloat(curr.portfolio_3), 0);
    const valid_simulation =
        (portfo_1_sum === 100 || portfo_1_sum === 0) &&
        (portfo_2_sum === 100 || portfo_2_sum === 0) &&
        (portfo_3_sum === 100 || portfo_3_sum === 0) &&
        (portfo_1_sum !== 0 || portfo_2_sum !== 0 || portfo_3_sum !== 0);
    const data_preparation = () => {
        let acc = {};
        if (valid_simulation) {
            Object.entries(assets).forEach(([key, obj]) => {
                Object.entries(obj).forEach(([portfo, value]) => {
                    if (parseFloat(value) !== 0) {
                        if (acc[portfo]) acc[portfo][key] = parseFloat(value);
                        else acc[portfo] = { [key]: parseFloat(value) };
                    }
                });
            });
        }
        return acc;
    };
    const {
        response: data,
        setResponse,
        do_fetch,
    } = usePostFetcher(postSimulationConfig, {
        portfolios: data_preparation(),
        start_time: getISODateForDatePicker(filters.range[0]),
        end_time: getISODateForDatePicker(filters.range[1]),
        initial_investment: Number(filters.initial),
    });

    const nav_set = data?.nav_set;

    const total_points =
        nav_set &&
        Object.fromEntries(
            Object.entries(nav_set).map(([key, value]) => {
                return [snake_to_readable(key), { color: PORTFOLIO_COLORS[key], data: value }];
            }),
        );
    const comparison_chart_data =
        total_points &&
        Object.keys(total_points).reduce((acc, curr) => {
            const first_point = total_points[curr].data?.[0];
            acc[curr] = {
                data: total_points[curr].data.map(({ time, value }) => ({
                    time,
                    value: new Decimal(value).dividedBy(new Decimal(first_point?.value)).times(100),
                })),
                color: total_points[curr].color,
            };
            return acc;
        }, {});
    const metrics_data = data?.metrics;
    const metrics_table_data =
        data &&
        (valid_month_diff(new Date(data.start_time), new Date(data.end_time))
            ? metrics_data &&
              Object.keys(metrics_data).map((index) => {
                  const metrics_obj = COMPARISON_METRICS.reduce((acc, curr) => {
                      acc[snake_to_readable(curr)] = `${metrics_data[index][curr]} ${
                          PERCENT_METRICS.includes(curr) ? '%' : ''
                      }`;
                      return acc;
                  }, {});
                  return {
                      [t('simulation.portfolio')]: (
                          <div style={{ color: total_points[snake_to_readable(index)]?.color }}>
                              {snake_to_readable(index)}
                          </div>
                      ),
                      ...metrics_obj,
                  };
              })
            : CONSTANT_METRICS.data.map((metric, idx) => {
                  const metrics_obj = COMPARISON_METRICS.reduce((acc, curr) => {
                      acc[snake_to_readable(curr)] = `${metric[curr]} ${PERCENT_METRICS.includes(curr) ? '%' : ''}`;
                      return acc;
                  }, {});
                  return {
                      [t('simulation.portfolio')]: <div style={{ color: FUND_COLORS[idx] }}>{metric.fund}</div>,
                      ...metrics_obj,
                  };
              }));
    const return_chart_data =
        total_points &&
        Object.keys(total_points).reduce((acc, curr) => {
            const first_point = total_points[curr].data?.[0];
            acc[curr] = {
                data: total_points[curr].data.map(({ time, value }) => ({
                    time,
                    value: new Decimal(value).dividedBy(new Decimal(first_point?.value)).times(data.initial_investment),
                })),
                color: total_points[curr].color,
            };
            return acc;
        }, {});
    const monthly_return_chart_data: { [x: string]: { data: Array<{ value: Decimal; time: string }> } } =
        comparison_chart_data &&
        Object.keys(comparison_chart_data).reduce((acc, curr) => {
            const first_of_every_month = comparison_chart_data[curr].data.filter(
                ({ time }) => new Date(time).getDate() === 1,
            );
            let data = first_of_every_month.map(({ time, value }, idx) => ({
                time,
                value: first_of_every_month[idx + 1]
                    ? new Decimal(first_of_every_month[idx + 1]?.value)
                          .minus(new Decimal(value))
                          .dividedBy(new Decimal(first_of_every_month[idx]?.value))
                          .times(100)
                    : null,
            }));
            data.pop();
            acc[curr] = {
                data,
                color: comparison_chart_data[curr].color,
            };
            return acc;
        }, {});

    const getter = useGetById();
    const handleClickConfig = async (id) => {
        const res = await getter(getSimulationConfigById, { id });
        if (res) {
            setConfigsModal(false);
            setResponse(res);
            setAssets(format_data(res.portfolios));
            setFilters({ range: [new Date(res.start_time), new Date(res.end_time)], initial: res.initial_investment });
        }
    };

    // handle copy to clipboard
    const [is_copied, setIsCopied] = useState(false);
    const copyTextToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    };

    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        const location = window?.location;
        if (location) {
            const lang = search_params.get('lang');
            const text = `${location.host}/shared-simulation?lang=${lang}&id=${data.id}`;
            copyTextToClipboard(text)
                .then(() => {
                    // If successful, update the is_copied state value
                    setIsCopied(true);
                    setTimeout(() => {
                        setIsCopied(false);
                    }, 1500);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    return (
        <>
            <div className={'page-header'}>{t('simulation.main_header')}</div>
            <div className={styles.container}>
                {configs_modal_open && (
                    <ConfigsListModal
                        t={t}
                        styles={styles}
                        handleClickConfig={handleClickConfig}
                        setConfigsModal={setConfigsModal}
                        asset_classes={asset_classes}
                    />
                )}
                <div className={styles.results}>
                    <AssetAllocation
                        asset_classes={asset_classes}
                        styles={styles}
                        assets={assets}
                        setAssets={setAssets}
                        funds={funds}
                        indices={indices}
                        t={t}
                    />
                    {data && (
                        <div className={styles['charts-container']}>
                            {/* Charts --- start */}

                            {return_chart_data && monthly_return_chart_data && (
                                <MonthlyReturnChart
                                    show_legend
                                    points={return_chart_data}
                                    bars={monthly_return_chart_data}
                                    styles={styles}
                                    t={t}
                                    initial_investment={data.initial_investment}
                                />
                            )}
                            <div
                                className={classNames(styles.box, 'box', {
                                    [styles.blurred]: !valid_month_diff(
                                        new Date(data.start_time),
                                        new Date(data.end_time),
                                    ),
                                })}
                            >
                                <div className={classNames(styles.header, 'box-header')}>
                                    <div className={'box-header_main'}>{t('simulation.metrics_comparison')}</div>
                                    <Tooltip info={t('docs.comparison.metrics')} />
                                </div>
                                {metrics_table_data && <TinyTable data={metrics_table_data} />}
                                {!valid_month_diff(new Date(data.start_time), new Date(data.end_time)) && (
                                    <div className={styles['no-return']}>{t('simulation.no_metrics')}</div>
                                )}
                            </div>
                            <div className={classNames(styles.box, 'box')}>
                                <div className={classNames(styles.header, 'box-header')}>
                                    <div className={'box-header_main'}>{t('simulation.navps_comparison')}</div>
                                    <Tooltip info={t('docs.comparison.navps')} />
                                </div>
                                <div className={styles['chart-container']}>
                                    {comparison_chart_data &&
                                        Object.keys(comparison_chart_data).every((key) => key !== 'undefined') && (
                                            <LineChart points={comparison_chart_data} show_legend />
                                        )}
                                </div>
                            </div>

                            {comparison_chart_data && (
                                <ReturnDistribution data={comparison_chart_data} styles={styles} t={t} show_legend />
                            )}
                            {/* Charts --- end */}
                        </div>
                    )}
                </div>
                <div className={styles.filters}>
                    {indices && funds && (
                        <Filters
                            styles={styles}
                            funds={funds}
                            indices={indices}
                            filters={filters}
                            setFilters={setFilters}
                            assets={assets}
                            handleApplyFilter={do_fetch}
                            valid_simulation={valid_simulation}
                            t={t}
                        />
                    )}
                    <div>
                        <div className={styles['button-container']}>
                            <button
                                className={classNames(styles.apply, 'button-outline', 'button-48')}
                                onClick={() => setConfigsModal(true)}
                            >
                                {t('simulation.recents')}
                            </button>
                        </div>
                        {data?.id && (
                            <div className={styles['button-container']}>
                                <button
                                    className={classNames(styles.apply, 'button-outline', 'button-48')}
                                    onClick={handleCopyClick}
                                >
                                    {t('simulation.share')}
                                </button>
                            </div>
                        )}
                        {is_copied && <div className={styles.copy}>{t('simulation.copied')}</div>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Simulation;
