import classNames from 'classnames';

import styles from './index.module.scss';

const ToggleButton = ({ left, right, state, setState, left_label, right_label }) => {
    const handleToggle = () => {
        setState((prev) => {
            if (prev === left) return right;
            else return left;
        });
    };
    return (
        <div className={styles.container}>
            <input
                type="checkbox"
                className={styles.checkbox}
                id="checkbox-button"
                readOnly
                checked={state === right}
                onClick={handleToggle}
            />
            <label htmlFor="checkbox-button" className={styles.label}>
                <div className={styles.ball}></div>
                <div className={classNames(styles.toggle, styles.left)}>
                    <div className={classNames({ [styles['toggle-word']]: state === right })}>{left_label}</div>
                </div>
                <div className={classNames(styles.toggle, styles.right)}>
                    <div className={classNames({ [styles['toggle-word']]: state === left })}>{right_label}</div>
                </div>
            </label>
        </div>
    );
};

export default ToggleButton;
