import { BASE_API_URL, BASE_API_URL_V2, headers } from 'api/common';
import { AuthorizedRequest } from 'types/auth';
import { NAVRequest } from 'types/accounts';

const path = 'accounts';

export const getAccounts = ({ token }: AuthorizedRequest) =>
    fetch(`${BASE_API_URL}/${path}/`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    });

export const getHistoricalNAV = ({ token, account_id, query_string }: NAVRequest) =>
    account_id &&
    fetch(`${BASE_API_URL_V2}/${path}/${account_id}/historical-nav/${query_string}`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    });
