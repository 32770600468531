import classNames from 'classnames';
import { FieldMetaProps } from 'formik';
import React, { ReactNode, useRef, useState } from 'react';
import { useClickOutside } from 'utilities/hooks';

import styles from './index.module.scss';

type DropdownSelectorProps = {
    icon?: ReactNode;
    is_light?: boolean;
    items: Array<string>;
    label?: string;
    meta?: FieldMetaProps<string>;
    onChange: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void;
    required: boolean;
    [x: string]: any;
};

export default function DropdownSelector({
    icon,
    is_light = false,
    items,
    label,
    meta,
    onChange,
    required,
    ...props
}: DropdownSelectorProps) {
    const [is_open, setOpen] = useState(false);

    const wrapper_ref = useRef<HTMLDivElement>(null);
    useClickOutside(wrapper_ref, () => setOpen(false));

    return (
        <div className={styles['input-section']} ref={wrapper_ref}>
            <p
                className={classNames(styles['input-label'], {
                    [styles['input-label--light']]: is_light,
                })}
            >
                {label} {required && '*'}
            </p>
            <div
                className={classNames(styles['input-container'], {
                    [styles['input-container--light']]: is_light,
                })}
                onClick={() => setOpen(true)}
            >
                <>
                    {icon && <span className={styles.icon}>{icon}</span>}
                    <input
                        className={classNames(styles.input, {
                            [styles['input--light']]: is_light,
                            [styles['input--error']]: required && meta.touched && meta.error,
                            [styles['input--has_icon']]: !!icon,
                        })}
                        autoComplete="off"
                        {...props}
                        onChange={(e) => onChange(e, '')}
                    />
                    <img
                        alt="arrow down"
                        className={classNames(styles.arrow_icon, styles[`arrow_icon-${is_open ? 'open' : 'close'}`])}
                        src={`/img/icons/arrow-down${!is_light ? '-dark' : ''}.svg`}
                    />
                </>
            </div>
            <div
                className={classNames(
                    styles['items-container'],
                    styles[`items-container-${is_open ? 'open' : 'close'}`],
                    {
                        [styles['items-container--light']]: is_light,
                    },
                    {
                        [styles['items-container--with-label']]: !!label,
                    },
                )}
            >
                {items.map((item) => {
                    return (
                        <div
                            onClick={() => {
                                onChange(null, item);
                                setOpen(false);
                            }}
                            className={classNames(styles.item, {
                                [styles['item--light']]: is_light,
                            })}
                            key={item}
                        >
                            {item}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
