import { useFetcher } from 'api/fetcher';
import { getHistoricalIndices } from 'api/indices';
import classNames from 'classnames';
import Tooltip from 'components/info_tooltip';
import Decimal from 'decimal.js';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import { useSelector } from 'react-redux';

import { RootState } from 'store';
import { T } from 'types/common';
import { INDICE_COLORS, MIN_MAX_RETURN, RETURN_DISTRIBUTION_BIN_RANGE } from 'utilities/constants';
import { getISODateForDatePicker, getZeroTime } from 'utilities/date_time';
import { bins_array, histogram } from 'utilities/histogram';
import { strQueryParams } from 'utilities/obj_utils';

type Props = {
    show_legend?: boolean;
    navps_points: { [x: string]: { data: Array<{ value: Decimal; time: string }>; color: string } };
    selected_indices: Array<string>;
    indices_labels: { [x: string]: { label: string } };
    range: Array<Date>;
    styles: { readonly [key: string]: string };
    t: T;
};

const ReturnDistribution = ({
    navps_points,
    selected_indices,
    indices_labels,
    range,
    styles,
    t,
    show_legend = false,
}: Props) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const { response: indices_historical } = useFetcher(
        getHistoricalIndices,
        null,
        null,
        strQueryParams({
            is_gap_filled: false,
            start_time: getISODateForDatePicker(getZeroTime(range[0]), 'not splitted'),
            end_time: getISODateForDatePicker(getZeroTime(range[1]), 'not splitted'),
            symbols: selected_indices,
        }),
        false,
    );
    const indices_points =
        indices_historical?.data &&
        Object.keys(indices_historical.data).reduce((acc, curr, idx) => {
            acc[indices_labels?.[curr].label] = {
                data: indices_historical.data[curr].data,
                color: INDICE_COLORS[idx],
            };
            return acc;
        }, {});
    const data = { ...navps_points, ...indices_points };

    const return_data: { [x: string]: { data: Array<Decimal>; color: string } } =
        data &&
        Object.keys(data).reduce((acc, curr) => {
            let tmp_data = data[curr].data.map(({ value }, idx) =>
                data[curr].data[idx + 1]
                    ? new Decimal(data[curr].data[idx + 1]?.value)
                          .minus(new Decimal(value))
                          .dividedBy(new Decimal(data[curr].data[idx]?.value))
                          .times(100)
                          .toNumber()
                    : null,
            );
            tmp_data.pop();
            const filter_data = tmp_data.filter((value) => value <= MIN_MAX_RETURN && value >= -MIN_MAX_RETURN);
            acc[curr] = {
                data: filter_data,
                color: data[curr].color,
            };
            return acc;
        }, {});
    const total_percentages =
        return_data && Object.values(return_data).reduce((acc, curr) => acc.concat(curr.data), []);
    const maximum = total_percentages && Math.ceil(Math.max(...total_percentages));
    const minimum = total_percentages && Math.ceil(Math.min(...total_percentages));
    const values =
        return_data &&
        maximum &&
        minimum &&
        Object.keys(return_data).reduce((acc, curr) => {
            let tmp_data = histogram(return_data[curr].data, RETURN_DISTRIBUTION_BIN_RANGE, minimum, maximum);
            acc[curr] = {
                data: tmp_data,
                color: return_data[curr].color,
            };
            return acc;
        }, {});
    const bins = maximum && minimum && bins_array(minimum, maximum, RETURN_DISTRIBUTION_BIN_RANGE);
    const chartData =
        values &&
        Object.keys(values).map((trace) => ({
            name: trace,
            data: values[trace].data,
            type: 'line',
            emphasis: {
                focus: 'none',
            },
            ...(values[trace].color
                ? { lineStyle: { color: 'transparent' }, itemStyle: { color: values[trace].color } }
                : {}),
            showSymbol: false,
            smooth: true,
            areaStyle: { opacity: 0.55 },
        }));
    const option: EChartsOption = values &&
        bins && {
            grid: {
                top: show_legend ? 50 : 15,
                left: 0,
                right: 5,
                bottom: 10,
                width: 'auto',
                containLabel: true,
            },
            backgroundColor: 'transparent',
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'line',
                    animation: false,
                },
                textStyle: { color: `${is_dark_mode ? '#CED4DA' : '#868E96'}`, fontSize: '1rem' },
                borderColor: `${is_dark_mode ? '#111018' : '#F7F9FB'}`,
                backgroundColor: `${is_dark_mode ? '#111018' : '#F7F9FB'}`,
            },
            xAxis: {
                type: 'category',
                data: bins,
                splitLine: {
                    show: false,
                },
                boundaryGap: false,
                axisPointer: { label: { show: false } },
                axisTick: { show: false },
                axisLine: { show: false },
                axisLabel: { margin: 20 },
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    lineStyle: {
                        color: `${is_dark_mode ? '#495057' : '#E9ECEF'}`,
                        type: 'dashed',
                    },
                },
                axisPointer: { label: { show: false } },
                scale: true,
            },
            dataZoom: [{ type: 'inside' }],
            series: chartData,
            color: ['#B57BFF', '#FEC37B', '#FF7B7B', '#7B9EFD', '#7BFD9F'],
            ...(show_legend
                ? {
                      legend: {
                          show: show_legend,
                          padding: [0, 0, 250, 0],
                          icon: 'circle',
                          width: '80%',
                          itemWidth: 8,
                          textStyle: { fontSize: 10 },
                          data: Object.keys(values).map((trace) => ({
                              name: trace,
                              textStyle: { color: values[trace].color },
                          })),
                      },
                  }
                : {}),
        };
    return (
        <div className={classNames(styles.box, 'box')}>
            <div className={classNames(styles.header, 'box-header')}>
                <div className={'box-header_main'}>{t('comparison.return_distribution')}</div>
                <Tooltip info={t('docs.comparison.return_distribution')} />
            </div>
            <div style={{ height: '45rem' }}>
                {option && (
                    <ReactEcharts
                        theme={is_dark_mode ? 'dark' : 'light'}
                        option={option}
                        notMerge={true}
                        style={{ height: '100%', width: '100%' }}
                    />
                )}
            </div>
        </div>
    );
};

export default ReturnDistribution;
