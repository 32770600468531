const getFormattedDateTime = (date_param: object) => {
    let date_obj;
    if (typeof date_param === 'string') {
        date_obj = new Date(date_param);
    } else {
        date_obj = date_param;
    }
    const date = date_obj.toLocaleDateString('en-US');
    const time = date_obj.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
    });
    return { date, time };
};

const getISODateForDatePicker = (date_param: Date, split: 'splitted' | 'not splitted' = 'splitted') => {
    const offset = date_param.getTimezoneOffset();
    if (split === 'splitted') return new Date(date_param.getTime() - offset * 60 * 1000).toISOString().split('T')[0];
    return new Date(date_param.getTime() - offset * 60 * 1000).toISOString();
};

const getZeroTime = (date: Date) => {
    date.setHours(0, 0, 0, 0);
    return date;
};

export { getFormattedDateTime, getISODateForDatePicker, getZeroTime };
