import styles from './index.module.scss';

export default function BouncingDotsLoader() {
    return (
        <div className={styles['bouncing-loader']}>
            <div />
            <div />
            <div />
        </div>
    );
}
