import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import DesktopSignup from 'components/desktop_signup';
import MobileSignup from 'components/mobile_signup';
import { RootState } from 'store';
import { setGoogleClientParams } from 'store/platform_slice';
import { setTheme } from 'utilities/DOM';

export default function SignUp() {
    const { is_logged_in } = useSelector((state: RootState) => state.client);
    const { is_dark_mode, google_client_params, is_mobile } = useSelector((state: RootState) => state.platform);

    const is_dispatched = useRef(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!is_dispatched.current && !google_client_params?.client_id) {
            dispatch(setGoogleClientParams());
            is_dispatched.current = true;
        }
    }, [dispatch, google_client_params?.client_id]);

    useEffect(() => {
        if (is_dark_mode) {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    }, [is_dark_mode]);

    if (is_logged_in) return <Navigate to="/overview" replace />;

    return is_mobile ? <MobileSignup is_dark_mode={is_dark_mode} /> : <DesktopSignup is_dark_mode={is_dark_mode} />;
}
