import InvestDivest from './invest_divest';
import Nav from './nav';
import NavpsComparison from './navps_comparison';
import Portfolio from './portfolio';

const OverviewMobile = ({
    styles,
    pie_chart_data,
    table_data,
    total_available_value,
    total_fund_value,
    applyNAVPSPeriod,
    fund_colors,
    funds,
    navps_chart_data,
    navps_list,
    getfundName,
    traded_shares,
    t,
}) => {
    return (
        <div className={'mobile-view'}>
            <div className={styles.column}>
                <Nav t={t} quote="USDT" />
                <Nav t={t} quote="BTC" />
                <NavpsComparison
                    applyNAVPSPeriod={applyNAVPSPeriod}
                    fund_colors={fund_colors}
                    funds={funds}
                    navps_chart_data={navps_chart_data}
                    navps_list={navps_list}
                    styles={styles}
                    t={t}
                />
                <Portfolio
                    pie_chart_data={pie_chart_data}
                    styles={styles}
                    table_data={table_data}
                    total_available_value={total_available_value}
                    total_fund_value={total_fund_value}
                    t={t}
                />
                <InvestDivest
                    fund_colors={fund_colors}
                    getfundName={getfundName}
                    styles={styles}
                    traded_shares={traded_shares}
                    t={t}
                />
            </div>
        </div>
    );
};

export default OverviewMobile;
