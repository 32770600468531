import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import GoogleOAuth from 'components/google_oauth';
import LanguagePicker from 'components/i18n';
// import SignupForm from 'components/signup_form';
import SignupIntro from 'components/signup_intro';
import TermsPolicy from 'components/terms_policy';
import ThemeToggle from 'components/theme_toggle';
import { RIGHTS } from 'utilities/constants';

import styles from './index.module.scss';

type DesktopSignupProps = {
    is_dark_mode: boolean;
};

export default function DesktopSignup({ is_dark_mode }: DesktopSignupProps) {
    const { t } = useTranslation();

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <SignupIntro show_term_privacy={false} is_dark_mode={is_dark_mode} t={t} />
                {/* <div className={styles.separator} /> */}
                <GoogleOAuth />
                <div className={styles['already-have-account']}>
                    <p>
                        <Link to="/log-in">
                            <span>{t('signup.local.have_account')}</span>
                        </Link>
                    </p>
                </div>
                <div className={styles['terms-policy-container']}>
                    <TermsPolicy t={t} />
                </div>
                {/* <div className={styles['form-container']}>
                    <SignupForm is_dark_mode={is_dark_mode} />
                    <div className={styles['form-separator']}>
                        <div className={styles['form-separator-line']} />
                        <span>{t('or')}</span>
                        <div className={styles['form-separator-line']} />
                    </div>
                </div> */}
            </div>
            <div className={styles.options}>
                <LanguagePicker should_update_query_string={false} />
                <ThemeToggle />
            </div>
            <span className={styles.rights}>{RIGHTS}</span>
        </div>
    );
}
