import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthorizedRequest } from 'types/auth';
import { UserInfo, IChangePasswordRequest, IChangeEmailRequest } from 'types/users';

interface IUserState {
    is_me_loading: boolean;
    is_change_email_loading: boolean;
    is_change_password_loading: boolean;
    change_email_err_msg: string;
    change_password_err_msg: string;
    change_email_success_msg: string;
    change_password_success_msg: string;
    info: UserInfo | null;
}

const initial_state: IUserState = {
    is_me_loading: false,
    is_change_email_loading: false,
    is_change_password_loading: false,
    change_email_err_msg: '',
    change_password_err_msg: '',
    change_email_success_msg: '',
    change_password_success_msg: '',
    info: null,
};

export const user_slice = createSlice({
    name: 'user',
    initialState: initial_state,
    reducers: {
        getMe: (state: IUserState, action: PayloadAction<AuthorizedRequest>) => {
            state.is_me_loading = true;
        },
        getMeSuccess: (state: IUserState, action: PayloadAction<UserInfo>) => {
            state.info = action.payload;
            state.is_me_loading = false;
        },
        changeEmail: (state: IUserState, action: PayloadAction<IChangeEmailRequest>) => {
            state.change_email_err_msg = '';
            state.change_email_success_msg = '';
            state.is_change_email_loading = true;
        },
        changeEmailSuccess: (state: IUserState, action: PayloadAction<string>) => {
            state.change_email_success_msg = action.payload;
            state.is_change_email_loading = false;
        },
        changeEmailFailure: (state: IUserState, action: PayloadAction<string>) => {
            state.change_email_err_msg = action.payload;
            state.is_change_email_loading = false;
        },
        changePassword: (state: IUserState, action: PayloadAction<IChangePasswordRequest>) => {
            state.change_password_err_msg = '';
            state.change_password_success_msg = '';
            state.is_change_password_loading = true;
        },
        changePasswordSuccess: (state: IUserState, action: PayloadAction<string>) => {
            state.change_password_success_msg = action.payload;
            state.is_change_password_loading = false;
        },
        changePasswordFailure: (state: IUserState, action: PayloadAction<string>) => {
            state.change_password_err_msg = action.payload;
            state.is_change_password_loading = false;
        },
        clearInfo: (state: IUserState, action: PayloadAction) => {
            state.info = null;
        },
        clearState: (state: IUserState, action: PayloadAction) => {
            state.change_email_err_msg = '';
            state.change_email_success_msg = '';
            state.change_password_err_msg = '';
            state.change_password_success_msg = '';
        },
    },
});

export const {
    getMe,
    getMeSuccess,
    changeEmail,
    changeEmailSuccess,
    changeEmailFailure,
    changePassword,
    changePasswordSuccess,
    changePasswordFailure,
    clearInfo,
    clearState,
} = user_slice.actions;

export default user_slice.reducer;
