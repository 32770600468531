import classNames from 'classnames';
import Decimal from 'decimal.js';

import Capsule from 'components/capsule';
import PieChart from 'components/pie_chart';
import TinyTable from 'components/table/TinyTable';
import { T } from 'types/common';
import { getDecimalFormat } from 'utilities/str_utils';
import Tooltip from 'components/info_tooltip';

type PortfolioProps = {
    pie_chart_data: any;
    styles: { readonly [key: string]: string };
    table_data: any;
    total_available_value: any;
    total_fund_value: any;
    t: T;
};

const Portfolio = ({
    pie_chart_data,
    styles,
    table_data,
    total_available_value,
    total_fund_value,
    t,
}: PortfolioProps) => {
    const summation =
        total_available_value && total_fund_value
            ? new Decimal(total_available_value).plus(new Decimal(total_fund_value))
            : 0;
    return (
        <div>
            <div className={classNames('box')}>
                <div className={classNames('box-header', 'box-header-mobile')}>
                    <div className={classNames('box-header_main', 'box-header_main-mobile')}>
                        {t('overview.portfolio')}
                    </div>
                    <Tooltip info={t('docs.overview.portfolio')} />
                </div>
                <div className={styles['chart-desktop-view']} style={{ height: '28rem' }}>
                    {pie_chart_data && (
                        <PieChart
                            t={t}
                            data={pie_chart_data}
                            configs={{
                                chart_center: ['75%', 140],
                                chart_radius: [75, 95],
                                legend_position: { left: 10, top: 'center', bottom: 0, right: 'auto' },
                                title_position: { top: 115, left: '74.5%', bottom: 'auto', right: 'auto' },
                            }}
                        />
                    )}
                </div>
                <div className={styles['chart-mobile-view']} style={{ height: '38rem' }}>
                    {pie_chart_data && (
                        <PieChart
                            t={t}
                            data={pie_chart_data}
                            configs={{
                                chart_center: ['50%', 100],
                                chart_radius: [70, 90],
                                legend_position: { left: 'center', top: 210, bottom: 'auto', right: 'auto' },
                                title_position: { top: '75', left: '49%', bottom: 'auto', right: '50%' },
                            }}
                        />
                    )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ maxHeight: '20rem', display: 'flex' }}>
                        {table_data && <TinyTable data={table_data} />}
                    </div>
                    <Capsule
                        key_string={t('fund_assets.total_fund')}
                        value={`₮ ${getDecimalFormat(total_fund_value)}`}
                    />
                    <Capsule
                        key_string={t('fund_assets.total_available')}
                        value={`₮ ${getDecimalFormat(total_available_value)}`}
                    />
                    <Capsule key_string={t('fund_assets.summation')} value={`₮ ${getDecimalFormat(summation)}`} />
                </div>
            </div>
        </div>
    );
};

export default Portfolio;
