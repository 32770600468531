import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import * as api from 'api/auth';
import { postLoginFailure, postLoginSuccess, refreshSuccess, setLoggedOut } from 'store/client_slice';
import {
    ConfirmRegistrationRequest,
    GoogleLoginRequest,
    LoginRequest,
    LoginResponse,
    RefreshRequest,
    RefreshResponse,
} from 'types/auth';

function* postLogin(action: PayloadAction<LoginRequest>) {
    const { username, password } = action.payload;
    const response: Response = yield call(() => api.logIn({ username, password }));
    switch (response.status) {
        case 200:
            const formatted: LoginResponse = yield response.json();
            yield put(postLoginSuccess(formatted));
            break;
        case 401:
            yield put(postLoginFailure('login.messages.401'));
            break;
        default:
            yield put(postLoginFailure('login.messages.500'));
            break;
    }
}

function* postGoogleLogin(action: PayloadAction<GoogleLoginRequest>) {
    const { authorization_code, redirect_uri } = action.payload;
    const response: Response = yield call(() => api.googleLogIn({ authorization_code, redirect_uri }));

    switch (response.status) {
        case 200:
            const formatted: LoginResponse = yield response.json();
            yield put(postLoginSuccess(formatted));
            break;
        case 401:
            yield put(postLoginFailure('login.messages.401'));
            break;
        default:
            yield put(postLoginFailure('login.messages.500'));
            break;
    }
}

function* postConfirmRegistration(action: PayloadAction<ConfirmRegistrationRequest>) {
    const { token } = action.payload;
    const response: Response = yield call(() => api.confirmRegistration({ token }));

    switch (response.status) {
        case 200:
            const formatted: LoginResponse = yield response.json();
            yield put(postLoginSuccess(formatted));
            break;
        case 401:
            yield put(postLoginFailure('login.messages.401'));
            break;
        default:
            yield put(postLoginFailure('login.messages.500'));
            break;
    }
}

function* postRefresh(action: PayloadAction<RefreshRequest>) {
    const { refresh } = action.payload;
    const response: Response = yield call(() => api.refresh({ refresh }));
    switch (response.status) {
        case 200:
            const formatted: RefreshResponse = yield response.json();
            yield put(refreshSuccess(formatted));
            break;
        default:
            // Log out the user so they can log in again
            yield put(setLoggedOut());
            break;
    }
}

export { postLogin, postGoogleLogin, postConfirmRegistration, postRefresh };
