import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import CustomErrorMessage from 'components/custom_error';
import PasswordInput from 'components/password_input';
import { RootState } from 'store';
import { changePassword } from 'store/user_slice';

const getChangePasswordSchema = (t: TFunction) =>
    Yup.object().shape({
        old_password: Yup.string().trim().required(t('require_password')),
        new_password: Yup.string().trim().required(t('require_password')),
    });

interface IChangePasswordValues extends FormikValues {
    old_password: string;
    new_password: string;
}

const ChangePassword = ({ styles }) => {
    const { jwt_token } = useSelector((state: RootState) => state.client);
    const { info, is_change_password_loading, change_password_err_msg, change_password_success_msg } = useSelector(
        (state: RootState) => state.user,
    );
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handlePasswordSubmit = (values: IChangePasswordValues) => {
        const { old_password, new_password } = values;
        dispatch(changePassword({ old_password, new_password, token: jwt_token, user_id: info?.id }));
    };

    return (
        <React.Fragment>
            <Formik
                initialValues={{ old_password: '', new_password: '' }}
                validationSchema={getChangePasswordSchema(t)}
                onSubmit={handlePasswordSubmit}
            >
                {(props) => (
                    <Form>
                        <div className={styles['old-password-container']}>
                            <p className={styles['input-label']}>{t('settings.old_password_label')}</p>
                            <Field name="old_password">
                                {({ field, meta }) => (
                                    <PasswordInput
                                        placeholder={t('settings.old_password_placeholder')}
                                        autoComplete="off"
                                        meta={meta}
                                        {...field}
                                    />
                                )}
                            </Field>
                            <ErrorMessage children={(msg) => <CustomErrorMessage msg={msg} />} name="old_password" />
                        </div>
                        <div className={styles['new-password-container']}>
                            <p className={styles['input-label']}>{t('settings.new_password_label')}</p>
                            <Field name="new_password">
                                {({ field, meta }) => (
                                    <PasswordInput
                                        placeholder={t('settings.new_password_placeholder')}
                                        autoComplete="off"
                                        meta={meta}
                                        {...field}
                                    />
                                )}
                            </Field>
                            <ErrorMessage children={(msg) => <CustomErrorMessage msg={msg} />} name="new_password" />
                        </div>
                        {change_password_err_msg && (
                            <div className={styles['server-error']}>{t(change_password_err_msg)}</div>
                        )}
                        {change_password_success_msg && (
                            <div className={styles['server-success']}>{t(change_password_success_msg)}</div>
                        )}
                        <div>
                            <button
                                type="submit"
                                className={classNames(styles.submit, 'button-primary', 'button-48')}
                                disabled={!(props.isValid && props.dirty) || is_change_password_loading}
                            >
                                <span>{t('submit')}</span>
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default ChangePassword;
