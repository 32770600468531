import classNames from 'classnames';
import Decimal from 'decimal.js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ConfigNameModal from './ConfigNameModal';
import ConfigsListModal from './ConfigsListModal';
import Filters from './Filters';
import MonthlyReturnChart from './MonthlyReturnChart';
import ReturnDistribution from './ReturnDistribution';
import { deleteComparisonConfig, getComparisonConfigs, postComparisonConfig, postComparisonLog } from 'api/comparison';
import { useFetcher, usePostFetcher } from 'api/fetcher';
import { getAllFundsHistoricalNAVPS, getAllFundsMetrics, getFundsList } from 'api/funds';
import { getHistoricalIndices, getIndices, getIndicesMetrics } from 'api/indices';
import { useListFetcher } from 'api/listFetcher';
import LineChart from 'components/line_chart';
import TinyTable from 'components/table/TinyTable';
import { RootState } from 'store';
import { getISODateForDatePicker, getZeroTime } from 'utilities/date_time';
import { strQueryParams } from 'utilities/obj_utils';
import {
    COMPARISON_DEFAULT_PERIOD,
    COMPARISON_METRICS,
    CONSTANT_METRICS,
    FUND_COLORS,
    INDICE_COLORS,
    PERCENT_METRICS,
} from 'utilities/constants';

import styles from './index.module.scss';
import { snake_to_readable } from 'utilities/str_utils';
import Tooltip from 'components/info_tooltip';

function valid_month_diff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months >= 6;
}

const TODAY = getZeroTime(new Date());
const DEFAULT_PERIOD = getZeroTime(COMPARISON_DEFAULT_PERIOD);
const Comparison = () => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState({
        funds: [],
        indices: [],
        initial: 1000,
        range: [DEFAULT_PERIOD, TODAY],
    });
    const { response: funds } = useFetcher(getFundsList, null, null, null, false);
    const getfundName = (id) => {
        return funds?.data?.find((obj) => id === obj.id)?.name || id;
    };
    const { response: indices } = useFetcher(getIndices, null, null, null, false);
    const [range, setRange] = useState([DEFAULT_PERIOD, TODAY]);
    const [selected_funds, setSelectedFunds] = useState([]);
    const [selected_indices, setSelectedIndices] = useState([]);
    const [initial_investment, setInitialInvestment] = useState(1000);
    const { jwt_token } = useSelector((state: RootState) => state.client);
    const { do_fetch: log } = usePostFetcher(postComparisonLog, {
        data: JSON.stringify({
            funds: selected_funds,
            indices: selected_indices,
            initial: initial_investment,
            range,
        }),
    });
    const [configs_modal_open, setConfigsModal] = useState(false);
    const [config_name_modal_open, setConfigNameModal] = useState(false);
    const [is_loading, setLoading] = useState(false);
    const [config_name, setConfigName] = useState('');
    const [save_config_message, setMessage] = useState('');
    const { response: configs, do_fetch: refresh_configs } = useFetcher(getComparisonConfigs, null, null, null, false);

    const { response: funds_navps } = useListFetcher(
        getAllFundsHistoricalNAVPS,
        selected_funds,
        strQueryParams({ start_time: getISODateForDatePicker(range[0]), end_time: getISODateForDatePicker(range[1]) }),
        [selected_funds],
    );
    const { response: funds_metrics } = useListFetcher(
        getAllFundsMetrics,
        selected_funds,
        strQueryParams({ start_time: getISODateForDatePicker(range[0]), end_time: getISODateForDatePicker(range[1]) }),
        [selected_funds],
    );
    const { response: indices_historical } = useFetcher(
        getHistoricalIndices,
        null,
        null,
        strQueryParams({
            start_time: getISODateForDatePicker(getZeroTime(range[0]), 'not splitted'),
            end_time: getISODateForDatePicker(getZeroTime(range[1]), 'not splitted'),
            symbols: selected_indices,
        }),
        false,
    );
    const { response: indices_metrics } = useFetcher(
        getIndicesMetrics,
        null,
        null,
        strQueryParams({
            start_time: getISODateForDatePicker(getZeroTime(range[0]), 'not splitted'),
            end_time: getISODateForDatePicker(getZeroTime(range[1]), 'not splitted'),
            symbols: selected_indices,
        }),
        false,
    );
    const indices_points =
        indices_historical?.data &&
        Object.keys(indices_historical.data).reduce((acc, curr, idx) => {
            acc[indices.data[curr].label] = {
                data: indices_historical.data[curr].data,
                color: INDICE_COLORS[idx],
            };
            return acc;
        }, {});
    const navps_points = funds_navps?.data?.reduce((acc, curr, idx) => {
        acc[getfundName(selected_funds[idx])] = { color: FUND_COLORS[idx], data: curr };
        return acc;
    }, {});
    const total_points = { ...navps_points, ...indices_points };
    const comparison_chart_data =
        total_points &&
        Object.keys(total_points).reduce((acc, curr) => {
            const first_point = total_points[curr].data?.[0];
            acc[curr] = {
                data: total_points[curr].data.map(({ time, value }) => ({
                    time,
                    value: new Decimal(value).dividedBy(new Decimal(first_point?.value)).times(100),
                })),
                color: total_points[curr].color,
            };
            return acc;
        }, {});
    const funds_metrics_table_data = valid_month_diff(range[0], range[1])
        ? funds_metrics?.data?.map((metric, idx) => {
              const metrics_obj = COMPARISON_METRICS.reduce((acc, curr) => {
                  acc[snake_to_readable(curr)] = `${metric[curr]} ${PERCENT_METRICS.includes(curr) ? '%' : ''}`;
                  return acc;
              }, {});
              return {
                  [t('comparison.fund_index')]: (
                      <div style={{ color: total_points[getfundName(selected_funds[idx])]?.color }}>
                          {getfundName(selected_funds[idx])}
                      </div>
                  ),
                  ...metrics_obj,
              };
          })
        : CONSTANT_METRICS.data.map((metric, idx) => {
              const metrics_obj = COMPARISON_METRICS.reduce((acc, curr) => {
                  acc[snake_to_readable(curr)] = `${metric[curr]} ${PERCENT_METRICS.includes(curr) ? '%' : ''}`;
                  return acc;
              }, {});
              return {
                  [t('comparison.fund_index')]: <div style={{ color: FUND_COLORS[idx] }}>{metric.fund}</div>,
                  ...metrics_obj,
              };
          });
    const indices_metrics_table_data = valid_month_diff(range[0], range[1])
        ? indices_metrics?.data &&
          Object.keys(indices_metrics.data).map((index) => {
              const metrics_obj = COMPARISON_METRICS.reduce((acc, curr) => {
                  acc[snake_to_readable(curr)] = `${indices_metrics.data[index][curr]}  ${
                      PERCENT_METRICS.includes(curr) ? '%' : ''
                  }`;
                  return acc;
              }, {});
              return {
                  [t('comparison.fund_index')]: (
                      <div style={{ color: total_points[indices.data[index].label]?.color }}>
                          {indices?.data?.[index].label}
                      </div>
                  ),
                  ...metrics_obj,
              };
          })
        : [];
    const table_data = funds_metrics_table_data &&
        indices_metrics_table_data && [...funds_metrics_table_data, ...indices_metrics_table_data];
    const return_chart_data =
        total_points &&
        Object.keys(total_points).reduce((acc, curr) => {
            const first_point = total_points[curr].data?.[0];
            acc[curr] = {
                data: total_points[curr].data.map(({ time, value }) => ({
                    time,
                    value: new Decimal(value).dividedBy(new Decimal(first_point?.value)).times(initial_investment),
                })),
                color: total_points[curr].color,
            };
            return acc;
        }, {});
    const monthly_return_chart_data: { [x: string]: { data: Array<{ value: Decimal; time: string }> } } =
        comparison_chart_data &&
        Object.keys(comparison_chart_data).reduce((acc, curr) => {
            const first_of_every_month = comparison_chart_data[curr].data.filter(
                ({ time }) => new Date(time).getDate() === 1,
            );
            let data = first_of_every_month.map(({ time, value }, idx) => ({
                time,
                value: first_of_every_month[idx + 1]
                    ? new Decimal(first_of_every_month[idx + 1]?.value)
                          .minus(new Decimal(value))
                          .dividedBy(new Decimal(first_of_every_month[idx]?.value))
                          .times(100)
                    : null,
            }));
            data.pop();
            acc[curr] = {
                data,
                color: comparison_chart_data[curr].color,
            };
            return acc;
        }, {});
    const handleApplyFilter = () => {
        setSelectedFunds([...filters.funds]);
        setSelectedIndices([...filters.indices]);
        setInitialInvestment(filters.initial);
        setRange([...filters.range]);
        log();
    };
    const {
        response: saved,
        setResponse: setSaved,
        do_fetch: save_config,
    } = usePostFetcher(postComparisonConfig, {
        name: config_name,
        data: JSON.stringify({
            funds: selected_funds,
            indices: selected_indices,
            initial: initial_investment,
            range,
        }),
    });
    const handleSaveFilter = async () => {
        save_config();
        setLoading(true);
    };
    useEffect(() => {
        if (saved) {
            setLoading(false);
            setMessage('comparison.saved_message');
            setTimeout(() => {
                setMessage('');
                setConfigNameModal(false);
                setConfigName('');
                refresh_configs();
                setSaved(null);
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saved]);
    const handleDeleteConfig = async (id) => {
        const res = await deleteComparisonConfig({
            token: jwt_token,
            id,
        });
        if (res?.ok) {
            refresh_configs();
        }
    };
    const handleClickConfig = (data) => {
        setSelectedFunds([...data.funds]);
        setSelectedIndices([...data.indices]);
        setInitialInvestment(data.initial);
        setRange([...data.range.map((date) => new Date(date))]);
        setFilters({ ...data, range: [...data.range.map((date) => new Date(date))] });
        setConfigsModal(false);
    };
    const distribution_indices = selected_indices.filter((index) => !indices?.data[index].tags.includes('monthly'));
    const distribution_indices_labels = distribution_indices?.reduce((acc, curr) => {
        acc[curr] = { label: indices?.data[curr].label };
        return acc;
    }, {});
    return (
        <>
            <div className={'page-header'}>{t('comparison.main_header')}</div>
            <div className={classNames(styles.container)}>
                {config_name_modal_open && (
                    <ConfigNameModal
                        setConfigName={setConfigName}
                        setConfigNameModal={setConfigNameModal}
                        config_name={config_name}
                        save_config_message={save_config_message}
                        styles={styles}
                        is_loading={is_loading}
                        handleSaveFilter={handleSaveFilter}
                        t={t}
                    />
                )}
                {configs_modal_open && (
                    <ConfigsListModal
                        t={t}
                        styles={styles}
                        handleClickConfig={handleClickConfig}
                        handleDeleteConfig={handleDeleteConfig}
                        configs={configs}
                        setConfigsModal={setConfigsModal}
                    />
                )}
                <div className={styles['results']}>
                    {return_chart_data && monthly_return_chart_data && (
                        <MonthlyReturnChart
                            show_legend
                            points={return_chart_data}
                            bars={monthly_return_chart_data}
                            styles={styles}
                            t={t}
                            initial_investment={initial_investment}
                        />
                    )}
                    <div
                        className={classNames(styles.box, 'box', {
                            [styles.blurred]: !valid_month_diff(range[0], range[1]),
                        })}
                    >
                        <div className={classNames(styles.header, 'box-header')}>
                            <div className={'box-header_main'}>{t('comparison.metrics_comparison')}</div>
                            <Tooltip info={t('docs.comparison.metrics')} />
                        </div>
                        {table_data && <TinyTable data={table_data} />}
                        {!valid_month_diff(range[0], range[1]) && (
                            <div className={styles['no-return']}>{t('comparison.no_metrics')}</div>
                        )}
                    </div>
                    <div className={classNames(styles.box, 'box')}>
                        <div className={classNames(styles.header, 'box-header')}>
                            <div className={'box-header_main'}>{t('comparison.navps_comparison')}</div>
                            <Tooltip info={t('docs.comparison.navps')} />
                        </div>
                        <div className={styles['chart-container']}>
                            {comparison_chart_data &&
                                indices_historical?.data &&
                                selected_funds?.length === funds_navps?.data?.length &&
                                selected_indices?.length === Object.keys(indices_historical?.data).length &&
                                Object.keys(comparison_chart_data).every((key) => key !== 'undefined') && (
                                    <LineChart points={comparison_chart_data} show_legend />
                                )}
                        </div>
                    </div>

                    {navps_points && (
                        <ReturnDistribution
                            navps_points={navps_points}
                            selected_indices={distribution_indices}
                            indices_labels={distribution_indices_labels}
                            range={range}
                            styles={styles}
                            t={t}
                            show_legend
                        />
                    )}
                </div>
                <div className={styles['filters']}>
                    <Filters
                        styles={styles}
                        funds={funds}
                        indices={indices}
                        filters={filters}
                        setFilters={setFilters}
                        setSelectedFunds={setSelectedFunds}
                        handleApplyFilter={handleApplyFilter}
                    />
                    <div className={styles['button-container']}>
                        <button
                            className={classNames(styles.apply, 'button-outline', 'button-48')}
                            onClick={() => setConfigNameModal(true)}
                        >
                            {t('comparison.filters.save')}
                        </button>
                        {configs?.data?.length > 0 && (
                            <button
                                className={classNames(styles.apply, 'button-outline', 'button-48')}
                                onClick={() => setConfigsModal(true)}
                            >
                                {t('comparison.filters.load')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Comparison;
