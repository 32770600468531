import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { fillGetStartedForm } from 'api/users';
import CustomErrorMessage from 'components/custom_error';
import CustomInput from 'components/custom_input';
import DropdownSelector from 'components/dropdown_selector';
import LoaderSpinner from 'components/loader_spinner';
import SignupLoginWrapper from 'components/signup_login_wrapper';
import { RootState } from 'store';
import { getMe } from 'store/user_slice';
import { setTheme } from 'utilities/DOM';

import styles from './index.module.scss';

interface IGetStartedValues extends FormikValues {
    company: string;
    country: string;
    crypto_aum: string;
    non_crypto_aum: string;
    role: string;
    referral_source: string;
}

const getStartedSchema = (t: TFunction) =>
    Yup.object().shape({
        company: Yup.string().trim().min(1).max(128).required(t('get_started.require_company')),
        role: Yup.string().trim().min(1).max(128).required(t('get_started.require_role')),
        referral_source: Yup.string().trim().min(1).max(128),
        country: Yup.string().trim().min(2).max(128).required(t('get_started.require_country')),
        crypto_aum: Yup.string().trim().min(1).max(32),
        non_crypto_aum: Yup.string().trim().min(1).max(32),
    });

type GetStartedProps = {
    countries_arr: Array<string>;
};

export default function GetStarted({ countries_arr }: GetStartedProps) {
    const [countries_list, setCountriesList] = useState(countries_arr);
    const [accepted_terms_policy, setAcceptedTermsPolicy] = useState(false);
    const [is_loading, setLoading] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const { jwt_token } = useSelector((state: RootState) => state.client);
    const { info } = useSelector((state: RootState) => state.user);
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const is_dispatched = useRef(false); // Prevent retriggering API call

    useEffect(() => {
        if (is_dark_mode) {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    }, [is_dark_mode]);

    useEffect(() => {
        if (!is_dispatched.current && !info?.id) {
            dispatch(getMe({ token: jwt_token }));
            is_dispatched.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, jwt_token]);

    const handleSubmit = async (values: IGetStartedValues) => {
        const body = { ...values };
        Object.keys(body).forEach((k) => {
            if (typeof body[k] === 'string' && body[k].length === 0) {
                body[k] = null;
            } else if (body[k] === 0) {
                body[k] = true;
            } else if (body[k] === 1) {
                body[k] = false;
            }
        });
        setLoading(true);
        setErrorMessage('');

        try {
            const response = await fillGetStartedForm({ token: jwt_token, user_id: info?.id, body });
            if (response.ok) {
                localStorage.setItem('client.get_started_form', 'done');
                navigate('/overview');
            } else if (response.status === 401) navigate('/log-in');
            else setErrorMessage('login.messages.500');
        } catch (error) {
            setErrorMessage('login.messages.500');
        } finally {
            setLoading(false);
        }
    };

    let submit_btn_content: JSX.Element | string;
    if (is_loading) {
        submit_btn_content = (
            <span className={styles['btn-content']}>
                <LoaderSpinner is_dark_mode={true} />
            </span>
        );
    } else {
        submit_btn_content = t('get_started.submit');
    }

    return (
        <SignupLoginWrapper is_dark_mode={is_dark_mode} use_small_logo>
            <>
                <div className={styles.form}>
                    <Formik
                        initialValues={{
                            company: '',
                            role: '',
                            referral_source: '',
                            country: '',
                            crypto_aum: '',
                            non_crypto_aum: '',
                        }}
                        validationSchema={getStartedSchema(t)}
                        onSubmit={handleSubmit}
                    >
                        {(props) => (
                            <Form>
                                <div className={styles['form-container']}>
                                    <div>
                                        <p className={styles['form-description']}>
                                            {t('get_started.congrat')}
                                            <br />
                                            {t('get_started.next_step')}
                                        </p>

                                        <div className={styles.row}>
                                            <div className={classNames(styles.company, styles.field)}>
                                                <p className={styles['input-label']}>
                                                    {t('get_started.company') + '*'}
                                                </p>
                                                <Field name="company">
                                                    {({ field, meta }) => (
                                                        <div>
                                                            <CustomInput
                                                                required
                                                                type="text"
                                                                placeholder={t('get_started.company_name')}
                                                                meta={meta}
                                                                {...field}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <ErrorMessage
                                                    children={(msg) => <CustomErrorMessage msg={msg} />}
                                                    name="company"
                                                />
                                            </div>
                                            <div className={classNames(styles.company, styles.field)}>
                                                <p className={styles['input-label']}>{t('get_started.role') + '*'}</p>
                                                <Field name="role">
                                                    {({ field, meta }) => (
                                                        <div>
                                                            <CustomInput
                                                                required
                                                                type="text"
                                                                placeholder={t('get_started.your_role')}
                                                                meta={meta}
                                                                {...field}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <ErrorMessage
                                                    children={(msg) => <CustomErrorMessage msg={msg} />}
                                                    name="role"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={classNames(styles.crypto_aum, styles.field)}>
                                                <Field name="crypto_aum">
                                                    {({ field, meta }) => (
                                                        <div>
                                                            <DropdownSelector
                                                                readOnly="readonly"
                                                                is_light={!is_dark_mode}
                                                                items={[
                                                                    '0',
                                                                    '> $0',
                                                                    '> $100K',
                                                                    '> $500K',
                                                                    '> $1M',
                                                                    '> $5M',
                                                                ]}
                                                                label={t('get_started.crypto_aum')}
                                                                placeholder={t('get_started.select_crypto_aum')}
                                                                type="text"
                                                                meta={meta}
                                                                {...field}
                                                                onChange={(e, crypto_aum) => {
                                                                    if (!e)
                                                                        props.setFieldValue('crypto_aum', crypto_aum);
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className={classNames(styles.non_crypto_aum, styles.field)}>
                                                <Field name="non_crypto_aum">
                                                    {({ field, meta }) => (
                                                        <div>
                                                            <DropdownSelector
                                                                readOnly="readonly"
                                                                is_light={!is_dark_mode}
                                                                items={[
                                                                    '0',
                                                                    '> $0',
                                                                    '> $100K',
                                                                    '> $500K',
                                                                    '> $1M',
                                                                    '> $5M',
                                                                ]}
                                                                label={t('get_started.non_crypto_aum')}
                                                                placeholder={t('get_started.select_non_crypto_aum')}
                                                                type="text"
                                                                meta={meta}
                                                                {...field}
                                                                onChange={(e, crypto_aum) => {
                                                                    if (!e)
                                                                        props.setFieldValue(
                                                                            'non_crypto_aum',
                                                                            crypto_aum,
                                                                        );
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={classNames(styles.country, styles.field)}>
                                                <Field name="country">
                                                    {({ field, meta }) => (
                                                        <div>
                                                            <DropdownSelector
                                                                is_light={!is_dark_mode}
                                                                items={countries_list}
                                                                placeholder={t('get_started.select_country')}
                                                                label={t('get_started.country')}
                                                                type="text"
                                                                meta={meta}
                                                                {...field}
                                                                onChange={(e, country) => {
                                                                    if (!e) props.setFieldValue('country', country);
                                                                    else {
                                                                        props.setFieldValue('country', e.target.value);
                                                                        const filtered = countries_arr.filter((item) =>
                                                                            item
                                                                                .toLowerCase()
                                                                                .includes(e.target.value.toLowerCase()),
                                                                        );
                                                                        setCountriesList(filtered);
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <ErrorMessage
                                                    children={(message) => <CustomErrorMessage msg={message} />}
                                                    name="country"
                                                />
                                            </div>
                                            <div className={classNames(styles.country, styles.field)}>
                                                <Field name="referral_source">
                                                    {({ field, meta }) => (
                                                        <div>
                                                            <DropdownSelector
                                                                readOnly="readonly"
                                                                is_light={!is_dark_mode}
                                                                items={[
                                                                    'Product Hunt',
                                                                    'Social Media',
                                                                    'Referrals',
                                                                    'Other',
                                                                ]}
                                                                label={t('get_started.referral')}
                                                                placeholder={t('get_started.select_referral')}
                                                                type="text"
                                                                meta={meta}
                                                                {...field}
                                                                onChange={(e, referral_source) => {
                                                                    if (!e)
                                                                        props.setFieldValue(
                                                                            'referral_source',
                                                                            referral_source,
                                                                        );
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <ErrorMessage
                                                    children={(message) => <CustomErrorMessage msg={message} />}
                                                    name="referral_source"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <p className={styles['checkbox-item']}>
                                        <img
                                            onClick={() => {
                                                setAcceptedTermsPolicy((prev) => !prev);
                                            }}
                                            alt="checkbox icon"
                                            src={`/img/icons/${accepted_terms_policy ? 'selected' : 'unselected'}.svg`}
                                        />
                                        <span>
                                            <Trans
                                                i18nKey="get_started.notice"
                                                t={t}
                                                components={[
                                                    <a
                                                        className={styles.privacy}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        href="https://eveince.com/terms-conditions"
                                                    />,
                                                    <a
                                                        className={styles.privacy}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        href="https://eveince.com/privacy-policy"
                                                    />,
                                                ]}
                                            />
                                        </span>
                                    </p>
                                    <div>
                                        {error_message && (
                                            <div className={styles['server-error']}>{t(error_message)}.</div>
                                        )}
                                        <button
                                            type="submit"
                                            className={classNames(styles.submit, 'button-primary', 'button-48')}
                                            disabled={
                                                !(props.isValid && props.dirty && accepted_terms_policy) || is_loading
                                            }
                                        >
                                            <span>{submit_btn_content}</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </>
        </SignupLoginWrapper>
    );
}
