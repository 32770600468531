import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export interface IResponseInfo {
    data: any;
    has_error: boolean;
}

export const useListFetcher = (getter, list, params = '', dependencies = []) => {
    const [response, setResponse] = React.useState({} as IResponseInfo);
    const { jwt_token } = useSelector((state: RootState) => state.client);

    React.useEffect(() => {
        if (list) {
            const fetch = async () => {
                const res = await getter({
                    token: jwt_token,
                    list,
                    query_string: params,
                });
                if (res) {
                    setResponse({ data: res, has_error: false });
                }
            };
            fetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, jwt_token, ...dependencies]);

    return { response };
};
