import { useEffect, useState } from 'react';

import { T } from 'types/common';

import styles from './index.module.scss';

type TimerProps = {
    onStop: () => void;
    t: T;
    total_time: number; // in seconds
};

export default function Timer({ onStop, t, total_time }: TimerProps) {
    const [seconds, setSeconds] = useState(total_time);

    useEffect(() => {
        const timer = setInterval(() => {
            setSeconds((old) => {
                localStorage.setItem('platform.timer', old.toString());
                return old - 1;
            });
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    if (seconds === 0) {
        localStorage.removeItem('platform.timer');
        onStop();
        return null;
    }
    const whole_minutes = Math.floor(seconds / 60);
    const remaining_minutes = whole_minutes ? `${String(whole_minutes).padStart(2, '0')}:` : '';
    const remaining_seconds = String(seconds % 60).padStart(2, '0');
    const time_left = remaining_minutes + remaining_seconds;

    return (
        <span className={styles.container}>
            <span className={styles.remaining}>{time_left}</span>
            &nbsp;
            {whole_minutes === 0 ? <span className={styles.seconds}>{t('seconds')}</span> : ''}
        </span>
    );
}
