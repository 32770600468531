import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GoogleClientParams } from 'types/common';
import { MOBILE_DEVICE_BREAKPOINT } from 'utilities/constants';

interface IPlatformState {
    google_client_params: GoogleClientParams;
    has_google_error: boolean;
    is_dark_mode: boolean;
    is_google_loading: boolean;
    is_mobile: boolean;
}

const is_dark_mode = localStorage.getItem('platform.theme') === 'dark';
const is_mobile = window.innerWidth <= MOBILE_DEVICE_BREAKPOINT;
const platform_google = localStorage.getItem('platform.google');
const google_client_params = platform_google !== null ? JSON.parse(platform_google) : null;

const initial_state: IPlatformState = {
    google_client_params,
    has_google_error: false,
    is_dark_mode,
    is_google_loading: false,
    is_mobile,
};

export const platform_slice = createSlice({
    name: 'platform',
    initialState: initial_state,
    reducers: {
        toggleTheme: (state: IPlatformState) => {
            const new_theme = state.is_dark_mode ? 'light' : 'dark';
            localStorage.setItem('platform.theme', new_theme);
            state.is_dark_mode = !state.is_dark_mode;
        },
        setMobile: (state: IPlatformState, action: PayloadAction<boolean>) => {
            state.is_mobile = action.payload;
        },
        setGoogleClientParams: (state: IPlatformState) => {
            state.is_google_loading = true;
            state.has_google_error = false;
        },
        setGoogleClientParamsSuccess: (state: IPlatformState, action: PayloadAction<GoogleClientParams>) => {
            state.is_google_loading = false;
            state.has_google_error = false;
            state.google_client_params = action.payload;
            localStorage.setItem('platform.google', JSON.stringify(action.payload));
        },
        setGoogleClientParamsFailure: (state: IPlatformState) => {
            state.is_google_loading = false;
            state.has_google_error = true;
            localStorage.removeItem('platform.google');
        },
    },
});

export const {
    setMobile,
    toggleTheme,
    setGoogleClientParams,
    setGoogleClientParamsSuccess,
    setGoogleClientParamsFailure,
} = platform_slice.actions;

export default platform_slice.reducer;
