import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store';
import { T } from 'types/common';
import { getCurrencyImg } from 'utilities/currency';
import { getFormattedDateTime } from 'utilities/date_time';

import styles from './index.module.scss';

type TransactionCardProps = {
    data: {
        time: Date;
        assets: { [x: string]: string };
        share_count: number;
        fund_name: string;
    };
    t: T;
};
const TransactionCard = ({ data, t }: TransactionCardProps) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const [open, setOpen] = React.useState(false);
    const { date, time } = getFormattedDateTime(data.time);
    const need_dropdown = Object.keys(data.assets).length > 1;
    const need_elipsis = Object.keys(data.assets).length > 3;
    return (
        <div className={styles['card-container']}>
            <div className={styles['card-action']}>
                {data.share_count > 0 ? t('share_transactions.investment') : t('share_transactions.divestment')}
            </div>
            <div className={styles['card-header']}>
                <div className={styles['card-fund']}>{data.fund_name.trim()}</div>
                <div className={styles['card-date']}>{date + ' ' + time}</div>
            </div>
            <div
                className={classNames(styles['card-details'], { [styles['card-details-open']]: open })}
                onClick={() => setOpen(!open)}
            >
                <div className={styles['card-details-closed']}>
                    {need_dropdown && (
                        <div className={classNames(styles['card-details-currency-row'], { [styles.hidden]: open })}>
                            {Object.keys(data.assets)
                                .slice(0, 3)
                                .map((key) => (
                                    <img
                                        key={key}
                                        className={styles['currency-img']}
                                        src={getCurrencyImg(key)}
                                        alt={key}
                                    />
                                ))}
                            {need_elipsis && <div>...</div>}
                        </div>
                    )}
                    {!need_dropdown && (
                        <div>
                            {Object.keys(data.assets)
                                .map((key) => ({
                                    name: key,
                                    value: +data.assets[key],
                                }))
                                .map((asset) => (
                                    <p className={styles.currency} key={asset.name}>
                                        <img
                                            className={styles['currency-img']}
                                            src={getCurrencyImg(asset.name)}
                                            alt={asset.name}
                                        />
                                        <span className={styles['currency-name']}>{asset.name}</span>
                                        <span className={styles['currency-value']}>{asset.value}</span>
                                    </p>
                                ))}
                        </div>
                    )}
                    <div className={styles['share-count-part']}>
                        <div className={styles['number']}>{Math.abs(data.share_count)}</div>
                        <div className={styles['count']}>{t('share_transactions.count')}</div>
                        {need_dropdown && (
                            <img
                                className={`arrow${open ? '-open' : ''}`}
                                alt="down arrow"
                                src={`img/icons/arrow-down${is_dark_mode ? '-dark' : ''}.svg`}
                            />
                        )}
                    </div>
                </div>
                {open && (
                    <div className={styles.assets}>
                        {Object.keys(data.assets)
                            .map((key) => ({
                                name: key,
                                value: +data.assets[key],
                            }))
                            .map((asset) => (
                                <p className={styles.currency} key={asset.name}>
                                    <img
                                        className={styles['currency-image-table']}
                                        src={getCurrencyImg(asset.name)}
                                        alt={asset.name}
                                    />
                                    <span className={styles['currency-name']}>{asset.name}</span>
                                    <span className={styles['currency-value']}>{asset.value}</span>
                                </p>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TransactionCard;
