import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import * as api from 'api/accounts';
import { authorizationHandler, getAccountsFailure, getAccountsSuccess } from 'store/client_slice';
import { Account } from 'types/accounts';
import { AuthorizedRequest } from 'types/auth';
import { PaginationResponse } from 'types/common';

function* getAccounts(action: PayloadAction<AuthorizedRequest>) {
    const { token } = action.payload;
    const response: Response = yield call(() => api.getAccounts({ token }));
    switch (response.status) {
        case 200:
            const formatted: PaginationResponse<Account> = yield response.json();
            yield put(getAccountsSuccess(formatted));
            break;
        case 401:
            yield put(authorizationHandler({ type: 'client/getAccounts' }));
            break;
        default:
            yield put(getAccountsFailure('Something went wrong'));
            break;
    }
}

export { getAccounts };
