import AssetRow from './AssetRow';

import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DropdownSelector from 'components/dropdown_selector';
import { RootState } from 'store';
import { AssetAllocationProps } from 'types/share_simulation';
import { PORTFOLIO_COLORS } from 'utilities/constants';

const getAssetsNameByid = (id, arr: Array<{ id: string; name: string }>) => {
    return arr.find((asset) => asset.id === id)?.name;
};
const AssetAllocation = ({ styles, asset_classes, assets, setAssets, funds, t }: AssetAllocationProps) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const [need_login, setNeedLogin] = useState(false);
    const handleChange = () => {
        setNeedLogin(true);
        setTimeout(() => {
            setNeedLogin(false);
        }, 20000);
    };
    const [filtered_assets, setFilteredAssets] = useState([]);
    const valid_funds = funds?.data?.filter((fund) => fund.quote === 'USDT');
    useEffect(() => {
        if (valid_funds) {
            const tmp = { ...assets };
            valid_funds.forEach((fund) => {
                tmp[fund.id] = { portfolio_1: '0', portfolio_2: '0', portfolio_3: '0' };
            });
            setAssets(tmp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [funds]);
    useEffect(() => {
        setFilteredAssets(asset_classes.map((asset) => asset.name));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset_classes]);

    const handleIndices = useCallback(() => {
        const filtered = asset_classes.filter((item) => !Object.keys(assets).includes(item.id)).map((idx) => idx.name);
        setFilteredAssets(filtered);
    }, [assets, asset_classes]);
    const portfo_1_sum = Object.values(assets).reduce((acc, curr) => acc + parseFloat(curr.portfolio_1), 0);
    const portfo_2_sum = Object.values(assets).reduce((acc, curr) => acc + parseFloat(curr.portfolio_2), 0);
    const portfo_3_sum = Object.values(assets).reduce((acc, curr) => acc + parseFloat(curr.portfolio_3), 0);

    return (
        <div className="box">
            <div className={classNames(styles.header, 'box-header')}>
                <div className={'box-header_main'}>{t('simulation.asset_allocation')}</div>
            </div>
            <div className={classNames(styles.row, styles['row--head'])}>
                <div className={classNames(styles.col, styles['head-row'])}>Asset Class</div>
                <div
                    className={classNames(styles.col, styles['head-row'])}
                    style={{ color: PORTFOLIO_COLORS.portfolio_1 }}
                >
                    Portfolio 1
                </div>
                <div
                    className={classNames(styles.col, styles['head-row'])}
                    style={{ color: PORTFOLIO_COLORS.portfolio_2 }}
                >
                    Portfolio 2
                </div>
                <div
                    className={classNames(styles.col, styles['head-row'])}
                    style={{ color: PORTFOLIO_COLORS.portfolio_3 }}
                >
                    Portfolio 3
                </div>
            </div>
            {Object.keys(assets).map((asset) => {
                return (
                    <AssetRow
                        key={asset}
                        assets={assets}
                        handleChange={handleChange}
                        styles={styles}
                        asset_class_name={getAssetsNameByid(asset, asset_classes)}
                        asset_class_id={asset}
                    />
                );
            })}
            <div className={styles.row}>
                <div className={classNames(styles.col, styles['col--dropdown'])}>
                    <DropdownSelector
                        icon={<img width="16px" alt="search" src="img/icons/search-normal.svg" />}
                        is_light={!is_dark_mode}
                        items={filtered_assets}
                        placeholder={t('simulation.add_asset')}
                        type="text"
                        onChange={handleChange}
                        required={false}
                        onClick={handleIndices}
                    />
                </div>
                <div className={classNames(styles.col, styles['col--last'])}>
                    <div className={styles['portfo']} style={{ color: PORTFOLIO_COLORS.portfolio_1 }}>
                        Portfolio 1
                    </div>
                    <div className={classNames(styles.sum, { [styles['sum--error']]: portfo_1_sum > 100 })}>
                        {portfo_1_sum}%
                    </div>
                </div>
                <div className={classNames(styles.col, styles['col--last'])}>
                    <div className={styles['portfo']} style={{ color: PORTFOLIO_COLORS.portfolio_2 }}>
                        Portfolio 2
                    </div>
                    <div className={classNames(styles.sum, { [styles['sum--error']]: portfo_2_sum > 100 })}>
                        {portfo_2_sum}%
                    </div>
                </div>
                <div className={classNames(styles.col, styles['col--last'])}>
                    <div className={styles['portfo']} style={{ color: PORTFOLIO_COLORS.portfolio_3 }}>
                        Portfolio 3
                    </div>
                    <div className={classNames(styles.sum, { [styles['sum--error']]: portfo_3_sum > 100 })}>
                        {portfo_3_sum}%
                    </div>
                </div>
            </div>
            <div className={styles['need-login']}>
                {need_login ? (
                    <>
                        <img alt="error" src="img/icons/Error.svg" />
                        {t('simulation.asset_error')}
                    </>
                ) : (
                    ' '
                )}
            </div>
        </div>
    );
};

export default AssetAllocation;
