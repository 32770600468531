import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import BottomSidebar from 'components/bottom_side_bar';
import CustomRangePicker from 'components/custom_range_picker';
import { RootState } from 'store';
import { useClickOutside } from 'utilities/hooks';

import styles from './index.module.scss';

type InputProps = {
    set_period: (e: any) => void;
    full_screen?: boolean;
    stats?: boolean;
};

const TODAY = new Date();
const PERIODS = ['week', 'month', 'year', 'custom'];

const PeriodSelect = ({ set_period, full_screen, stats }: InputProps) => {
    const { is_dark_mode, is_mobile } = useSelector((state: RootState) => state.platform);
    const { t } = useTranslation();
    const [is_open, setOpen] = React.useState(false);
    const [selected_period, setSelectedPeriod] = React.useState('year');
    const [mobile_selected_period, setMobileSelectedPeriod] = React.useState(selected_period);
    const [range, setRange] = React.useState([null, null]);
    const wrapper_ref = React.useRef<HTMLDivElement>(null);

    useClickOutside(wrapper_ref, () => setOpen(false));
    const [is_modal_open, setModalOpen] = React.useState(false);

    const handleClose = () => {
        setSelectedPeriod(selected_period);
        setModalOpen(false);
    };

    const handleClick = (item) => {
        setSelectedPeriod(item);
        switch (item) {
            case 'week':
                if (stats) set_period('_D7');
                else {
                    set_period({
                        start_time: new Date(new Date().setDate(TODAY.getDate() - 7)),
                        end_time: TODAY,
                    });
                    setModalOpen(false);
                    setRange([null, null]);
                }
                setOpen(false);
                break;
            case 'month':
                if (stats) set_period('_D30');
                else {
                    set_period({
                        start_time: new Date(new Date().setDate(TODAY.getDate() - 30)),
                        end_time: TODAY,
                    });
                    setModalOpen(false);
                    setRange([null, null]);
                }
                setOpen(false);
                break;
            case 'year':
                if (stats) set_period('_D365');
                else {
                    set_period({
                        start_time: new Date(new Date().setDate(TODAY.getDate() - 365)),
                        end_time: TODAY,
                    });
                    setModalOpen(false);
                    setRange([null, null]);
                }
                setOpen(false);
                break;
            case 'custom':
                !is_mobile && setOpen(false);
                setModalOpen(true);
                break;
        }
    };

    const handleApply = () => {
        set_period({
            start_time: range[0],
            end_time: range[1],
        });
        setModalOpen(false);
        setOpen(false);
    };

    return (
        <>
            <div className={styles.date} ref={wrapper_ref}>
                {is_modal_open && !is_mobile && (
                    <>
                        <div className={styles.modal} onClick={handleClose}></div>
                        <div className={styles['modal-main']}>
                            <CustomRangePicker range={range} setRange={setRange} />
                            <div className={styles.buttons}>
                                <button
                                    type="submit"
                                    className={classNames('button-primary', 'button-40')}
                                    onClick={handleApply}
                                >
                                    <span>{t('apply')}</span>
                                </button>
                                <button className={classNames('button-outline', 'button-40')} onClick={handleClose}>
                                    <span>{t('close')}</span>
                                </button>
                            </div>
                        </div>
                    </>
                )}
                {is_mobile && (
                    <BottomSidebar is_open={is_open} setOpen={setOpen} height={500} full_screen={full_screen}>
                        <>
                            {!is_modal_open && (
                                <div className={styles['items-container-mobile']}>
                                    <ul className={classNames(styles.list)}>
                                        {PERIODS.filter((item) => (stats ? item !== 'custom' : true)).map((item) => (
                                            <li
                                                onClick={() => setMobileSelectedPeriod(item)}
                                                className={classNames(styles.item, {
                                                    [styles['item-selected']]: item === mobile_selected_period,
                                                })}
                                                key={item}
                                            >
                                                {t(`period.${item}`)}
                                                <div
                                                    className={classNames(styles['empty-checkbox'], {
                                                        [styles['empty-checkbox-active']]:
                                                            mobile_selected_period === item,
                                                    })}
                                                >
                                                    <img
                                                        alt="tick icon"
                                                        src={`img/icons/${
                                                            mobile_selected_period === item
                                                                ? 'checked'
                                                                : `mobile-unchecked${is_dark_mode ? '-dark' : ''}`
                                                        }.svg`}
                                                    />
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className={styles.buttons}>
                                        <button
                                            className={classNames('button-primary', 'button-48')}
                                            onClick={() => {
                                                handleClick(mobile_selected_period);
                                            }}
                                        >
                                            {t('select')}
                                        </button>
                                    </div>
                                </div>
                            )}
                            {is_modal_open && (
                                <>
                                    <div className={styles['back-button-container']}>
                                        <div className={styles['back-button']} onClick={() => setModalOpen(false)}>
                                            <img
                                                className={styles['mobile-back-icon']}
                                                alt="back icon"
                                                src={`img/icons/arrow-left${is_dark_mode ? '-dark' : ''}.svg`}
                                            />
                                            <div className={styles['back-button-container-text']}>{t('back')}</div>
                                        </div>
                                    </div>
                                    <div
                                        className={classNames(styles['range-picker-container-mobile'])}
                                        style={{ maxHeight: full_screen ? '27rem' : '40rem' }}
                                    >
                                        <CustomRangePicker range={range} setRange={setRange} />
                                        <div className={styles.buttons}>
                                            <button
                                                type="submit"
                                                className={classNames('button-primary', 'button-48')}
                                                onClick={handleApply}
                                            >
                                                <span>{t('apply')}</span>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    </BottomSidebar>
                )}
                <div className={styles['input-container']}>
                    <div className={styles['fund-input']} onClick={() => setOpen((old) => !old)}>
                        <span className={styles.value}>
                            {is_mobile ? t(`period.choose`) : t(`period.${selected_period}`)}
                        </span>
                    </div>
                    <img
                        alt="down arrow"
                        className={classNames(styles.input_icon, `arrow${is_open ? '-open' : ''}`)}
                        src={`img/icons/arrow-down${is_dark_mode ? '-dark' : ''}.svg`}
                    />
                    <div
                        className={classNames(styles['items-container'], {
                            [styles['items-container-visible']]: is_open,
                        })}
                    >
                        <ul className={styles.list}>
                            {PERIODS.filter((item) => (stats ? item !== 'custom' : true)).map((item) => (
                                <li onClick={() => handleClick(item)} className={styles.item} key={item}>
                                    {t(`period.${item}`)}
                                    <div
                                        className={classNames(styles['empty-checkbox'], {
                                            [styles['empty-checkbox-active']]: selected_period === item,
                                        })}
                                    >
                                        <img
                                            alt="tick icon"
                                            src={`img/icons/${
                                                selected_period === item
                                                    ? 'checked'
                                                    : `unchecked${is_dark_mode ? '-dark' : ''}`
                                            }.svg`}
                                        />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PeriodSelect;
