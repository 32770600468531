import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';

import LanguagePicker from 'components/i18n';
import SettingsModal from 'components/settings_modal';
import ThemeToggle from 'components/theme_toggle';
import { RootState } from 'store';
import { getAccounts, setActiveAccount, setActiveFund, setLoggedOut } from 'store/client_slice';
import { clearInfo, getMe } from 'store/user_slice';

import styles from './index.module.scss';

const MobileAccountSwitcher = () => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const [is_accounts_open, setAccountsOpen] = React.useState(false);
    const [is_settings_open, setSettingsOpen] = React.useState(false);
    const { accounts, jwt_token, active_account_id } = useSelector((state: RootState) => state.client);
    const { info } = useSelector((state: RootState) => state.user);
    const [search_params, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    const is_dispatched = React.useRef(false); // Prevent retriggering API call
    const { t } = useTranslation();

    React.useEffect(() => {
        if (search_params.get('account')) {
            // This makes sharing a special account via direct URLs possible
            dispatch(setActiveAccount(search_params.get('account')));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!is_dispatched.current) {
            dispatch(getMe({ token: jwt_token }));
            dispatch(getAccounts({ token: jwt_token }));
            is_dispatched.current = true;
        }
    }, [dispatch, jwt_token]);

    React.useEffect(() => {
        if (active_account_id) {
            const search_obj = {};
            search_params.forEach((value, key) => (search_obj[key] = value));
            setSearchParams({ ...search_obj, account: active_account_id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active_account_id]);

    if (!localStorage.getItem('client.get_started_form')) {
        if (info?.id && !info.is_filled_get_started_form) return <Navigate to="/get-started" replace />;
    }

    return (
        <div className={styles['account-switcher']}>
            {is_settings_open && <SettingsModal handleClose={() => setSettingsOpen((old) => !old)} />}
            <div className={styles['user-section']}>
                <div className={styles['user-info']}>
                    <div className={styles['user-name']}>{info?.name}</div>
                    <div className={styles['current-account']}>{active_account_id}</div>
                </div>
            </div>
            <div className={classNames(styles['account-switcher-content'])}>
                {/* User accounts section */}
                {accounts?.length > 0 && (
                    <section className={styles['account-section']}>
                        <div
                            className={classNames(
                                styles['account-section-title'],
                                styles[`account-section-title-${is_accounts_open ? 'selected' : ''}`],
                                styles.general,
                            )}
                            onClick={() => setAccountsOpen((old_state) => !old_state)}
                        >
                            <img
                                alt="active user"
                                src={`img/icons/user-square-active${is_dark_mode ? '-dark' : ''}.svg`}
                            />
                            <span>{t('header.accounts')}</span>
                            <img
                                alt="down arrow"
                                className={classNames(
                                    styles['account-section-arrow'],
                                    `arrow${is_accounts_open ? '-open' : ''}`,
                                )}
                                src="img/icons/arrow-down-accounts.svg"
                            />
                        </div>
                        <div
                            className={classNames(
                                styles[`account-section-content-${is_accounts_open ? 'block' : 'none'}`],
                            )}
                        >
                            {accounts
                                .filter((account) => account.id !== active_account_id)
                                .map((account) => {
                                    return (
                                        <p
                                            key={account.id}
                                            className={styles.account}
                                            onClick={() => {
                                                dispatch(setActiveAccount(account.id));
                                                dispatch(setActiveFund(''));
                                            }}
                                        >
                                            <span className={classNames(styles.radio)}></span>
                                            <span className={classNames(styles['radio-label'])}>{account.id}</span>
                                        </p>
                                    );
                                })}
                        </div>
                    </section>
                )}
                {/* User settings section */}
                {info?.is_editable_by_user && (
                    <section
                        className={classNames(styles.section, styles.general)}
                        onClick={() => {
                            setSettingsOpen((old) => !old);
                        }}
                    >
                        <img alt="settings" src="img/icons/setting-2.svg" />
                        <span>{t('header.settings')}</span>
                    </section>
                )}
                {/* Logout section */}
                <section
                    onClick={() => {
                        dispatch(setLoggedOut());
                        dispatch(clearInfo());
                    }}
                    className={classNames(styles.section, styles.general, styles['log-out'])}
                >
                    <img alt="log out" src="img/icons/logout.svg" />
                    <span>{t('header.log_out')}</span>
                </section>
                <section className={styles['theme-toggle-position']}>
                    <LanguagePicker />
                    <ThemeToggle />
                </section>
            </div>
        </div>
    );
};

export default MobileAccountSwitcher;
