import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { ReactNode } from 'react';

import { Sorting } from 'types/common';

import styles from './index.module.scss';

type TableProps<T extends {}> = {
    data: Array<T>;
};

const TinyTable = <T extends { [x: string]: any }>({ data }: TableProps<T>) => {
    const [sorted_data, setSortedData] = React.useState(data);
    const [sorting, setSorting] = React.useState<Sorting>({
        column: -1,
        is_descending: false,
    });

    React.useEffect(() => {
        setSortedData(data);
    }, [data]);

    const field_mapper = data && data[0] && Object.keys(data[0]);
    const sort = (e) => {
        let column_idx = e.target.cellIndex;
        const column = field_mapper[column_idx];
        const is_descending = sorting.column === column_idx && !sorting.is_descending;
        const data_clone = [...sorted_data];
        data_clone.sort((a, b) => {
            let a_column = a[column];
            let b_column = b[column];
            if (Date.parse(a[column]) && Date.parse(b[column])) {
                a_column = Date.parse(a[column]);
                b_column = Date.parse(b[column]);
            }
            if (typeof a_column === 'object' || typeof b_column === 'object') {
                column_idx = -1;
                return 0;
            }
            if (a_column === b_column) return 0;
            return is_descending ? (a_column < b_column ? 1 : -1) : a_column > b_column ? 1 : -1;
        });
        setSortedData(data_clone);
        setSorting({ column: column_idx, is_descending });
    };

    return (
        <div className={styles.container}>
            <table className={styles.table}>
                <thead className={styles.thead} onClick={sort}>
                    <tr>
                        {sorted_data[0] &&
                            Object.keys(sorted_data[0]).map((field, idx) => (
                                <th className={classNames(styles.th, styles['tiny-th'])} key={field}>
                                    {field} {sorting.column === idx && (sorting.is_descending ? ' \u2191' : ' \u2193')}
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    {sorted_data.map((row) => {
                        return (
                            <tr className={classNames(styles['tiny-tr'])} key={nanoid()}>
                                {Object.values(row).map((cell: ReactNode) => (
                                    <td key={nanoid()} className={styles['tiny-td']}>
                                        {cell}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TinyTable;
