import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import AccountSwitcher from 'components/account_switcher';
import MobileMenuBar from 'components/mobile_menu_bar';
import { RootState } from 'store';

import styles from './index.module.scss';
import ThemeToggle from 'components/theme_toggle';
import LanguagePicker from 'components/i18n';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type HeaderProps = {
    query_string: string;
    unprotected: boolean;
};

const Header = ({ query_string, unprotected }: HeaderProps) => {
    const { t } = useTranslation();
    const { is_dark_mode, is_mobile } = useSelector((state: RootState) => state.platform);
    const [is_mobile_menu_open, setOpen] = useState(false);
    const [invest_modal_open, setInvestModalOpen] = useState(false);
    return (
        <header className={styles.header}>
            {is_mobile && <MobileMenuBar is_open={is_mobile_menu_open} setOpen={setOpen} />}
            {invest_modal_open && (
                <>
                    <div onClick={() => setInvestModalOpen(false)} className={'modal'}></div>
                    <div className={classNames('modal-main', styles.modal)}>
                        <h2 className={styles.title}>{t('header.invest')}</h2>
                        <div className={styles['modal-description']}>
                            <div>
                                <div>{t('header.invest_message.first')}</div>
                                <div>{t('header.invest_message.second')}</div>
                                <div>{t('header.invest_message.third')}</div>
                            </div>

                            <a
                                href="https://calendly.com/eveince/30min?month=2023-02"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <button className={classNames('button-primary', 'button-48')}>
                                    {t('header.invest_button')}
                                </button>
                            </a>
                        </div>
                    </div>
                </>
            )}
            <nav className={styles.nav}>
                <Link className={styles['brand-title']} to={`/overview${query_string}`}>
                    <img
                        alt="header logo"
                        className={styles['header-logo']}
                        src={`img/logos/header_logo_${is_dark_mode ? 'dark' : 'light'}.svg`}
                    />
                </Link>
                {!unprotected &&
                    (is_mobile ? (
                        <img
                            alt="hamburger menu"
                            className={styles['hamburger-menu']}
                            src={`img/icons/menu${is_dark_mode ? '-dark' : ''}.svg`}
                            onClick={() => setOpen((old) => !old)}
                        />
                    ) : (
                        <div className={styles['account-section']}>
                            <button onClick={() => setInvestModalOpen(true)}>{t('header.invest')}</button>
                            <AccountSwitcher />
                        </div>
                    ))}
                {unprotected && (
                    <div className={styles.options}>
                        <LanguagePicker />
                        <ThemeToggle />
                    </div>
                )}
            </nav>
        </header>
    );
};

export default Header;
