import classNames from 'classnames';
import BottomSidebar from 'components/bottom_side_bar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from 'store';
import { NavigationProps } from 'types/layout';
import { ALLOWED_PAGES } from 'utilities/constants';
import styles from './index.module.scss';
import sidebar_styles from '../sidebar/index.module.scss';

interface IActive {
    name: string;
    icon: string;
    to: string;
}

const items: Array<IActive> = [
    { name: 'overview', icon: 'overview', to: '/overview' },
    { name: 'funds', icon: 'fund', to: '/funds' },
    {
        name: 'transactions',
        icon: 'share-transaction',
        to: '/transactions',
    },
    { name: 'wallet', icon: 'wallet', to: '/wallet' },
    { name: 'comparison', icon: 'comparison', to: '/comparison' },
    { name: 'simulation', icon: 'simulation', to: '/simulation' },
    { name: 'pipeline', icon: 'pipeline', to: '/pipeline' },
];
const NavigationButton = ({ current_page, accounts, query_string }: NavigationProps) => {
    const { t } = useTranslation();
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const filtered_items = items.filter((item) => {
        if (Array.isArray(accounts) && accounts?.length) return true;
        else return ALLOWED_PAGES.includes(item.name);
    });
    const [open_more, setMoreOpen] = useState(false);
    const active_more = filtered_items
        ?.slice(3)
        .map((item) => item.to)
        .includes(current_page);
    return (
        <div className={styles.container}>
            <BottomSidebar is_open={open_more} setOpen={setMoreOpen} height={350}>
                <ul className={sidebar_styles['list']}>
                    {filtered_items.slice(3).map((item: IActive) => {
                        return (
                            <NavLink
                                to={`${item.to}${query_string}`}
                                className={classNames(sidebar_styles['link'])}
                                key={item.name}
                            >
                                <li
                                    className={classNames(sidebar_styles['item'], {
                                        [sidebar_styles.active]: item.to === current_page,
                                    })}
                                    onClick={() => setMoreOpen(false)}
                                >
                                    <div className={sidebar_styles['item-content']}>
                                        <img
                                            alt="item option"
                                            src={`img/icons/${item.icon}-icon${
                                                item.to === current_page
                                                    ? is_dark_mode
                                                        ? '-dark-active'
                                                        : '-light-active'
                                                    : ''
                                            }.svg`}
                                            className={sidebar_styles['item-icon']}
                                        />
                                        {t(`sidebar.${item.name}`)}
                                    </div>
                                </li>
                            </NavLink>
                        );
                    })}
                </ul>
            </BottomSidebar>
            <ul className={styles['list']}>
                {filtered_items.length < 5 ? (
                    filtered_items.map((item: IActive) => {
                        return (
                            <NavLink
                                to={`${item.to}${query_string}`}
                                className={classNames(styles['link'])}
                                key={item.name}
                            >
                                <li
                                    className={classNames(styles['item'], {
                                        [styles.active]: item.to === current_page,
                                    })}
                                >
                                    <div className={styles['item-content']}>
                                        <img
                                            alt="item option"
                                            src={`img/icons/${item.icon}-icon${
                                                item.to === current_page
                                                    ? is_dark_mode
                                                        ? '-dark-active'
                                                        : '-light-active'
                                                    : ''
                                            }.svg`}
                                            className={styles['item-icon']}
                                        />
                                        {t(`sidebar.${item.name}`)}
                                    </div>
                                </li>
                            </NavLink>
                        );
                    })
                ) : (
                    <>
                        {filtered_items.slice(0, 3).map((item: IActive) => {
                            return (
                                <NavLink
                                    to={`${item.to}${query_string}`}
                                    className={classNames(styles['link'])}
                                    key={item.name}
                                >
                                    <li
                                        className={classNames(styles['item'], {
                                            [styles.active]: item.to === current_page,
                                        })}
                                    >
                                        <div className={styles['item-content']}>
                                            <img
                                                alt="item option"
                                                src={`img/icons/${item.icon}-icon${
                                                    item.to === current_page
                                                        ? is_dark_mode
                                                            ? '-dark-active'
                                                            : '-light-active'
                                                        : ''
                                                }.svg`}
                                                className={styles['item-icon']}
                                            />
                                            {t(`sidebar.${item.name}`)}
                                        </div>
                                    </li>
                                </NavLink>
                            );
                        })}
                        <div className={styles.link}>
                            <li
                                className={classNames(styles['item'], {
                                    [styles.active]: active_more,
                                })}
                                onClick={() => setMoreOpen((prev) => !prev)}
                            >
                                <div className={styles['item-content']}>
                                    <img
                                        alt="item option"
                                        src={`img/icons/more-icon${
                                            active_more ? (is_dark_mode ? '-dark-active' : '-light-active') : ''
                                        }.svg`}
                                        className={styles['item-icon']}
                                    />
                                    {t(`sidebar.more`)}
                                </div>
                            </li>
                        </div>
                    </>
                )}
            </ul>
        </div>
    );
};
export default NavigationButton;
