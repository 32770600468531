import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    AuthorizationMessage,
    AuthorizedRequest,
    ConfirmRegistrationRequest,
    GoogleLoginRequest,
    LoginRequest,
    LoginResponse,
    RefreshRequest,
    RefreshResponse,
} from 'types/auth';
import { Account } from 'types/accounts';
import { APIError, PaginationResponse } from 'types/common';

interface IClientState {
    accounts: Array<Account> | null;
    active_account_id: string;
    active_fund_id: string;
    is_loading: boolean;
    is_loaded: boolean;
    is_google_login_in_progress: boolean;
    is_logged_in: boolean;
    jwt_token: string | null;
    refresh_token: string | null;
    err_code: number;
    err_message: string;
    retries: Array<{ type: string; args?: object }>;
}
// Fetch initial values from local storage
const jwt_token = localStorage.getItem('client.jwt_token') || '';
const refresh_token = localStorage.getItem('client.refresh_token') || '';
const active_account_id = localStorage.getItem('client.active_account_id') || '';

const initial_state: IClientState = {
    active_account_id,
    active_fund_id: '',
    accounts: null,
    err_code: 0,
    err_message: '',
    is_loading: false,
    is_loaded: false,
    is_google_login_in_progress: false,
    is_logged_in: !!jwt_token,
    jwt_token,
    refresh_token,
    retries: [], // Array of API calls to be dispatched again (after token refresh)
};
// Helper functions
const setLoading = (state: IClientState) => {
    state.err_message = '';
    state.is_loading = true;
};

const setError = (state: IClientState, error: APIError) => {
    state.err_code = error.status;
};

export const client_slice = createSlice({
    name: 'client',
    initialState: initial_state,
    reducers: {
        getAccounts: (state: IClientState, action: PayloadAction<AuthorizedRequest>) => {
            setLoading(state);
            state.is_loaded = false;
        },
        getAccountsSuccess: (state: IClientState, action: PayloadAction<PaginationResponse<Account>>) => {
            state.accounts = action.payload.results;
            if (!state.active_account_id) {
                const current_account_id = action.payload.results[0]?.id || '';
                state.active_account_id = current_account_id;
                localStorage.setItem('client.active_account_id', current_account_id);
            }
            state.is_loading = false;
            state.is_loaded = true;
        },
        getAccountsFailure: (state: IClientState, action: PayloadAction<string>) => {
            state.err_message = action.payload;
            state.is_loading = false;
        },
        postLogin: (state: IClientState, action: PayloadAction<LoginRequest>) => {
            setLoading(state);
        },
        postGoogleLogin: (state: IClientState, action: PayloadAction<GoogleLoginRequest>) => {
            state.is_google_login_in_progress = true;
            state.err_message = '';
        },
        postConfirmRegistration: (state: IClientState, action: PayloadAction<ConfirmRegistrationRequest>) => {
            state.err_message = '';
        },
        postLoginFailure: (state: IClientState, action: PayloadAction<string>) => {
            state.err_message = action.payload;
            state.is_loading = false;
            state.is_google_login_in_progress = false;
        },
        postLoginSuccess: (state: IClientState, action: PayloadAction<LoginResponse>) => {
            const { access, refresh } = action.payload;

            state.jwt_token = access;
            localStorage.setItem('client.jwt_token', access);

            state.refresh_token = refresh;
            localStorage.setItem('client.refresh_token', refresh);

            state.is_logged_in = true;
            state.is_loading = false;
            state.is_google_login_in_progress = false;
        },
        setLoggedOut: (state: IClientState) => {
            localStorage.removeItem('client.jwt_token');
            localStorage.removeItem('client.refresh_token');
            localStorage.removeItem('client.active_account_id');
            localStorage.removeItem('client.landing_page_path');

            state.is_logged_in = false;
            state.is_loading = false;
            state.is_google_login_in_progress = false;
            state.accounts = null;
            state.active_account_id = '';
            state.err_message = '';
            state.err_code = 0;
            state.jwt_token = '';
            state.refresh_token = '';
        },
        refresh: (state: IClientState, action: PayloadAction<RefreshRequest>) => {
            setLoading(state);
        },
        refreshSuccess: (state: IClientState, action: PayloadAction<RefreshResponse>) => {
            const { access } = action.payload;
            state.jwt_token = access;
            localStorage.setItem('client.jwt_token', access);
        },
        setActiveAccount: (state: IClientState, action: PayloadAction<string>) => {
            state.active_account_id = action.payload;
            localStorage.setItem('client.active_account_id', action.payload);
        },
        setActiveFund: (state: IClientState, action: PayloadAction<string>) => {
            state.active_fund_id = action.payload;
        },
        authorizationHandler: (state: IClientState, action: PayloadAction<AuthorizationMessage>) => {
            setError(state, { status: 401 });
            const retries_clone = [...state.retries];
            const { type, args } = action.payload;
            retries_clone.push({
                type,
                ...(args && { args }),
            });
            state.retries = retries_clone;
        },
        clearRetries: (state: IClientState) => {
            state.retries = [];
            state.err_code = 0;
        },
    },
});

export const {
    getAccounts,
    getAccountsSuccess,
    getAccountsFailure,
    refresh,
    refreshSuccess,
    setActiveAccount,
    postLogin,
    postGoogleLogin,
    postConfirmRegistration,
    postLoginSuccess,
    postLoginFailure,
    setLoggedOut,
    setActiveFund,
    authorizationHandler,
    clearRetries,
} = client_slice.actions;

export default client_slice.reducer;
