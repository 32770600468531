import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useFetcher } from 'api/fetcher';
import { getstats } from 'api/stats';
import PeriodSelect from 'components/period_select';
import { getCurrencyImg } from 'utilities/currency';
import { useCountDown } from 'utilities/hooks';
import { getNumberWithCommas } from 'utilities/str_utils';

import styles from './index.module.scss';

const get_next_time = () => {
    const date = new Date();
    date.setHours(date.getHours() - (date.getHours() % 4) + 4);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
};
const initial = get_next_time();
const PastDays = ({ period }: { period: string }) => {
    let days = period.replace('_D', '');
    return <span>{days}</span>;
};
const PipelineStats = () => {
    const { t } = useTranslation();
    const [period, setPeriod] = useState('_D365');
    const [next_time, setNext] = useState(initial);
    const [timer_content, setContent]: [ReactNode, React.Dispatch<React.SetStateAction<ReactNode>>] = useState();
    const [hours, minutes, seconds] = useCountDown(next_time);
    const { response } = useFetcher(getstats, null, null, null);
    useEffect(() => {
        const sum = hours + minutes + seconds;
        if (sum <= 0 && sum > -15) {
            setContent(t('stats.starting_pipeline'));
        } else if (sum <= -15) setNext(get_next_time());
        else {
            setContent(
                <>
                    <img alt="timer" src="img/icons/timer.svg" />
                    <span>
                        {t('stats.timer')}
                        <span>{` ${hours} : ${minutes} : ${seconds}`}</span>
                    </span>
                </>,
            );
        }
    }, [hours, minutes, seconds, t]);

    const buy_width = response?.data
        ? (response?.data[`ORDERS_BUY_COUNT${period}`] / response?.data[`ORDERS_TOTAL_COUNT${period}`]) * 75
        : 0;
    const sell_width = response?.data
        ? (response?.data[`ORDERS_SELL_COUNT${period}`] / response?.data[`ORDERS_TOTAL_COUNT${period}`]) * 75
        : 0;

    return (
        <div className={styles.container}>
            <div className={styles['stats-box-container']}>
                <div className={classNames(styles['stats-box'])}>
                    <div className="box">
                        <div className="box-header">
                            <div className={classNames('box-header_main', styles['header'])}>
                                {t('stats.orders')}{' '}
                                <span className={styles['header-info']}>
                                    (
                                    <Trans
                                        i18nKey="stats.past_days"
                                        t={t}
                                        components={[<PastDays period={period} />]}
                                    />
                                    )
                                </span>
                            </div>
                            <PeriodSelect stats set_period={setPeriod} />
                        </div>
                        <div className={styles['card-container']}>
                            <div className={styles.card}>
                                <div className={styles['card-header-container']}>
                                    <div className={styles['card-header']}>{t('stats.orders_total_count')}</div>
                                </div>
                                <div className={styles['report']}>
                                    <div>{getNumberWithCommas(response?.data?.[`ORDERS_TOTAL_COUNT${period}`])}</div>
                                    <img alt="total orders" src="img/icons/total-order.svg" />
                                </div>
                            </div>
                            <div className={styles.card}>
                                <div className={styles['card-header-container']}>
                                    <div className={styles['card-header']}>{t('stats.vwap_order_count')}</div>
                                </div>
                                <div className={styles['report']}>
                                    <div>{getNumberWithCommas(response?.data?.[`VWAP_ORDERS_COUNT${period}`])}</div>
                                    <img alt="total orders" src="img/icons/vwap-order.svg" />
                                </div>
                            </div>
                            <div className={styles.card}>
                                <div className={styles['card-header-container']}>
                                    <div className={styles['card-header']}>{t('stats.buy_sell_orders')}</div>
                                </div>
                                <div className={styles['report']}>
                                    <div>
                                        <div className={styles['bar-container']}>
                                            <div
                                                className={classNames(styles.bar, styles['bar-invest'])}
                                                style={{ width: `${buy_width}%` }}
                                            ></div>
                                            <span className={styles['bar-value']}>
                                                {t('stats.buy')}:{' '}
                                                {getNumberWithCommas(response?.data?.[`ORDERS_BUY_COUNT${period}`])}
                                            </span>
                                        </div>
                                        <div className={styles['bar-container']}>
                                            <div
                                                className={classNames(styles.bar, styles['bar-divest'])}
                                                style={{ width: `${sell_width}%` }}
                                            ></div>
                                            <span className={styles['bar-value']}>
                                                {t('stats.sell')}:{' '}
                                                {getNumberWithCommas(response?.data?.[`ORDERS_SELL_COUNT${period}`])}
                                            </span>
                                        </div>
                                    </div>
                                    <img alt="total orders" src="img/icons/buy-sell-order.png" />
                                </div>
                            </div>
                            <div className={styles.card}>
                                <div className={styles['card-header-container']}>
                                    <div className={styles['card-header']}>{t('stats.traded_value')}</div>
                                </div>
                                <div className={styles['report']}>
                                    <div>
                                        <div className={styles.currency}>
                                            <img
                                                className={styles['currency-img']}
                                                src={getCurrencyImg('BTC')}
                                                alt={'BTC'}
                                            />
                                            <div className={styles['currency-name']}>{'BTC'}</div>
                                            <div className={styles['currency-value']}>
                                                {getNumberWithCommas(response?.data?.[`TRADED_VALUE${period}_BTC`])}
                                            </div>
                                        </div>
                                        <div className={styles.currency}>
                                            <img
                                                className={styles['currency-img']}
                                                src={getCurrencyImg('USDT')}
                                                alt={'USDT'}
                                            />
                                            <div className={styles['currency-name']}>{'USDT'}</div>
                                            <div className={styles['currency-value']}>
                                                {getNumberWithCommas(response?.data?.[`TRADED_VALUE${period}_USDT`])}
                                            </div>
                                        </div>
                                    </div>
                                    <img alt="total orders" src="img/icons/traded-value.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNames(styles['stats-box'])}>
                    <div className="box">
                        <div className="box-header">
                            <div className={classNames('box-header_main', styles['header'])}>
                                {t('stats.trader_market')}
                            </div>
                        </div>
                        <div className={styles['card-container']}>
                            <div className={styles.card}>
                                <div className={styles['card-header-container']}>
                                    <div className={styles['card-header']}>{t('stats.trader_count')}</div>
                                </div>
                                <div className={styles['report']}>
                                    <div>{getNumberWithCommas(response?.data?.ACTIVE_TRADERS_COUNT)}</div>
                                    <img alt="total orders" src="img/icons/total-trader.svg" />
                                </div>
                            </div>
                            <div className={styles.card}>
                                <div className={styles['card-header-container']}>
                                    <div className={styles['card-header']}>{t('stats.market_count')}</div>
                                </div>
                                <div className={styles['report']}>
                                    <div>{getNumberWithCommas(response?.data?.MARKET_COUNT)}</div>
                                    <img alt="total orders" src="img/icons/total-market.svg" />
                                </div>
                            </div>
                            <div className={styles.card}>
                                <div className={styles['card-header-container']}>
                                    <div className={styles['card-header']}>{t('stats.fulfillment_rate')}</div>
                                </div>
                                <div className={styles['report']}>
                                    <div>{getNumberWithCommas(response?.data?.AVERAGE_FULFILLMENT_RATE)} %</div>
                                    <img alt="total orders" src="img/icons/fulfillment-rate.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNames(styles['stats-box'])}>
                    <div className="box">
                        <div className="box-header">
                            <div className={classNames('box-header_main', styles['header'])}>
                                {t('stats.algorithm_portfolio')}
                            </div>
                        </div>
                        <div className={styles['card-container']}>
                            <div className={styles.card}>
                                <div className={styles['card-header-container']}>
                                    <div className={styles['card-header']}>{t('stats.direction_algorithms')}</div>
                                </div>
                                <div className={styles['report']}>
                                    <div>{getNumberWithCommas(response?.data?.ACTIVE_DIRECTION_ALGO_COUNT)}</div>
                                    <img alt="total orders" src="img/icons/total-direction.svg" />
                                </div>
                            </div>
                            <div className={styles.card}>
                                <div className={styles['card-header-container']}>
                                    <div className={styles['card-header']}>{t('stats.placement_algorithms')}</div>
                                </div>
                                <div className={styles['report']}>
                                    <div>{getNumberWithCommas(response?.data?.ACTIVE_PLACEMENT_ALGO_COUNT)}</div>
                                    <img alt="total orders" src="img/icons/total-placement.svg" />
                                </div>
                            </div>
                            <div className={styles.card}>
                                <div className={styles['card-header-container']}>
                                    <div className={styles['card-header']}>{t('stats.portfolio_count')}</div>
                                </div>
                                <div className={styles['report']}>
                                    <div>{getNumberWithCommas(response?.data?.ACTIVE_PORTFOLIOS_COUNT)}</div>
                                    <img alt="total orders" src="img/icons/total-portfolio.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles['timer-container']}>{timer_content}</div>
        </div>
    );
};

export default PipelineStats;
