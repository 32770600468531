import styles from './index.module.scss';

type UnprotectedRouteWrapperProp = {
    children: JSX.Element;
};

const UnprotectedRouteWrapper = ({ children }: UnprotectedRouteWrapperProp) => {
    return (
        <div className={styles['page-container']}>
            <div className={styles['main-container']}>{children}</div>
        </div>
    );
};

export default UnprotectedRouteWrapper;
