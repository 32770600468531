import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import Layout from 'components/layout';
import { RootState } from 'store';
import { useIsMounted } from 'utilities/hooks';

type ProtectedRouteProp = {
    redirect_path?: string;
};

const ProtectedRoute = ({ redirect_path = '/log-in' }: ProtectedRouteProp) => {
    const { is_logged_in } = useSelector((state: RootState) => state.client);
    const location = useLocation();
    const isMounted = useIsMounted();
    if (!isMounted()) {
        localStorage.setItem('client.landing_page_path', location?.pathname + location?.search);
    }
    return is_logged_in ? (
        <Layout current_page={location?.pathname}>
            <Outlet />
        </Layout>
    ) : (
        <Navigate to={redirect_path} replace />
    );
};
export default ProtectedRoute;
