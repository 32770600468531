import { BASE_API_URL, headers } from 'api/common';
import { AvailableAssetsRequest } from 'types/available_assets';

export const getAvailableAssets = ({ token, account_id }: AvailableAssetsRequest) =>
    account_id &&
    fetch(`${BASE_API_URL}/accounts/${account_id}/available-assets`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    });
