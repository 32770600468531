import React, { Dispatch, SetStateAction, TouchEvent } from 'react';

import MobileAccountSwitcher from 'components/mobile_account_switcher';
import { useClickOutside } from 'utilities/hooks';

import styles from './index.module.scss';
import classNames from 'classnames';

type MobileMenuBarProps = {
    is_open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

const min_swipe_distance = 30;

const MobileMenuBar = ({ is_open, setOpen }: MobileMenuBarProps) => {
    // To use as a ref to close menu bar by clicking outside
    const wrapper_ref = React.useRef<HTMLDivElement>(null);
    // To allow closing the menu bar by swiping
    const [touch_start, setTouchStart] = React.useState(null);
    const [touch_end, setTouchEnd] = React.useState(null);

    useClickOutside(wrapper_ref, () => setOpen(false));
    // Touch event handlers
    const onTouchStart = (e: TouchEvent<HTMLDivElement>) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
    };
    const onTouchMove = (e: TouchEvent<HTMLDivElement>) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };
    const onTouchEnd = () => {
        if (!touch_start || !touch_end) return;
        const distance = touch_start - touch_end;
        const is_to_right_swipe = distance < -min_swipe_distance;
        if (is_to_right_swipe) setOpen(false);
    };

    return (
        <div
            className={classNames(styles.container, { [styles['container-open']]: is_open })}
            ref={wrapper_ref}
            onTouchMove={onTouchMove}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
        >
            <img
                alt="close menu"
                className={styles['close-menu']}
                src="img/icons/close_menu_icon.svg"
                onClick={() => setOpen(false)}
            />
            <MobileAccountSwitcher />
        </div>
    );
};

export default MobileMenuBar;
