import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { IResponseInfo } from 'api/fetcher';
import CustomInput from 'components/custom_input';
import CustomRangePicker from 'components/custom_range_picker';
import { getISODateForDatePicker } from 'utilities/date_time';
import { COMPARISON_DEFAULT_PERIOD } from 'utilities/constants';
import { Assets } from 'types/simulation';
import { indicesData, T } from 'types/common';

type Filter = { initial: number; range: Array<Date> };
type FilterProps = {
    styles: { readonly [key: string]: string };
    funds: IResponseInfo;
    indices: { data: indicesData; has_error: boolean };
    filters: Filter;
    setFilters: React.Dispatch<React.SetStateAction<Filter>>;
    assets: Assets;
    handleApplyFilter: () => void;
    valid_simulation: boolean;
    t: T;
};

const Filters = ({
    styles,
    funds,
    indices,
    filters,
    setFilters,
    assets,
    handleApplyFilter,
    valid_simulation,
    t,
}: FilterProps) => {
    const [valid_start_time, setValidStartTime] = useState(new Date());
    const [is_red, setRed] = useState(false);
    const valid_funds = funds?.data?.filter((fund) => fund.quote === 'USDT');

    const handle_change_initial_investment = (e) => {
        const tmp = { ...filters };
        tmp['initial'] = e.target.value;
        setFilters(tmp);
    };

    const handleRange = (range) => {
        setFilters((old) => ({ ...old, range }));
    };
    const all_assets = valid_funds &&
        indices.data && [
            ...valid_funds?.map(({ id, first_navps_time }) => ({ id, time: first_navps_time })),
            ...Object.keys(indices.data).map((index) => ({ id: index, time: indices?.data?.[index].start_time })),
        ];
    const selected_assets = Object.keys(assets);
    useEffect(() => {
        const all_selected_dates = all_assets
            ?.filter(({ id }) => selected_assets.includes(id))
            .map((asset) => asset.time);
        const valid_time =
            all_selected_dates?.length > 0
                ? new Date(Math.max(...all_selected_dates.map((date) => new Date(date).getTime())))
                : COMPARISON_DEFAULT_PERIOD;
        setValidStartTime(valid_time);
        if (valid_time.getTime() > new Date(filters.range[0]).getTime()) {
            setFilters((old) => ({ ...old, range: [valid_time, filters.range[1]] }));
        }
        const portfo_1_sum = Object.values(assets).reduce((acc, curr) => acc + parseFloat(curr.portfolio_1), 0);
        const portfo_2_sum = Object.values(assets).reduce((acc, curr) => acc + parseFloat(curr.portfolio_2), 0);
        const portfo_3_sum = Object.values(assets).reduce((acc, curr) => acc + parseFloat(curr.portfolio_3), 0);
        if (portfo_1_sum === 0 && portfo_2_sum === 0 && portfo_3_sum === 0) setRed(false);
        else setRed(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assets]);

    const disabled_apply = !valid_simulation || !filters.initial;
    return (
        <div className="box">
            <div className={styles.header}>{t('simulation.filters.period')}</div>
            <div className={styles['available-period-container']}>
                <div>{t('simulation.filters.available_period')}</div>
                <div className={styles.dates}>
                    <div className={styles['dates-item']}>
                        <div>{t('start_date')}</div>
                        <div>{getISODateForDatePicker(valid_start_time)}</div>
                    </div>
                    <div className={styles['dates-item']}>
                        <div>{t('end_date')}</div>
                        <div>{getISODateForDatePicker(new Date()) + ` (${t('simulation.filters.today')})`}</div>
                    </div>
                </div>
            </div>
            <CustomRangePicker dropdown min_date={valid_start_time} range={filters.range} setRange={handleRange} />
            <div className={styles.header}>{t('simulation.filters.initial')}</div>
            <CustomInput
                icon={<img width="16px" alt="tether" src="img/icons/dollar-circle.svg" />}
                placeholder="Enter Initial Value"
                type="number"
                onChange={handle_change_initial_investment}
                value={filters.initial}
            />

            <div className={styles['button-container']}>
                <button
                    className={classNames(styles.apply, 'button-primary', 'button-48')}
                    onClick={handleApplyFilter}
                    disabled={disabled_apply}
                >
                    {t('apply')}
                </button>
            </div>
            {disabled_apply && (
                <>
                    {!filters.initial && (
                        <div className={classNames(styles['message-disabled'], styles['message-disabled--red'])}>
                            <img alt="error" src="img/icons/Error.svg" />
                            <div>{t('simulation.initial_value_msg')}</div>
                        </div>
                    )}
                    <div
                        className={classNames(styles['message-disabled'], {
                            [styles['message-disabled--red']]: is_red,
                        })}
                    >
                        {is_red && <img alt="error" src="img/icons/Error.svg" />}
                        <div>{t('simulation.ensure')}</div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Filters;
