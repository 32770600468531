export const deleteEmptyValues = (obj: Object) =>
    Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== '' && v !== null));

export const strQueryParams = (obj: { [x: string]: any }) => {
    const str =
        obj &&
        Object.keys(obj)
            .map((key) => {
                if (Array.isArray(obj[key])) {
                    const tmp = obj[key].map((row: any) => `${key}=${row}`).join('&');
                    return tmp;
                } else if (obj[key] !== '') {
                    return `${key}=${obj[key]}`;
                } else {
                    return '';
                }
            })
            .filter(Boolean)
            .join('&');
    return `${Object.keys(obj).length === 0 ? '' : '?'}` + str;
};
