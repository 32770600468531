import { useFetcher } from 'api/fetcher';
import { getFundsList } from 'api/funds';
import { getShareTransactions, getTotalTradedShares } from 'api/share_transactions';
import classNames from 'classnames';
import Pagination from 'components/pagination';
import Table from 'components/table/Table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrencyImg } from 'utilities/currency';
import { getFormattedDateTime, getISODateForDatePicker } from 'utilities/date_time';
import { deleteEmptyValues, strQueryParams } from 'utilities/obj_utils';
import styles from './index.module.scss';
import CustomDatePicker from 'components/custom_date_picker/CustomDatePicker';
import CustomSelectInput from 'components/custom_select_input';
import { ShareTransactionFilter } from 'types/share_transactions';

type DesktopProps = {
    active_account_id: string;
};
const ShareTransactionDesktop = ({ active_account_id }: DesktopProps) => {
    const { t } = useTranslation();
    const [filter_params, setFilterParams] = React.useState<ShareTransactionFilter>({
        fund_name: '',
        start_time: null,
        end_time: null,
    });
    const [query_params, setQueryParams] = React.useState('');
    const [page_number, setPageNumber] = React.useState(1);
    const [selected_fund_id, setSelectedFundId] = React.useState(null);
    const [selected_fund_name, setSelectedFundName] = React.useState(null);
    const { response: funds } = useFetcher(getFundsList);
    const fund_items = funds?.data?.map((obj) => obj.name || obj.id);
    const { response: traded_shares } = useFetcher(getTotalTradedShares, active_account_id);
    const traded_shares_data = traded_shares?.data;
    const { response: share_transactions } = useFetcher(getShareTransactions, active_account_id, null, query_params);
    const share_transaction_result = share_transactions?.data?.results;
    const total_pages = share_transactions?.data?.total_pages;
    const handleApplyFilter = () => {
        const { start_time, end_time } = filter_params;
        const tmp = {
            ...filter_params,
            ...(start_time && { start_time: getISODateForDatePicker(start_time) }),
            ...(end_time && { end_time: getISODateForDatePicker(end_time) }),
            page: 1,
        };
        setPageNumber(1);
        const tmpId = funds?.data?.find((fund) => fund.name === tmp.fund_name)?.id || null;
        setSelectedFundId(tmpId);
        setSelectedFundName(tmp.fund_name);
        setQueryParams(strQueryParams(deleteEmptyValues(tmp)));
    };

    const handlePageNumberChange = (num: number) => {
        const { start_time, end_time } = filter_params;
        setPageNumber(num);
        const tmp = {
            ...filter_params,
            ...(start_time && { start_time: getISODateForDatePicker(start_time) }),
            ...(end_time && { end_time: getISODateForDatePicker(end_time) }),
            page: num,
        };
        setQueryParams(strQueryParams(deleteEmptyValues(tmp)));
    };

    const handleResetFilter = () => {
        setSelectedFundName(null);
        setSelectedFundId(null);
        setPageNumber(1);
        setFilterParams({ fund_name: '', start_time: null, end_time: null });
        setQueryParams('');
    };

    const table_data = share_transaction_result?.map((row) => {
        const { date, time } = getFormattedDateTime(row.time);
        return {
            [t('share_transactions.name')]: row.fund_name.trim() || row.fund_id,
            [t('share_transactions.action')]:
                row.share_count > 0 ? t('share_transactions.investment') : t('share_transactions.divestment'),
            [t('share_transactions.share_count')]: Math.abs(row.share_count),
            [t('share_transactions.assets')]: (
                <>
                    {Object.keys(row.assets)
                        .map((key) => ({
                            name: key,
                            value: +row.assets[key],
                        }))
                        .map((asset) => (
                            <div className={styles.currency} key={asset.name}>
                                <img
                                    className={styles['currency-img']}
                                    src={getCurrencyImg(asset.name)}
                                    alt={asset.name}
                                />
                                <div className={styles['currency-name']}>{asset.name}</div>
                                <div className={styles['currency-value']}>{asset.value}</div>
                            </div>
                        ))}
                </>
            ),
            [t('share_transactions.time')]: date + ' ' + time,
        };
    });
    const total_investment = selected_fund_id
        ? traded_shares_data?.[selected_fund_id]?.invested
        : traded_shares_data &&
          Object.values(traded_shares_data).reduce((acc: number, curr: { [x: string]: number }) => {
              acc += curr.invested;
              return acc;
          }, 0);
    const total_divestment = selected_fund_id
        ? traded_shares_data?.[selected_fund_id]?.divested
        : traded_shares_data &&
          Object.values(traded_shares_data).reduce((acc: number, curr: { [x: string]: number }) => {
              acc += curr.divested;
              return acc;
          }, 0);
    const invest_width = (total_investment / (total_divestment + total_investment)) * 80;
    const divest_width = (total_divestment / (total_divestment + total_investment)) * 80;
    return (
        <div className={'desktop-view'}>
            <div className={styles['filters-container']}>
                <div className={styles['fund-input-container']}>
                    {fund_items && (
                        <CustomSelectInput
                            value={filter_params.fund_name}
                            on_change={(item) => setFilterParams((old) => ({ ...old, fund_name: item }))}
                            items={fund_items}
                            input_placeholder={t('share_transactions.fund')}
                        />
                    )}
                </div>
                <CustomDatePicker
                    value={filter_params.start_time}
                    on_change={(e) => setFilterParams((old) => ({ ...old, start_time: e }))}
                    max_date={filter_params.end_time || new Date()}
                    input_placeholder={t('start_date')}
                />
                <CustomDatePicker
                    value={filter_params.end_time}
                    on_change={(e) => setFilterParams((old) => ({ ...old, end_time: e }))}
                    max_date={new Date()}
                    min_date={filter_params.start_time}
                    input_placeholder={t('end_date')}
                />
                <div className={styles['button-container']}>
                    <button
                        className={classNames(styles.submit, 'button-primary', 'button-48')}
                        onClick={handleApplyFilter}
                    >
                        {t('apply')}
                    </button>
                    <button
                        className={classNames(styles.submit, 'button-outline', 'button-48')}
                        onClick={handleResetFilter}
                    >
                        {t('reset')}
                    </button>
                </div>
            </div>
            <div className={classNames('box', styles.container)}>
                {traded_shares && (
                    <div className={styles['trade-container']}>
                        <div className={styles['data-container']}>
                            <p className={styles['trade-header']}>
                                {selected_fund_name || t('share_transactions.shares')}
                            </p>
                            <div>
                                <span className={styles['trade-type']}>{t('invested')}</span>
                                <div className={styles['bar-container']}>
                                    <div
                                        className={classNames(styles.bar, styles['bar-invest'])}
                                        style={{ width: `${invest_width + 2}%` }}
                                    ></div>
                                    <span className={styles['bar-value']}>{`${total_investment}`}</span>
                                </div>
                            </div>
                            <div>
                                <span className={styles['trade-type']}>{t('divested')}</span>
                                <div className={styles['bar-container']}>
                                    <div
                                        className={classNames(styles.bar, styles['bar-divest'])}
                                        style={{ width: `${divest_width + 2}%` }}
                                    ></div>
                                    <span className={styles['bar-value']}>{`${total_divestment}`}</span>
                                </div>
                            </div>
                        </div>
                        <img alt="trade icon" className={styles['trade-image']} src="img/traded-share-img.svg" />
                    </div>
                )}
                {table_data && (
                    <>
                        <Table
                            data={table_data}
                            handleClickRow={(row) => setFilterParams((old) => ({ ...old, fund_name: row.Name }))}
                        />
                        <Pagination
                            onPageChange={(number) => handlePageNumberChange(number)}
                            currentPage={page_number}
                            total_pages={total_pages}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default ShareTransactionDesktop;
