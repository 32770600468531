import classNames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import BottomSidebar from 'components/bottom_side_bar';
import { RootState } from 'store';
import { getISODateForDatePicker } from 'utilities/date_time';
import { useClickOutside } from 'utilities/hooks';

import 'react-calendar/dist/Calendar.css';
import styles from './index.module.scss';

type DatePickerProps = {
    value: Date | null;
    on_change: (e: Date) => void;
    max_date?: Date | null;
    min_date?: Date | null;
    input_placeholder: string;
    mobile_selected?: Date | null;
    setMobileSelected?: Dispatch<SetStateAction<Date | null>>;
};

const CustomDatePicker = ({
    value,
    on_change,
    max_date = null,
    min_date = null,
    input_placeholder,
    mobile_selected,
    setMobileSelected,
}: DatePickerProps) => {
    const { t } = useTranslation();
    const { is_dark_mode, is_mobile } = useSelector((state: RootState) => state.platform);
    const [is_open, setOpen] = React.useState(false);
    const wrapper_ref = React.useRef<HTMLDivElement>(null);

    useClickOutside(wrapper_ref, () => setOpen(false));

    const input_value = value ? getISODateForDatePicker(value) : '';

    const handleChange = (e) => {
        on_change(e);
        setOpen(false);
    };

    return (
        <div className={styles.date} ref={wrapper_ref}>
            <div className={styles['input-container']} onClick={() => setOpen((old) => !old)}>
                <div className={styles.input} style={{ pointerEvents: is_open ? 'none' : 'auto' }}>
                    {value ? (
                        <span className={styles.value}>{input_value}</span>
                    ) : (
                        <span className={styles.placeholder}>{input_placeholder}</span>
                    )}
                </div>
                <img
                    alt="down arrow"
                    className={classNames(styles.input_icon, `arrow${is_open ? '-open' : ''}`)}
                    src={`img/icons/arrow-down${is_dark_mode ? '-dark' : ''}.svg`}
                />
            </div>
            <div className={classNames(styles.calendar, { [styles['calendar-visible']]: is_open })}>
                <Calendar
                    onChange={(e) => handleChange(e)}
                    value={value}
                    maxDate={max_date}
                    prev2Label={null}
                    next2Label={null}
                    prevLabel={
                        <img alt="left side" src={`img/icons/caret-circle-left${is_dark_mode ? '-dark' : ''}.svg`} />
                    }
                    nextLabel={
                        <img alt="right side" src={`img/icons/caret-circle-right${is_dark_mode ? '-dark' : ''}.svg`} />
                    }
                    minDate={min_date}
                />
            </div>
            {is_mobile && (
                <BottomSidebar is_open={is_open} setOpen={setOpen} height={415}>
                    <div className={styles['calendar-container-mobile']}>
                        <div style={{ padding: '0 2rem' }}>
                            <Calendar
                                onChange={(e) => setMobileSelected(e)}
                                value={mobile_selected}
                                maxDate={max_date}
                                prev2Label={null}
                                next2Label={null}
                                prevLabel={
                                    <img
                                        alt="left side"
                                        src={`img/icons/caret-circle-left${is_dark_mode ? '-dark' : ''}.svg`}
                                    />
                                }
                                nextLabel={
                                    <img
                                        alt="right side"
                                        src={`img/icons/caret-circle-right${is_dark_mode ? '-dark' : ''}.svg`}
                                    />
                                }
                                minDate={min_date}
                            />
                        </div>
                        <button
                            className={classNames(styles.submit, 'button-primary', 'button-48')}
                            onClick={() => {
                                on_change(mobile_selected);
                                setOpen(false);
                            }}
                        >
                            {t('select')}
                        </button>
                    </div>
                </BottomSidebar>
            )}
        </div>
    );
};

export default CustomDatePicker;
