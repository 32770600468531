import classNames from 'classnames';
import { useRef } from 'react';

import CustomInput from 'components/custom_input';
import LoaderSpinner from 'components/loader_spinner';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { T } from 'types/common';
import { useClickOutside } from 'utilities/hooks';

type Props = {
    config_name: string;
    is_loading: boolean;
    handleSaveFilter: () => void;
    save_config_message: string;
    setConfigName: React.Dispatch<React.SetStateAction<string>>;
    setConfigNameModal: React.Dispatch<React.SetStateAction<boolean>>;
    styles: { readonly [key: string]: string };
    t: T;
};
const ConfigNameModal = ({
    setConfigNameModal,
    styles,
    is_loading,
    save_config_message,
    config_name,
    setConfigName,
    t,
    handleSaveFilter,
}: Props) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const config_name_wrapper = useRef<HTMLDivElement>(null);
    useClickOutside(config_name_wrapper, () => {
        setConfigNameModal(false);
    });
    return (
        <>
            <div className={styles.modal} onClick={() => setConfigNameModal(false)}></div>
            <div ref={config_name_wrapper} className={classNames(styles['modal-main'], styles['config-name'])}>
                {is_loading ? (
                    <div className={styles.loader}>
                        <LoaderSpinner is_dark_mode={is_dark_mode} />
                    </div>
                ) : save_config_message ? (
                    <div className={classNames(styles.message)}>
                        <img alt="archive tick" src="img/icons/archive-tick.svg" />
                        {t(save_config_message)}
                    </div>
                ) : (
                    <>
                        <div className={classNames(styles.header, 'box-header')}>{t('comparison.select_name')}</div>
                        <CustomInput
                            icon={<img width="16px" alt="tether" src="img/icons/text-block.svg" />}
                            placeholder={t('comparison.enter_name')}
                            type="text"
                            onChange={(e) => setConfigName(e.target.value)}
                            value={config_name}
                        />
                        <div className={styles['button-container']}>
                            <button
                                className={classNames(styles.apply, 'button-primary', 'button-48')}
                                onClick={handleSaveFilter}
                                disabled={!config_name}
                            >
                                {t('submit')}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default ConfigNameModal;
