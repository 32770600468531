import React from 'react';

const useWindowWidth = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useLayoutEffect(() => {
        function updateWidth() {
            setWidth(window.innerWidth);
        }

        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    return width;
};

export default useWindowWidth;
