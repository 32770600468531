import { BASE_API_URL, headers } from 'api/common';
import { FundAssetsRequest, FundRequest } from 'types/funds';

const path = 'funds';

export const getFundAssets = ({ token, account_id }: FundAssetsRequest) =>
    account_id &&
    fetch(`${BASE_API_URL}/accounts/${account_id}/fund-assets`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    });

export const getFund = ({ token, fund_id }: FundRequest) =>
    fund_id &&
    fetch(`${BASE_API_URL}/${path}/${fund_id}/`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    });

export const getFundsList = () =>
    fetch(`${BASE_API_URL}/${path}/`, {
        method: 'GET',
        headers: {
            ...headers,
        },
    });

export const getFundHistoricalNAVPS = ({ token, fund_id, query_string }: FundRequest) =>
    fund_id &&
    fetch(`${BASE_API_URL}/${path}/${fund_id}/historical-navps/${query_string}`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    });

export const getFundMetrics = ({ token, fund_id, query_string }: FundRequest) =>
    fund_id &&
    fetch(`${BASE_API_URL}/${path}/${fund_id}/metrics/${query_string}`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    });

export const getAllFundsHistoricalNAVPS = async ({ token, list, query_string }) => {
    const promises =
        list &&
        list.map((id) => {
            const fetcher = async () => {
                const res = await getFundHistoricalNAVPS({ token, fund_id: id, query_string });
                const res_json = await res.json();
                return res_json;
            };
            return fetcher();
        });

    return Promise.allSettled(promises).then((res) =>
        res.map((resp) => (resp.status === 'fulfilled' ? resp.value : [])),
    );
};

export const getAllFundsMetrics = async ({ token, list, query_string }) => {
    const promises =
        list &&
        list.map((id) => {
            const fetcher = async () => {
                const res = await getFundMetrics({ token, fund_id: id, query_string });
                const res_json = await res.json();
                return res_json;
            };
            return fetcher();
        });

    return Promise.allSettled(promises).then((res) =>
        res.map((resp) => (resp.status === 'fulfilled' ? resp.value : [])),
    );
};
