import { FieldMetaProps, FormikProps } from 'formik';
import React, { ChangeEvent, useEffect, useRef } from 'react';

import CustomInput from 'components/custom_input';
import { T } from 'types/common';
import { useIsMounted } from 'utilities/hooks';

const PASSWORD_FIELD = 'password';
const PASSWORD_STRENGTH_FIELD = 'password_strength';

type PasswordInputProps = {
    form_props?: FormikProps<object>;
    meta: FieldMetaProps<string>;
    t?: T;
    with_password_meter?: boolean;
    [x: string]: any;
};

const PasswordInput = ({ form_props, meta, t, with_password_meter = false, ...props }: PasswordInputProps) => {
    const [is_hidden, setHidden] = React.useState(true);
    const [is_zxcvbn_loading, setZxcvbnLoading] = React.useState<boolean>(with_password_meter);
    const [score, setScore] = React.useState<number>(-1);
    const zxcvbn = useRef<any>();

    const isMounted = useIsMounted();

    useEffect(() => {
        async function loadLibrary() {
            const lib = await import('zxcvbn');
            zxcvbn.current = lib.default;
            if (isMounted()) setZxcvbnLoading(false);
        }
        if (with_password_meter) loadLibrary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (is_zxcvbn_loading) return <p>Loading...</p>; // [TODO]: Render a loading state

    return (
        <CustomInput
            btn_content={
                is_hidden ? (
                    <img alt="hidden password" src="img/icons/eye-slash.svg" />
                ) : (
                    <img alt="visible password" src="img/icons/eye.svg" />
                )
            }
            handleBtnClick={() => setHidden((visiblity) => !visiblity)}
            icon={<img alt="lock icon" src="img/icons/lock.svg" />}
            meta={meta}
            type={is_hidden ? 'password' : 'text'}
            {...props}
            {...(with_password_meter
                ? {
                      onChange: (e: ChangeEvent<HTMLInputElement>) => {
                          if (typeof zxcvbn.current === 'function') {
                              const { value } = e.target;
                              if (e.target.value) {
                                  const updated_score = zxcvbn.current(value).score;
                                  form_props.setFieldValue(PASSWORD_STRENGTH_FIELD, updated_score);
                                  setScore(updated_score);
                              } else {
                                  setScore(-1);
                              }
                              form_props.setFieldValue(PASSWORD_FIELD, e.target.value);
                          }
                      },
                      score,
                  }
                : {})}
        />
    );
};

export default PasswordInput;
