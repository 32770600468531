export const MOBILE_DEVICE_BREAKPOINT = 480;
export const CURRENCY_FONT = { USDT: '₮', BTC: '₿' };
export const APP_VERSION = '1.9.0';
export const RECAPTCHA_SITE_KEY = '6Ld2onIjAAAAABmNXOpDisYK-5meBWvwNNrI6ZAj';
export const RIGHTS = ` © Eveince Tech GmbH ${new Date().getFullYear()}`;
export const COMPARISON_DEFAULT_PERIOD = new Date(new Date().setMonth(new Date().getMonth() - 6));
export const COMPARISON_METRICS = [
    'annualized_return',
    'annualized_volatility',
    'max_drawdown',
    'sharpe_ratio',
    'sortino_ratio',
    'calmar_ratio',
];
export const PERCENT_METRICS = ['annualized_return', 'annualized_volatility', 'max_drawdown'];
export const PORTFOLIO_COLORS = { portfolio_1: '#B57BFF', portfolio_2: '#FEC37B', portfolio_3: '#7B9EFD' };
export const FUND_COLORS = ['#B57BFF', '#FEC37B', '#7B9EFD', '#FF7B7B', '#7BFD9F'];
export const ALLOWED_PAGES = ['funds', 'comparison', 'pipeline', 'simulation'];
export const INDICE_COLORS = [
    '#F34213',
    '#7C6354',
    '#D81E5B',
    '#23967F',
    '#3B429F',
    '#E0CBA8',
    '#9E0031',
    '#E6625D',
    '#8FB2C6',
    '#FC6B3F',
    '#73C8A9',
    '#BD5532',
    '#9D27B0',
];
export const RETURN_DISTRIBUTION_BIN_RANGE = 1;
export const MIN_MAX_RETURN = 10;
export const CONSTANT_RETURN_POINTS = {
    'Moderate Head Capital': {
        data: [
            {
                time: '2022-06-28T00:00:00',
                value: '1000',
            },
            {
                time: '2022-06-29T00:00:00',
                value: '991.68420184505290467',
            },
            {
                time: '2022-06-30T00:00:00',
                value: '988.74000120600925012',
            },
            {
                time: '2022-07-01T00:00:00',
                value: '985.2759828420655471',
            },
            {
                time: '2022-07-02T00:00:00',
                value: '985.02369798491835461',
            },
            {
                time: '2022-07-03T00:00:00',
                value: '986.43413121081888621',
            },
            {
                time: '2022-07-04T00:00:00',
                value: '987.20172047165987893',
            },
            {
                time: '2022-07-05T00:00:00',
                value: '991.37267377781359455',
            },
            {
                time: '2022-07-06T00:00:00',
                value: '984.96317609185878399',
            },
            {
                time: '2022-07-07T00:00:00',
                value: '986.01434840064731837',
            },
            {
                time: '2022-07-08T00:00:00',
                value: '998.5395653008552499',
            },
            {
                time: '2022-07-09T00:00:00',
                value: '988.95491406395778627',
            },
            {
                time: '2022-07-10T00:00:00',
                value: '987.84092655661310017',
            },
            {
                time: '2022-07-11T00:00:00',
                value: '974.27466357599611995',
            },
            {
                time: '2022-07-12T00:00:00',
                value: '972.3302240778259372',
            },
            {
                time: '2022-07-13T00:00:00',
                value: '971.61358248751986153',
            },
            {
                time: '2022-07-14T00:00:00',
                value: '971.62114749013811632',
            },
            {
                time: '2022-07-15T00:00:00',
                value: '1000.843982952299636',
            },
            {
                time: '2022-07-16T00:00:00',
                value: '1000.0201596081720172',
            },
            {
                time: '2022-07-17T00:00:00',
                value: '1006.8729433694384255',
            },
            {
                time: '2022-07-18T00:00:00',
                value: '993.98778457462585008',
            },
            {
                time: '2022-07-19T00:00:00',
                value: '1067.2006878628645798',
            },
            {
                time: '2022-07-20T00:00:00',
                value: '1100.9566724855143034',
            },
            {
                time: '2022-07-21T00:00:00',
                value: '1090.6211244088707423',
            },
            {
                time: '2022-07-22T00:00:00',
                value: '1096.6126175957997348',
            },
            {
                time: '2022-07-23T00:00:00',
                value: '1087.3525538345498742',
            },
            {
                time: '2022-07-24T00:00:00',
                value: '1083.9550626663935154',
            },
            {
                time: '2022-07-25T00:00:00',
                value: '1087.3839481196123809',
            },
            {
                time: '2022-07-26T00:00:00',
                value: '1064.7589809006882411',
            },
            {
                time: '2022-07-27T00:00:00',
                value: '1064.5145639619511289',
            },
            {
                time: '2022-07-28T00:00:00',
                value: '1075.5887567206597568',
            },
            {
                time: '2022-07-29T00:00:00',
                value: '1092.4336522611282414',
            },
            {
                time: '2022-07-30T00:00:00',
                value: '1094.8767713534459066',
            },
            {
                time: '2022-07-31T00:00:00',
                value: '1091.8452989619090339',
            },
            {
                time: '2022-08-01T00:00:00',
                value: '1081.590081940682671',
            },
            {
                time: '2022-08-02T00:00:00',
                value: '1075.3058050682014162',
            },
            {
                time: '2022-08-03T00:00:00',
                value: '1070.3051670566367697',
            },
            {
                time: '2022-08-04T00:00:00',
                value: '1068.4859865920035284',
            },
            {
                time: '2022-08-05T00:00:00',
                value: '1070.3355505415837001',
            },
            {
                time: '2022-08-06T00:00:00',
                value: '1073.5122991344183546',
            },
            {
                time: '2022-08-07T00:00:00',
                value: '1067.0543066371038357',
            },
            {
                time: '2022-08-08T00:00:00',
                value: '1074.2821531335003666',
            },
            {
                time: '2022-08-09T00:00:00',
                value: '1085.0955283206191208',
            },
            {
                time: '2022-08-10T00:00:00',
                value: '1060.7731804793575176',
            },
            {
                time: '2022-08-11T00:00:00',
                value: '1091.5439009170159688',
            },
            {
                time: '2022-08-12T00:00:00',
                value: '1088.8467737468450467',
            },
            {
                time: '2022-08-13T00:00:00',
                value: '1105.4482082232556809',
            },
            {
                time: '2022-08-14T00:00:00',
                value: '1109.6022401434529314',
            },
            {
                time: '2022-08-15T00:00:00',
                value: '1096.9839581518902868',
            },
            {
                time: '2022-08-16T00:00:00',
                value: '1086.3300952663344716',
            },
            {
                time: '2022-08-17T00:00:00',
                value: '1084.1075069477183663',
            },
            {
                time: '2022-08-18T00:00:00',
                value: '1080.9006837077397898',
            },
            {
                time: '2022-08-19T00:00:00',
                value: '1079.8858996739733735',
            },
            {
                time: '2022-08-20T00:00:00',
                value: '1075.0267656713665729',
            },
            {
                time: '2022-08-21T00:00:00',
                value: '1075.9497618136684842',
            },
            {
                time: '2022-08-22T00:00:00',
                value: '1076.9976665957449636',
            },
            {
                time: '2022-08-23T00:00:00',
                value: '1080.2158242482258265',
            },
            {
                time: '2022-08-24T00:00:00',
                value: '1080.1077056206283531',
            },
            {
                time: '2022-08-25T00:00:00',
                value: '1068.1818606674385318',
            },
            {
                time: '2022-08-26T00:00:00',
                value: '1079.4963821525111623',
            },
            {
                time: '2022-08-27T00:00:00',
                value: '1037.2461079505493102',
            },
            {
                time: '2022-08-28T00:00:00',
                value: '1037.5041123107176604',
            },
            {
                time: '2022-08-29T00:00:00',
                value: '1035.5151092455239056',
            },
            {
                time: '2022-08-30T00:00:00',
                value: '1036.3670637358772015',
            },
            {
                time: '2022-08-31T00:00:00',
                value: '1034.5834809422930568',
            },
            {
                time: '2022-09-01T00:00:00',
                value: '1034.392919300122317',
            },
            {
                time: '2022-09-02T00:00:00',
                value: '1037.5609954442687883',
            },
            {
                time: '2022-09-03T00:00:00',
                value: '1035.8108240141977674',
            },
            {
                time: '2022-09-04T00:00:00',
                value: '1035.080110527553652',
            },
            {
                time: '2022-09-05T00:00:00',
                value: '1039.9381624384823326',
            },
            {
                time: '2022-09-06T00:00:00',
                value: '1047.8099888573255408',
            },
            {
                time: '2022-09-07T00:00:00',
                value: '1024.3116552333394706',
            },
            {
                time: '2022-09-08T00:00:00',
                value: '1042.7053747304966738',
            },
            {
                time: '2022-09-09T00:00:00',
                value: '1045.284172227100398',
            },
            {
                time: '2022-09-10T00:00:00',
                value: '1084.0910536777285772',
            },
            {
                time: '2022-09-11T00:00:00',
                value: '1098.6314694214313338',
            },
            {
                time: '2022-09-12T00:00:00',
                value: '1096.2434967232071651',
            },
            {
                time: '2022-09-13T00:00:00',
                value: '1098.1096121829264032',
            },
            {
                time: '2022-09-14T00:00:00',
                value: '1035.7133717794078726',
            },
            {
                time: '2022-09-15T00:00:00',
                value: '1040.2324257664809076',
            },
            {
                time: '2022-09-16T00:00:00',
                value: '1035.8925871953411517',
            },
            {
                time: '2022-09-17T00:00:00',
                value: '1036.2270053986648007',
            },
            {
                time: '2022-09-18T00:00:00',
                value: '1039.0423255808137313',
            },
            {
                time: '2022-09-19T00:00:00',
                value: '1030.1435529512000793',
            },
            {
                time: '2022-09-20T00:00:00',
                value: '1031.4510348219641848',
            },
            {
                time: '2022-09-21T00:00:00',
                value: '1030.6986174074618479',
            },
            {
                time: '2022-09-22T00:00:00',
                value: '1027.9398064469071945',
            },
            {
                time: '2022-09-23T00:00:00',
                value: '1033.5277453246645791',
            },
            {
                time: '2022-09-24T00:00:00',
                value: '1036.1985306268758856',
            },
            {
                time: '2022-09-25T00:00:00',
                value: '1028.8460640179085923',
            },
            {
                time: '2022-09-26T00:00:00',
                value: '1024.6160410519114729',
            },
            {
                time: '2022-09-27T00:00:00',
                value: '1032.1117718001155914',
            },
            {
                time: '2022-09-28T00:00:00',
                value: '1016.6063940727026287',
            },
            {
                time: '2022-09-29T00:00:00',
                value: '1014.5773973162026278',
            },
            {
                time: '2022-09-30T00:00:00',
                value: '1011.7965981230219977',
            },
            {
                time: '2022-10-01T00:00:00',
                value: '1003.9204186312513146',
            },
            {
                time: '2022-10-02T00:00:00',
                value: '998.31433282798938',
            },
            {
                time: '2022-10-03T00:00:00',
                value: '997.18654533405661496',
            },
            {
                time: '2022-10-04T00:00:00',
                value: '998.46968104320404448',
            },
            {
                time: '2022-10-05T00:00:00',
                value: '1006.6940478519262332',
            },
            {
                time: '2022-10-06T00:00:00',
                value: '1003.0933794247171083',
            },
            {
                time: '2022-10-07T00:00:00',
                value: '998.16797341189295502',
            },
            {
                time: '2022-10-08T00:00:00',
                value: '990.40556526973265488',
            },
            {
                time: '2022-10-09T00:00:00',
                value: '988.67821082274481928',
            },
            {
                time: '2022-10-10T00:00:00',
                value: '989.83653250532878942',
            },
            {
                time: '2022-10-11T00:00:00',
                value: '986.21465215841355525',
            },
            {
                time: '2022-10-12T00:00:00',
                value: '985.34311136636054956',
            },
            {
                time: '2022-10-13T00:00:00',
                value: '985.77151191938261217',
            },
            {
                time: '2022-10-14T00:00:00',
                value: '984.0048595535307036',
            },
            {
                time: '2022-10-15T00:00:00',
                value: '984.18021383125497901',
            },
            {
                time: '2022-10-16T00:00:00',
                value: '984.07254818997971128',
            },
            {
                time: '2022-10-17T00:00:00',
                value: '982.87761503224394514',
            },
            {
                time: '2022-10-18T00:00:00',
                value: '984.81815163263261837',
            },
            {
                time: '2022-10-19T00:00:00',
                value: '976.46291774632288137',
            },
            {
                time: '2022-10-20T00:00:00',
                value: '967.38091373789489586',
            },
            {
                time: '2022-10-21T00:00:00',
                value: '965.2884231435590528',
            },
            {
                time: '2022-10-22T00:00:00',
                value: '964.89897442745796542',
            },
            {
                time: '2022-10-23T00:00:00',
                value: '965.30349519686682375',
            },
            {
                time: '2022-10-24T00:00:00',
                value: '974.14754419665383656',
            },
            {
                time: '2022-10-25T00:00:00',
                value: '966.83974829817039654',
            },
            {
                time: '2022-10-26T00:00:00',
                value: '1000.0332227083993831',
            },
            {
                time: '2022-10-27T00:00:00',
                value: '1025.7149001497547482',
            },
            {
                time: '2022-10-28T00:00:00',
                value: '1006.8399710711986486',
            },
            {
                time: '2022-10-29T00:00:00',
                value: '1023.2758527042022843',
            },
            {
                time: '2022-10-30T00:00:00',
                value: '1039.7834410649557958',
            },
            {
                time: '2022-10-31T00:00:00',
                value: '1032.2638793169552169',
            },
            {
                time: '2022-11-01T00:00:00',
                value: '1026.6189353472646389',
            },
            {
                time: '2022-11-02T00:00:00',
                value: '1024.6769233782283291',
            },
            {
                time: '2022-11-03T00:00:00',
                value: '1015.8094768200930121',
            },
            {
                time: '2022-11-04T00:00:00',
                value: '1018.3492026861496794',
            },
            {
                time: '2022-11-05T00:00:00',
                value: '1026.4669206923513909',
            },
        ],
        color: '#B57BFF',
    },
    'Alto Risk Capital': {
        data: [
            {
                time: '2022-06-28T00:00:00',
                value: '1000',
            },
            {
                time: '2022-06-29T00:00:00',
                value: '984.304100150817032',
            },
            {
                time: '2022-06-30T00:00:00',
                value: '976.6734361640432812',
            },
            {
                time: '2022-07-01T00:00:00',
                value: '971.10438862195216957',
            },
            {
                time: '2022-07-02T00:00:00',
                value: '971.36354831325609119',
            },
            {
                time: '2022-07-03T00:00:00',
                value: '972.54317707630506662',
            },
            {
                time: '2022-07-04T00:00:00',
                value: '973.40299637628311529',
            },
            {
                time: '2022-07-05T00:00:00',
                value: '979.75192727265454105',
            },
            {
                time: '2022-07-06T00:00:00',
                value: '965.03957245413440565',
            },
            {
                time: '2022-07-07T00:00:00',
                value: '967.73183670102445529',
            },
            {
                time: '2022-07-08T00:00:00',
                value: '990.37229706350719466',
            },
            {
                time: '2022-07-09T00:00:00',
                value: '972.24640643747007116',
            },
            {
                time: '2022-07-10T00:00:00',
                value: '970.63299424873367982',
            },
            {
                time: '2022-07-11T00:00:00',
                value: '944.31772294974338418',
            },
            {
                time: '2022-07-12T00:00:00',
                value: '939.5240642319321357',
            },
            {
                time: '2022-07-13T00:00:00',
                value: '938.04590351292550511',
            },
            {
                time: '2022-07-14T00:00:00',
                value: '938.8624906142581123',
            },
            {
                time: '2022-07-15T00:00:00',
                value: '973.0348985347189535',
            },
            {
                time: '2022-07-16T00:00:00',
                value: '972.31150204662890652',
            },
            {
                time: '2022-07-17T00:00:00',
                value: '985.24746553283764663',
            },
            {
                time: '2022-07-18T00:00:00',
                value: '973.70634191900019831',
            },
            {
                time: '2022-07-19T00:00:00',
                value: '1068.3599830703499241',
            },
            {
                time: '2022-07-20T00:00:00',
                value: '1087.4139676847795806',
            },
            {
                time: '2022-07-21T00:00:00',
                value: '1070.8590369200225051',
            },
            {
                time: '2022-07-22T00:00:00',
                value: '1081.4804189845755365',
            },
            {
                time: '2022-07-23T00:00:00',
                value: '1068.8651646816741403',
            },
            {
                time: '2022-07-24T00:00:00',
                value: '1063.2954894036802433',
            },
            {
                time: '2022-07-25T00:00:00',
                value: '1069.0349565154709232',
            },
            {
                time: '2022-07-26T00:00:00',
                value: '1034.0680306056064966',
            },
            {
                time: '2022-07-27T00:00:00',
                value: '1034.1053070601227288',
            },
            {
                time: '2022-07-28T00:00:00',
                value: '1052.5606821756420121',
            },
            {
                time: '2022-07-29T00:00:00',
                value: '1079.5979809615819042',
            },
            {
                time: '2022-07-30T00:00:00',
                value: '1082.9151814667747146',
            },
            {
                time: '2022-07-31T00:00:00',
                value: '1077.7809219447403536',
            },
            {
                time: '2022-08-01T00:00:00',
                value: '1063.4581132427520344',
            },
            {
                time: '2022-08-02T00:00:00',
                value: '1054.0911023197777784',
            },
            {
                time: '2022-08-03T00:00:00',
                value: '1045.7255640444900683',
            },
            {
                time: '2022-08-04T00:00:00',
                value: '1042.9401542693530866',
            },
            {
                time: '2022-08-05T00:00:00',
                value: '1043.4770908767975148',
            },
            {
                time: '2022-08-06T00:00:00',
                value: '1049.9809954676389641',
            },
            {
                time: '2022-08-07T00:00:00',
                value: '1040.5041549717341203',
            },
            {
                time: '2022-08-08T00:00:00',
                value: '1050.6324014289267159',
            },
            {
                time: '2022-08-09T00:00:00',
                value: '1066.3036814926480653',
            },
            {
                time: '2022-08-10T00:00:00',
                value: '1030.2177917005426516',
            },
            {
                time: '2022-08-11T00:00:00',
                value: '1075.4508649653801331',
            },
            {
                time: '2022-08-12T00:00:00',
                value: '1072.2641208178539693',
            },
            {
                time: '2022-08-13T00:00:00',
                value: '1096.2119825617901869',
            },
            {
                time: '2022-08-14T00:00:00',
                value: '1102.9905955142349346',
            },
            {
                time: '2022-08-15T00:00:00',
                value: '1083.5991919426514572',
            },
            {
                time: '2022-08-16T00:00:00',
                value: '1067.025056396414652',
            },
            {
                time: '2022-08-17T00:00:00',
                value: '1064.5260560687243901',
            },
            {
                time: '2022-08-18T00:00:00',
                value: '1058.9598621029452245',
            },
            {
                time: '2022-08-19T00:00:00',
                value: '1057.956305944058233',
            },
            {
                time: '2022-08-20T00:00:00',
                value: '1050.860750754427621',
            },
            {
                time: '2022-08-21T00:00:00',
                value: '1052.6010821586779689',
            },
            {
                time: '2022-08-22T00:00:00',
                value: '1054.5984029806966704',
            },
            {
                time: '2022-08-23T00:00:00',
                value: '1060.595919428838075',
            },
            {
                time: '2022-08-24T00:00:00',
                value: '1060.2308124159097808',
            },
            {
                time: '2022-08-25T00:00:00',
                value: '1043.975810751807819',
            },
            {
                time: '2022-08-26T00:00:00',
                value: '1059.4893438463503525',
            },
            {
                time: '2022-08-27T00:00:00',
                value: '998.39965556742465456',
            },
            {
                time: '2022-08-28T00:00:00',
                value: '999.32881368984175005',
            },
            {
                time: '2022-08-29T00:00:00',
                value: '996.54744611150766466',
            },
            {
                time: '2022-08-30T00:00:00',
                value: '998.13885950888504872',
            },
            {
                time: '2022-08-31T00:00:00',
                value: '994.95051311346802733',
            },
            {
                time: '2022-09-01T00:00:00',
                value: '994.93052669614143709',
            },
            {
                time: '2022-09-02T00:00:00',
                value: '1000.7000044421315198',
            },
            {
                time: '2022-09-03T00:00:00',
                value: '998.32645118269076792',
            },
            {
                time: '2022-09-04T00:00:00',
                value: '997.86980117857009798',
            },
            {
                time: '2022-09-05T00:00:00',
                value: '1005.2933419618879598',
            },
            {
                time: '2022-09-06T00:00:00',
                value: '1017.3920725947485177',
            },
            {
                time: '2022-09-07T00:00:00',
                value: '980.36275305814573415',
            },
            {
                time: '2022-09-08T00:00:00',
                value: '1007.1691443693730714',
            },
            {
                time: '2022-09-09T00:00:00',
                value: '1010.9546715316056005',
            },
            {
                time: '2022-09-10T00:00:00',
                value: '1060.5731078918162578',
            },
            {
                time: '2022-09-11T00:00:00',
                value: '1079.8352323859850458',
            },
            {
                time: '2022-09-12T00:00:00',
                value: '1076.1205842944265413',
            },
            {
                time: '2022-09-13T00:00:00',
                value: '1078.5104137503855302',
            },
            {
                time: '2022-09-14T00:00:00',
                value: '993.36872775632307758',
            },
            {
                time: '2022-09-15T00:00:00',
                value: '1002.0991247369829516',
            },
            {
                time: '2022-09-16T00:00:00',
                value: '994.12765808389235512',
            },
            {
                time: '2022-09-17T00:00:00',
                value: '993.98538414976492402',
            },
            {
                time: '2022-09-18T00:00:00',
                value: '997.58169402451111902',
            },
            {
                time: '2022-09-19T00:00:00',
                value: '985.49590848594116938',
            },
            {
                time: '2022-09-20T00:00:00',
                value: '986.33742304034148069',
            },
            {
                time: '2022-09-21T00:00:00',
                value: '985.57355727957950931',
            },
            {
                time: '2022-09-22T00:00:00',
                value: '981.967837795144215',
            },
            {
                time: '2022-09-23T00:00:00',
                value: '988.91689554074684222',
            },
            {
                time: '2022-09-24T00:00:00',
                value: '992.30578366874860536',
            },
            {
                time: '2022-09-25T00:00:00',
                value: '980.84396084805756276',
            },
            {
                time: '2022-09-26T00:00:00',
                value: '974.24831415785449758',
            },
            {
                time: '2022-09-27T00:00:00',
                value: '984.49554664103431282',
            },
            {
                time: '2022-09-28T00:00:00',
                value: '966.06281294013657584',
            },
            {
                time: '2022-09-29T00:00:00',
                value: '962.64478910620815926',
            },
            {
                time: '2022-09-30T00:00:00',
                value: '958.95617428404713776',
            },
            {
                time: '2022-10-01T00:00:00',
                value: '948.54882052482143162',
            },
            {
                time: '2022-10-02T00:00:00',
                value: '943.40763281192245761',
            },
            {
                time: '2022-10-03T00:00:00',
                value: '940.82458169561394215',
            },
            {
                time: '2022-10-04T00:00:00',
                value: '943.22952439733011857',
            },
            {
                time: '2022-10-05T00:00:00',
                value: '954.36946225298760032',
            },
            {
                time: '2022-10-06T00:00:00',
                value: '949.95308909766948848',
            },
            {
                time: '2022-10-07T00:00:00',
                value: '943.03554487511513309',
            },
            {
                time: '2022-10-08T00:00:00',
                value: '933.26163228019077528',
            },
            {
                time: '2022-10-09T00:00:00',
                value: '930.6789802601360821',
            },
            {
                time: '2022-10-10T00:00:00',
                value: '932.3772238065978281',
            },
            {
                time: '2022-10-11T00:00:00',
                value: '926.9978277461403229',
            },
            {
                time: '2022-10-12T00:00:00',
                value: '925.67149426562058898',
            },
            {
                time: '2022-10-13T00:00:00',
                value: '925.7662213462733745',
            },
            {
                time: '2022-10-14T00:00:00',
                value: '923.49816132402664992',
            },
            {
                time: '2022-10-15T00:00:00',
                value: '923.47291760823634503',
            },
            {
                time: '2022-10-16T00:00:00',
                value: '923.47225362920485774',
            },
            {
                time: '2022-10-17T00:00:00',
                value: '922.13829506070539419',
            },
            {
                time: '2022-10-18T00:00:00',
                value: '926.00002602717634273',
            },
            {
                time: '2022-10-19T00:00:00',
                value: '915.06274054681872864',
            },
            {
                time: '2022-10-20T00:00:00',
                value: '901.92650050086408289',
            },
            {
                time: '2022-10-21T00:00:00',
                value: '898.38524419666039426',
            },
            {
                time: '2022-10-22T00:00:00',
                value: '898.00289942697408456',
            },
            {
                time: '2022-10-23T00:00:00',
                value: '898.5064917464984902',
            },
            {
                time: '2022-10-24T00:00:00',
                value: '911.42138682911215467',
            },
            {
                time: '2022-10-25T00:00:00',
                value: '900.92923719234302436',
            },
            {
                time: '2022-10-26T00:00:00',
                value: '943.14104943547126085',
            },
            {
                time: '2022-10-27T00:00:00',
                value: '975.83043141825022067',
            },
            {
                time: '2022-10-28T00:00:00',
                value: '951.27854442044670161',
            },
            {
                time: '2022-10-29T00:00:00',
                value: '972.42554942714043487',
            },
            {
                time: '2022-10-30T00:00:00',
                value: '993.50837162763095849',
            },
            {
                time: '2022-10-31T00:00:00',
                value: '982.7080077449672769',
            },
            {
                time: '2022-11-01T00:00:00',
                value: '975.11487075840239672',
            },
            {
                time: '2022-11-02T00:00:00',
                value: '972.04616566402858864',
            },
            {
                time: '2022-11-03T00:00:00',
                value: '960.61666828004181873',
            },
            {
                time: '2022-11-04T00:00:00',
                value: '963.01893041407978478',
            },
            {
                time: '2022-11-05T00:00:00',
                value: '974.53073476811661805',
            },
        ],
        color: '#FEC37B',
    },
    'Basso Risk Capital': {
        data: [
            {
                time: '2022-06-28T00:00:00',
                value: '1000',
            },
            {
                time: '2022-06-29T00:00:00',
                value: '995.03423544318410394',
            },
            {
                time: '2022-06-30T00:00:00',
                value: '993.51098149524844897',
            },
            {
                time: '2022-07-01T00:00:00',
                value: '992.86594290241818145',
            },
            {
                time: '2022-07-02T00:00:00',
                value: '992.26955802008416805',
            },
            {
                time: '2022-07-03T00:00:00',
                value: '992.84387706134361158',
            },
            {
                time: '2022-07-04T00:00:00',
                value: '993.27221201222286589',
            },
            {
                time: '2022-07-05T00:00:00',
                value: '996.05674638362749335',
            },
            {
                time: '2022-07-06T00:00:00',
                value: '992.11616106815347676',
            },
            {
                time: '2022-07-07T00:00:00',
                value: '992.58188591977892842',
            },
            {
                time: '2022-07-08T00:00:00',
                value: '998.74827303905465434',
            },
            {
                time: '2022-07-09T00:00:00',
                value: '994.62758267515566525',
            },
            {
                time: '2022-07-10T00:00:00',
                value: '994.14529483934397253',
            },
            {
                time: '2022-07-11T00:00:00',
                value: '987.81459372040017872',
            },
            {
                time: '2022-07-12T00:00:00',
                value: '986.49047242302737016',
            },
            {
                time: '2022-07-13T00:00:00',
                value: '985.98377244439617231',
            },
            {
                time: '2022-07-14T00:00:00',
                value: '986.70511642593013021',
            },
            {
                time: '2022-07-15T00:00:00',
                value: '1007.3508038130578795',
            },
            {
                time: '2022-07-16T00:00:00',
                value: '1007.0099383139580762',
            },
            {
                time: '2022-07-17T00:00:00',
                value: '1013.4770787000578642',
            },
            {
                time: '2022-07-18T00:00:00',
                value: '1005.1508012690634661',
            },
            {
                time: '2022-07-19T00:00:00',
                value: '1058.5448642436287868',
            },
            {
                time: '2022-07-20T00:00:00',
                value: '1071.6693101121517232',
            },
            {
                time: '2022-07-21T00:00:00',
                value: '1065.9819812877088164',
            },
            {
                time: '2022-07-22T00:00:00',
                value: '1069.3000511476305816',
            },
            {
                time: '2022-07-23T00:00:00',
                value: '1064.8351141579045695',
            },
            {
                time: '2022-07-24T00:00:00',
                value: '1063.1916577718810426',
            },
            {
                time: '2022-07-25T00:00:00',
                value: '1064.3712386116008909',
            },
            {
                time: '2022-07-26T00:00:00',
                value: '1054.6757417067261221',
            },
            {
                time: '2022-07-27T00:00:00',
                value: '1054.4747730102730283',
            },
            {
                time: '2022-07-28T00:00:00',
                value: '1059.0279074030877133',
            },
            {
                time: '2022-07-29T00:00:00',
                value: '1065.55519835798974',
            },
            {
                time: '2022-07-30T00:00:00',
                value: '1066.1644087575294506',
            },
            {
                time: '2022-07-31T00:00:00',
                value: '1064.9445410542783901',
            },
            {
                time: '2022-08-01T00:00:00',
                value: '1059.6304668950886077',
            },
            {
                time: '2022-08-02T00:00:00',
                value: '1056.6267759154579029',
            },
            {
                time: '2022-08-03T00:00:00',
                value: '1055.1381344116113062',
            },
            {
                time: '2022-08-04T00:00:00',
                value: '1054.3946840087556869',
            },
            {
                time: '2022-08-05T00:00:00',
                value: '1054.9964180449088812',
            },
            {
                time: '2022-08-06T00:00:00',
                value: '1055.418348595663354',
            },
            {
                time: '2022-08-07T00:00:00',
                value: '1052.4016350889116462',
            },
            {
                time: '2022-08-08T00:00:00',
                value: '1055.6723655438095597',
            },
            {
                time: '2022-08-09T00:00:00',
                value: '1060.3292562909232687',
            },
            {
                time: '2022-08-10T00:00:00',
                value: '1049.3953917341352107',
            },
            {
                time: '2022-08-11T00:00:00',
                value: '1063.5099313034556288',
            },
            {
                time: '2022-08-12T00:00:00',
                value: '1061.8481142805766915',
            },
            {
                time: '2022-08-13T00:00:00',
                value: '1070.5122498054249243',
            },
            {
                time: '2022-08-14T00:00:00',
                value: '1072.3669581458929644',
            },
            {
                time: '2022-08-15T00:00:00',
                value: '1066.7282305548720792',
            },
            {
                time: '2022-08-16T00:00:00',
                value: '1061.6558041058614014',
            },
            {
                time: '2022-08-17T00:00:00',
                value: '1060.4001253752636878',
            },
            {
                time: '2022-08-18T00:00:00',
                value: '1059.0497841468740236',
            },
            {
                time: '2022-08-19T00:00:00',
                value: '1058.6081891444983211',
            },
            {
                time: '2022-08-20T00:00:00',
                value: '1056.1555026522207543',
            },
            {
                time: '2022-08-21T00:00:00',
                value: '1056.5728056438288573',
            },
            {
                time: '2022-08-22T00:00:00',
                value: '1057.0960993689747446',
            },
            {
                time: '2022-08-23T00:00:00',
                value: '1058.5889793910034005',
            },
            {
                time: '2022-08-24T00:00:00',
                value: '1058.4226611233710889',
            },
            {
                time: '2022-08-25T00:00:00',
                value: '1052.4076740151573132',
            },
            {
                time: '2022-08-26T00:00:00',
                value: '1057.7686121680712654',
            },
            {
                time: '2022-08-27T00:00:00',
                value: '1037.0930821319429561',
            },
            {
                time: '2022-08-28T00:00:00',
                value: '1037.2293792864136314',
            },
            {
                time: '2022-08-29T00:00:00',
                value: '1036.2335699269999345',
            },
            {
                time: '2022-08-30T00:00:00',
                value: '1036.6320330276424317',
            },
            {
                time: '2022-08-31T00:00:00',
                value: '1035.6460883159156079',
            },
            {
                time: '2022-09-01T00:00:00',
                value: '1035.5671080579390663',
            },
            {
                time: '2022-09-02T00:00:00',
                value: '1037.1482211733623282',
            },
            {
                time: '2022-09-03T00:00:00',
                value: '1036.4241813731885474',
            },
            {
                time: '2022-09-04T00:00:00',
                value: '1035.9804842343084397',
            },
            {
                time: '2022-09-05T00:00:00',
                value: '1038.539272691203989',
            },
            {
                time: '2022-09-06T00:00:00',
                value: '1042.0047986892250327',
            },
            {
                time: '2022-09-07T00:00:00',
                value: '1030.3538164392243025',
            },
            {
                time: '2022-09-08T00:00:00',
                value: '1039.2988986220042345',
            },
            {
                time: '2022-09-09T00:00:00',
                value: '1040.6572909731966856',
            },
            {
                time: '2022-09-10T00:00:00',
                value: '1059.2108788730978308',
            },
            {
                time: '2022-09-11T00:00:00',
                value: '1066.1514072453351457',
            },
            {
                time: '2022-09-12T00:00:00',
                value: '1065.2974017877143102',
            },
            {
                time: '2022-09-13T00:00:00',
                value: '1066.8737598739197512',
            },
            {
                time: '2022-09-14T00:00:00',
                value: '1035.9120897214599523',
            },
            {
                time: '2022-09-15T00:00:00',
                value: '1038.4166578419339151',
            },
            {
                time: '2022-09-16T00:00:00',
                value: '1036.4993545281340819',
            },
            {
                time: '2022-09-17T00:00:00',
                value: '1036.5131580529187355',
            },
            {
                time: '2022-09-18T00:00:00',
                value: '1038.0155675513349824',
            },
            {
                time: '2022-09-19T00:00:00',
                value: '1033.8148886894245721',
            },
            {
                time: '2022-09-20T00:00:00',
                value: '1034.4935943554006468',
            },
            {
                time: '2022-09-21T00:00:00',
                value: '1034.2083143086231168',
            },
            {
                time: '2022-09-22T00:00:00',
                value: '1032.8825558276876176',
            },
            {
                time: '2022-09-23T00:00:00',
                value: '1035.7889046890713304',
            },
            {
                time: '2022-09-24T00:00:00',
                value: '1037.0680920325962959',
            },
            {
                time: '2022-09-25T00:00:00',
                value: '1033.4095442195348282',
            },
            {
                time: '2022-09-26T00:00:00',
                value: '1031.4010560194122792',
            },
            {
                time: '2022-09-27T00:00:00',
                value: '1034.8835516696098336',
            },
            {
                time: '2022-09-28T00:00:00',
                value: '1026.9199799794400251',
            },
            {
                time: '2022-09-29T00:00:00',
                value: '1025.7369972832589519',
            },
            {
                time: '2022-09-30T00:00:00',
                value: '1024.9321761307697325',
            },
            {
                time: '2022-10-01T00:00:00',
                value: '1020.2933159250509494',
            },
            {
                time: '2022-10-02T00:00:00',
                value: '1018.0090645886243516',
            },
            {
                time: '2022-10-03T00:00:00',
                value: '1017.207411266227647',
            },
            {
                time: '2022-10-04T00:00:00',
                value: '1018.0016626952071642',
            },
            {
                time: '2022-10-05T00:00:00',
                value: '1021.9858341063639302',
            },
            {
                time: '2022-10-06T00:00:00',
                value: '1020.184417491446173',
            },
            {
                time: '2022-10-07T00:00:00',
                value: '1017.7193339396610989',
            },
            {
                time: '2022-10-08T00:00:00',
                value: '1013.7080450074051069',
            },
            {
                time: '2022-10-09T00:00:00',
                value: '1012.7896343712615804',
            },
            {
                time: '2022-10-10T00:00:00',
                value: '1013.2787285149849267',
            },
            {
                time: '2022-10-11T00:00:00',
                value: '1011.3998564322344537',
            },
            {
                time: '2022-10-12T00:00:00',
                value: '1010.7999199506209053',
            },
            {
                time: '2022-10-13T00:00:00',
                value: '1010.8291261488726389',
            },
            {
                time: '2022-10-14T00:00:00',
                value: '1009.9264186606730061',
            },
            {
                time: '2022-10-15T00:00:00',
                value: '1009.8197123719524428',
            },
            {
                time: '2022-10-16T00:00:00',
                value: '1009.7848759485107759',
            },
            {
                time: '2022-10-17T00:00:00',
                value: '1009.3468583183489748',
            },
            {
                time: '2022-10-18T00:00:00',
                value: '1010.4185979083808679',
            },
            {
                time: '2022-10-19T00:00:00',
                value: '1006.1756200821073393',
            },
            {
                time: '2022-10-20T00:00:00',
                value: '1001.8006923286168107',
            },
            {
                time: '2022-10-21T00:00:00',
                value: '1000.9977051280563064',
            },
            {
                time: '2022-10-22T00:00:00',
                value: '1000.8602698304736173',
            },
            {
                time: '2022-10-23T00:00:00',
                value: '1001.1356381648753514',
            },
            {
                time: '2022-10-24T00:00:00',
                value: '1005.662780026400231',
            },
            {
                time: '2022-10-25T00:00:00',
                value: '1002.0355112173713226',
            },
            {
                time: '2022-10-26T00:00:00',
                value: '1019.1177792683109958',
            },
            {
                time: '2022-10-27T00:00:00',
                value: '1031.9128371773991995',
            },
            {
                time: '2022-10-28T00:00:00',
                value: '1022.514720469648897',
            },
            {
                time: '2022-10-29T00:00:00',
                value: '1030.2251479070302789',
            },
            {
                time: '2022-10-30T00:00:00',
                value: '1038.1686710752618972',
            },
            {
                time: '2022-10-31T00:00:00',
                value: '1034.386398543583715',
            },
            {
                time: '2022-11-01T00:00:00',
                value: '1031.0915733827796932',
            },
            {
                time: '2022-11-02T00:00:00',
                value: '1030.1309538166152838',
            },
            {
                time: '2022-11-03T00:00:00',
                value: '1025.6143438245112553',
            },
            {
                time: '2022-11-04T00:00:00',
                value: '1026.7305160141917368',
            },
            {
                time: '2022-11-05T00:00:00',
                value: '1030.8911372915625113',
            },
        ],
        color: '#7B9EFD',
    },
};
export const CONSTANT_RETURN_BARS = {
    'Moderate Head Capital': {
        data: [
            {
                time: '2022-07-01T00:00:00',
                value: '8.9048615281125747148',
            },
            {
                time: '2022-08-01T00:00:00',
                value: '-4.5627886424913168809',
            },
            {
                time: '2022-09-01T00:00:00',
                value: '-3.0353502233192267051',
            },
            {
                time: '2022-10-01T00:00:00',
                value: '2.2109972780051356238',
            },
        ],
        color: '#B57BFF',
    },
    'Alto Risk Capital': {
        data: [
            {
                time: '2022-07-01T00:00:00',
                value: '8.6842841735618587853',
            },
            {
                time: '2022-08-01T00:00:00',
                value: '-6.8876755419465975668',
            },
            {
                time: '2022-09-01T00:00:00',
                value: '-4.8897542401304686947',
            },
            {
                time: '2022-10-01T00:00:00',
                value: '2.7244021222770435352',
            },
        ],
        color: '#FEC37B',
    },
    'Basso Risk Capital': {
        data: [
            {
                time: '2022-07-01T00:00:00',
                value: '6.3007365377387376195',
            },
            {
                time: '2022-08-01T00:00:00',
                value: '-2.323688986441158548',
            },
            {
                time: '2022-09-01T00:00:00',
                value: '-1.4970001169751958147',
            },
            {
                time: '2022-10-01T00:00:00',
                value: '1.0472646403560537511',
            },
        ],
        color: '#7B9EFD',
    },
};
export const CONSTANT_METRICS = {
    data: [
        {
            fund: 'Moderate Head Capital',
            calmar_ratio: '0.23',
            sharpe_ratio: '-7.37',
            sortino_ratio: '-7.37',
            max_drawdown: '-12.44',
            annualized_return: '-54.65',
            annualized_volatility: '10.38',
        },
        {
            fund: 'Alto Risk Capital',
            calmar_ratio: '0.25',
            sharpe_ratio: '-8.15',
            sortino_ratio: '-8.15',
            max_drawdown: '-16.49',
            annualized_return: '-65.75',
            annualized_volatility: '12.58',
        },
        {
            fund: 'Basso Risk Capital',
            calmar_ratio: '0.20',
            sharpe_ratio: '-8.87',
            sortino_ratio: '-8.87',
            max_drawdown: '-6.28',
            annualized_return: '-32.15',
            annualized_volatility: '4.30',
        },
    ],
    has_error: false,
};
