import { BASE_API_URL, headers } from 'api/common';
import { ShareTransactionsRequest, TotalTradedShares } from 'types/share_transactions';

export const getShareTransactions = ({ token, account_id, query_string }: ShareTransactionsRequest) =>
    account_id &&
    fetch(`${BASE_API_URL}/accounts/${account_id}/share-transactions/${query_string}`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    });

export const getTotalTradedShares = ({ token, account_id }: TotalTradedShares) =>
    account_id &&
    fetch(`${BASE_API_URL}/accounts/${account_id}/total-traded-shares`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    });
