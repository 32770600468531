import classNames from 'classnames';
import { FieldMetaProps } from 'formik';
import { FocusEvent, ReactNode } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const evalutePasswordStrength = (t: TFunction, score: number): string => {
    switch (score) {
        case 0:
        case 1:
            return t('too_weak');
        case 2:
            return t('weak');
        case 3:
            return t('strong');
        case 4:
            return t('too_strong');
        default:
            return null;
    }
};

type CustomInputProps = {
    btn_content?: JSX.Element;
    handleBtnClick?: () => void;
    icon: ReactNode;
    meta?: FieldMetaProps<string>;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    score?: number;
    is_small?: boolean;
    [x: string]: any;
};

const CustomInput = ({
    btn_content,
    handleBtnClick,
    icon,
    meta,
    onFocus = () => {},
    score,
    is_small,
    ...props
}: CustomInputProps) => {
    const { t } = useTranslation();
    const password_strength = evalutePasswordStrength(t, score);

    return (
        <div className={styles.container}>
            <span className={classNames(styles.icon, { [styles['icon--small']]: is_small })}>{icon}</span>
            <input
                className={classNames(styles.input, {
                    [styles['input-error']]: !!meta && meta.touched && meta.error,
                    [styles['input--small']]: is_small,
                })}
                onFocus={onFocus}
                {...props}
            />
            <div
                className={classNames(styles['password-meter'], {
                    [styles[`password-meter-${score}`]]: score,
                })}
                style={{ width: score >= 4 ? '100%' : (0.25 + score * 0.25) * 100 + '%' }}
            />
            {password_strength && (
                <div
                    className={classNames(styles['password-strength'], {
                        [styles[`password-strength-${score}`]]: password_strength,
                    })}
                >
                    {password_strength}
                </div>
            )}
            {btn_content && (
                <span className={styles.btn} onClick={handleBtnClick}>
                    {btn_content}
                </span>
            )}
        </div>
    );
};

export default CustomInput;
