import { call, put } from 'redux-saga/effects';

import * as api from 'api/auth';
import { setGoogleClientParamsSuccess, setGoogleClientParamsFailure } from 'store/platform_slice';
import { GoogleClientParams } from 'types/common';

function* setGoogleClientParams() {
    const response: Response = yield call(() => api.getGoogleClientParams());
    switch (response.status) {
        case 200:
            const formatted: GoogleClientParams = yield response.json();
            yield put(setGoogleClientParamsSuccess(formatted));
            break;
        default:
            yield put(setGoogleClientParamsFailure());
            break;
    }
}

export { setGoogleClientParams };
