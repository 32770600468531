import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './index.module.scss';

type ReportsProps = {
    children?: ReactNode;
    unprotected: boolean;
};

const Reports = (props: ReportsProps) => (
    <div className={classNames(styles.container, { [styles['container-unprotected']]: props.unprotected })}>
        {props.children}
    </div>
);

export default Reports;
