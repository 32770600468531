import classNames from 'classnames';

import styles from './index.module.scss';

type CircularProgressBarProps = {
    is_rise?: boolean;
    percentage: number;
};

const CircularProgressBar = ({ is_rise = true, percentage }: CircularProgressBarProps) => {
    if (Number.isFinite(percentage)) {
        const stroke_dash_offset = 100 - percentage;
        const icon = is_rise ? 'rise_arrow' : 'fall_arrow';

        return (
            <>
                <svg
                    className={classNames(styles.container, {
                        [styles['rise-container']]: is_rise,
                        [styles['fall-container']]: !is_rise,
                    })}
                    viewBox="2 -2 28 36"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        className={classNames(styles.background, {
                            [styles['rise-background']]: is_rise,
                            [styles['fall-background']]: !is_rise,
                        })}
                        r="16"
                        cx="16"
                        cy="16"
                    />
                    <circle
                        className={classNames(styles.progress, {
                            [styles['rise-progress']]: is_rise,
                            [styles['fall-progress']]: !is_rise,
                        })}
                        r="16"
                        cx="16"
                        cy="16"
                        style={{ strokeDashoffset: stroke_dash_offset }}
                    />
                    <image width="8" height="8" x="39%" y="33%" href={`/img/icons/${icon}.svg`} />
                </svg>
            </>
        );
    }
    return null;
};

export default CircularProgressBar;
