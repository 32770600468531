import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import * as api from 'api/users';
import { authorizationHandler } from 'store/client_slice';
import { AuthorizedRequest } from 'types/auth';
import { UserInfo, IChangePasswordRequest, IChangeEmailRequest } from 'types/users';
import {
    getMeSuccess,
    getMe as getMeAction,
    changeEmailSuccess,
    changePasswordSuccess,
    changeEmailFailure,
    changePasswordFailure,
} from 'store/user_slice';

function* getMe(action: PayloadAction<AuthorizedRequest>) {
    const { token } = action.payload;
    const response: Response = yield call(() => api.getMe({ token }));
    switch (response.status) {
        case 200:
            const formatted: UserInfo = yield response.json();
            yield put(getMeSuccess(formatted));
            break;
        case 401:
            yield put(authorizationHandler({ type: 'user/getMe' }));
            break;
        default:
            // TODO - General error handling
            break;
    }
}

function* changeEmail(action: PayloadAction<IChangeEmailRequest>) {
    const { token, email, password, user_id } = action.payload;
    const response: Response = yield call(() => api.changeEmail({ token, email, password, user_id }));
    switch (response.status) {
        case 200:
            yield put(changeEmailSuccess('change_email_success'));
            yield put(getMeAction({ token }));
            break;
        case 400:
            yield put(changeEmailFailure('incorrect_password'));
            break;
        case 401:
            yield put(
                authorizationHandler({
                    type: 'user/updateEmail',
                    args: { email, password, user_id },
                }),
            );
            break;
        default:
            // TODO - General error handling
            break;
    }
}

function* changePassword(action: PayloadAction<IChangePasswordRequest>) {
    const { token, old_password, new_password, user_id } = action.payload;
    const response: Response = yield call(() => api.changePassword({ token, old_password, new_password, user_id }));
    switch (response.status) {
        case 200:
            yield put(changePasswordSuccess('change_password_success'));
            break;
        case 400:
            yield put(changePasswordFailure('incorrect_or_common_password'));
            break;
        case 401:
            yield put(
                authorizationHandler({
                    type: 'user/updateEmail',
                    args: { old_password, new_password, user_id },
                }),
            );
            break;
        default:
            // TODO - General error handling
            break;
    }
}

export { getMe, changeEmail, changePassword };
