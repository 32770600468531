import { BASE_API_URL, headers } from 'api/common';
import {
    ConfirmRegistrationRequest,
    GoogleLoginRequest,
    LoginRequest,
    RefreshRequest,
    ResendConfirmationEmailRequest,
    SignupRequest,
} from 'types/auth';

const path = 'auth';

export const logIn = ({ username, password }: LoginRequest) =>
    fetch(`${BASE_API_URL}/${path}/login/`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ username, password }),
    });

export const googleLogIn = ({ authorization_code, redirect_uri }: GoogleLoginRequest) =>
    fetch(`${BASE_API_URL}/${path}/google/login/`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ authorization_code, redirect_uri }),
    });

export const confirmRegistration = ({ token }: ConfirmRegistrationRequest) =>
    fetch(`${BASE_API_URL}/${path}/confirm-registration/`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ token }),
    });

export const refresh = ({ refresh }: RefreshRequest) =>
    fetch(`${BASE_API_URL}/${path}/refresh/`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ refresh }),
    });

export const getGoogleClientParams = () =>
    fetch(`${BASE_API_URL}/${path}/google/client-params/`, {
        method: 'GET',
        headers,
    });

export const signUp = (body: SignupRequest) =>
    fetch(`${BASE_API_URL}/${path}/register/`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ ...body }),
    });

export const resendConfirmationEmail = (body: ResendConfirmationEmailRequest) =>
    fetch(`${BASE_API_URL}/${path}/resend-confirmation-email/`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ ...body }),
    });
