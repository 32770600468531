import { BASE_API_URL, headers } from 'api/common';

const path = 'simulation-config';

export const getSimulationConfigs = ({ token }) =>
    fetch(`${BASE_API_URL}/${path}/`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    });

export const postSimulationConfig = ({
    token,
    body,
}: {
    token: string;
    body: {
        portfolios: { [x: string]: { [x: string]: number } };
        start_time: string;
        end_time: string;
        initial_investment: number;
    };
}) =>
    fetch(`${BASE_API_URL}/${path}/`, {
        method: 'Post',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    });
export const getSimulationConfigById = ({ id }) =>
    id &&
    fetch(`${BASE_API_URL}/${path}/${id}/`, {
        method: 'GET',
        headers: {
            ...headers,
        },
    });
