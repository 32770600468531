import classNames from 'classnames';
import CustomInput from 'components/custom_input';
import CustomSlider from 'components/custom_slider';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { AssetRowProps } from 'types/share_simulation';

const AssetRow = ({ styles, asset_class_name, asset_class_id, assets, handleChange }: AssetRowProps) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const [is_open, setOpen] = useState(true);
    return (
        <div className={classNames(styles.row, styles['row--assets'], { [styles['row--open']]: is_open })}>
            <div className={styles.col}>
                <div className={styles.asset_class}>{asset_class_name}</div>
                <div>
                    <img
                        onClick={() => setOpen((prev) => !prev)}
                        width="10px"
                        alt="trash icon"
                        src={`img/icons/arrow-down${is_dark_mode ? '-dark' : ''}.svg`}
                        className={classNames(styles.arrow, { [styles['arrow--open']]: is_open })}
                    />
                    <img
                        onClick={handleChange}
                        width="10px"
                        alt="trash icon"
                        src="img/icons/close_menu_icon.svg"
                        className={styles.delete}
                    />
                </div>
            </div>
            <div className={styles.col}>
                <div style={{ width: '65%' }}>
                    <CustomSlider
                        portfo="portfolio-1"
                        onChange={handleChange}
                        value={assets[asset_class_id].portfolio_1 || '0'}
                        handleClickMinus={handleChange}
                        handleClickPlus={handleChange}
                    />
                </div>
                <div style={{ width: '30%' }}>
                    <CustomInput
                        type="number"
                        value={assets[asset_class_id].portfolio_1}
                        onChange={handleChange}
                        is_small
                        icon={<div style={{ lineHeight: '16px' }}>%</div>}
                    />
                </div>
            </div>
            <div className={styles.col}>
                <div style={{ width: '65%' }}>
                    <CustomSlider
                        portfo="portfolio-2"
                        onChange={handleChange}
                        value={assets[asset_class_id].portfolio_2 || '0'}
                        handleClickMinus={handleChange}
                        handleClickPlus={handleChange}
                    />
                </div>
                <div style={{ width: '30%' }}>
                    <CustomInput
                        type="number"
                        value={assets[asset_class_id].portfolio_2}
                        onChange={handleChange}
                        is_small
                        icon={<div style={{ lineHeight: '16px' }}>%</div>}
                    />
                </div>
            </div>
            <div className={styles.col}>
                <div style={{ width: '65%' }}>
                    <CustomSlider
                        portfo="portfolio-3"
                        onChange={handleChange}
                        value={assets[asset_class_id].portfolio_3 || '0'}
                        handleClickMinus={handleChange}
                        handleClickPlus={handleChange}
                    />
                </div>
                <div style={{ width: '30%' }}>
                    <CustomInput
                        type="number"
                        value={assets[asset_class_id].portfolio_3}
                        onChange={handleChange}
                        is_small
                        icon={<div style={{ lineHeight: '16px' }}>%</div>}
                    />
                </div>
            </div>
        </div>
    );
};

export default AssetRow;
