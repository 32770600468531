import { GoogleOAuthProvider } from '@react-oauth/google';
import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import * as Yup from 'yup';

import CustomErrorMessage from 'components/custom_error';
import CustomInput from 'components/custom_input';
import GoogleLogin from 'components/google_login';
import LoaderSpinner from 'components/loader_spinner';
import PasswordInput from 'components/password_input';
import SignupLoginWrapper from 'components/signup_login_wrapper';
import { RootState } from 'store';
import { postLogin } from 'store/client_slice';
import { setGoogleClientParams } from 'store/platform_slice';
import { setTheme } from 'utilities/DOM';
import { useClickOutside } from 'utilities/hooks';

import styles from './index.module.scss';

interface ILoginValues extends FormikValues {
    username: string;
    password: string;
}

const getLoginSchema = (t: TFunction) =>
    Yup.object().shape({
        username: Yup.string().trim().required(t('require_username')),
        password: Yup.string().trim().required(t('require_password')),
    });

const LogIn = () => {
    const [has_input_focus, setInputFocus] = useState(false);
    const [is_google_login_clicked, setGoogleLoginClicked] = useState(false);
    const { is_loading, is_google_login_in_progress, err_message, is_logged_in } = useSelector(
        (state: RootState) => state.client,
    );
    const { is_dark_mode, google_client_params, is_google_loading, is_mobile } = useSelector(
        (state: RootState) => state.platform,
    );
    const is_dispatched = useRef(false);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const wrapper_ref = useRef<HTMLDivElement>(null);
    useClickOutside(wrapper_ref, () => setInputFocus(false));

    useEffect(() => {
        if (!is_dispatched.current && !google_client_params?.client_id) {
            dispatch(setGoogleClientParams());
            is_dispatched.current = true;
        }
    }, [dispatch, google_client_params?.client_id]);

    useEffect(() => {
        if (is_dark_mode) {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    }, [is_dark_mode]);

    const landing_page_path = localStorage.getItem('client.landing_page_path');
    const i18n_language = i18n?.language;
    const landing_page_params = landing_page_path && new URLSearchParams(landing_page_path); //The URLSearchParams interface defines utility methods to work with the query string of a URL
    const landing_page_language = landing_page_params?.get('lang'); // get lang paramater from landing_path
    const landing_path_with_i18n_language =
        i18n_language === landing_page_language
            ? landing_page_path
            : landing_page_path?.replace(`lang=${landing_page_language}`, `lang=${i18n_language}`);

    if (is_logged_in)
        return (
            <Navigate to={landing_path_with_i18n_language ? landing_path_with_i18n_language : '/overview'} replace />
        );

    const handleSubmit = (values: ILoginValues) => {
        const { username, password } = values;
        dispatch(postLogin({ username, password }));
    };

    let submit_btn_content: JSX.Element | string;
    if (is_loading) {
        submit_btn_content = (
            <span className={styles['btn-content']}>
                <LoaderSpinner is_dark_mode={true} />
            </span>
        );
    } else {
        submit_btn_content = t('login.submit');
    }

    return (
        <SignupLoginWrapper is_dark_mode={is_dark_mode} should_hide_rights={is_mobile && has_input_focus}>
            <>
                <div className={styles.form}>
                    <Formik
                        initialValues={{ username: '', password: '' }}
                        validationSchema={getLoginSchema(t)}
                        onSubmit={handleSubmit}
                    >
                        {(props) => (
                            <Form>
                                <div className={styles['form-container']}>
                                    <div>
                                        <div className={styles.username}>
                                            <p className={styles['input-label']}>{t('username')}</p>
                                            <Field name="username">
                                                {({ field, meta }) => (
                                                    <div ref={wrapper_ref}>
                                                        <CustomInput
                                                            icon={
                                                                <img
                                                                    alt="sms notification"
                                                                    src="img/icons/sms-notification.svg"
                                                                />
                                                            }
                                                            type="text"
                                                            placeholder="abc@example.com"
                                                            meta={meta}
                                                            onFocus={() => setInputFocus(true)}
                                                            {...field}
                                                        />
                                                    </div>
                                                )}
                                            </Field>
                                            <ErrorMessage
                                                children={(msg) => <CustomErrorMessage msg={msg} />}
                                                name="username"
                                            />
                                        </div>

                                        <div className={styles.password}>
                                            <p className={styles['input-label']}>{t('password')}</p>
                                            <Field name="password">
                                                {({ field, meta }) => (
                                                    <div ref={wrapper_ref}>
                                                        <PasswordInput
                                                            placeholder="Password"
                                                            autoComplete="off"
                                                            meta={meta}
                                                            onFocus={() => setInputFocus(true)}
                                                            {...field}
                                                        />
                                                    </div>
                                                )}
                                            </Field>
                                            <ErrorMessage
                                                children={(msg) => <CustomErrorMessage msg={msg} />}
                                                name="password"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {err_message && <div className={styles['server-error']}>{t(err_message)}.</div>}
                                        <button
                                            type="submit"
                                            className={classNames(styles.submit, 'button-primary', 'button-48')}
                                            disabled={
                                                !(props.isValid && props.dirty) ||
                                                is_loading ||
                                                is_google_login_in_progress ||
                                                is_google_login_clicked
                                            }
                                        >
                                            <span>{submit_btn_content}</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                {!is_google_loading && google_client_params?.client_id && (
                    <GoogleOAuthProvider clientId={google_client_params.client_id}>
                        <>
                            <div className={styles['google-container']}>
                                <div className={styles['google-separator']} />
                                <span>{t('or')}</span>
                                <div className={styles['google-separator']} />
                            </div>
                            <GoogleLogin
                                is_loading={is_google_login_in_progress}
                                is_normal_login_loading={is_loading}
                                is_signup={false}
                                setGoogleLoginClicked={setGoogleLoginClicked}
                                scopes={google_client_params.scope}
                            />
                        </>
                    </GoogleOAuthProvider>
                )}
                <div className={styles.options}>
                    <div className={styles['no-account']}>
                        <p>
                            <Link to="/sign-up">
                                <span>{t('login.no_account')}</span>
                            </Link>
                        </p>
                    </div>
                    {/* <div className={styles['forgot-password']}>
                        <p>
                            <Link to="/forgot-password">
                                <span>{t('signup.local.forgot_password')}</span>
                            </Link>
                        </p>
                    </div> */}
                </div>
            </>
        </SignupLoginWrapper>
    );
};

export default LogIn;
