import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AnimatedTickmark from 'components/animated_tickmark';
import BouncingDotsLoader from 'components/bouncing_dots_loader';
import UnprotectedRouteWrapper from 'components/unprotected_route_wrapper';
import { RootState } from 'store';
import { postConfirmRegistration } from 'store/client_slice';
import { setTheme } from 'utilities/DOM';

import styles from './index.module.scss';

export default function VerifyEmail() {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const { is_logged_in, err_message } = useSelector((state: RootState) => state.client);
    const [is_loading, setLoading] = useState<boolean>(true);
    const [search_params, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const is_dispatched = useRef(false);

    useEffect(() => {
        const token = search_params.get('token');
        if (token && !is_dispatched.current) {
            dispatch(postConfirmRegistration({ token }));
            setSearchParams({}, { replace: true });
            is_dispatched.current = true;
        } // [TODO]: Handle no token scenario
    }, [dispatch, search_params, setSearchParams]);

    useEffect(() => {
        if (is_dark_mode) {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    }, [is_dark_mode]);

    useEffect(() => {
        if (is_logged_in) {
            localStorage.removeItem('client.signup_info');
            localStorage.removeItem('platform.timer');
            setLoading(false);
            setTimeout(() => {
                navigate('/overview');
            }, 2000);
        }
    }, [is_logged_in, navigate]);

    return (
        <UnprotectedRouteWrapper>
            <div className={styles.container}>
                <img
                    className={styles['loading-img']}
                    src="/img/creating-account.png"
                    alt="creating your account, please wait"
                />
                {is_loading ? (
                    <p>
                        <span className={styles.loading}>{t('verify_email.loading')}</span>
                        <BouncingDotsLoader />
                    </p>
                ) : (
                    <p>{err_message ? err_message : <AnimatedTickmark />}</p>
                )}
            </div>
        </UnprotectedRouteWrapper>
    );
}
