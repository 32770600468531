import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { clearState } from 'store/user_slice';

import ChangePassword from './ChangePassword';
// import UpdateEmail from './UpdateEmail';

import styles from './index.module.scss';

type SettingsModalProps = {
    handleClose: () => void;
};

const SettingsModal = ({ handleClose }: SettingsModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [active, setActive] = React.useState('password');
    React.useEffect(() => {
        return () => {
            dispatch(clearState());
        };
    }, [dispatch]);
    return (
        <>
            <div className={styles.modal} onClick={handleClose}></div>
            <div className={styles['modal-main']}>
                <h2 className={styles.title}>{t('settings.title')}</h2>
                <div>
                    <section className={classNames(styles.tab)}>
                        {/* Hide update email from setting */}
                        {/* <div
                            className={classNames(styles['tab-title'], { [styles['active-tab']]: active === 'email' })}
                            onClick={() => setActive('email')}
                        >
                            {t('settings.email_title')}
                        </div> */}
                        <div
                            className={classNames(styles['tab-title'], {
                                [styles['active-tab']]: active === 'password',
                            })}
                            onClick={() => setActive('password')}
                        >
                            {t('settings.password_title')}
                        </div>
                    </section>
                    {/* Hide update email from setting */}
                    {/* {active === 'email' && <UpdateEmail styles={styles} />} */}
                    {active === 'password' && <ChangePassword styles={styles} />}
                </div>
            </div>
        </>
    );
};

export default SettingsModal;
