import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { IResponseInfo } from 'api/fetcher';
import { T } from 'types/common';
import { getISODateForDatePicker } from 'utilities/date_time';
import { useClickOutside } from 'utilities/hooks';
import { nanoid } from 'nanoid';

type Props = {
    styles: { readonly [key: string]: string };
    t: T;
    setConfigsModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleDeleteConfig: (id: string) => Promise<void>;
    handleClickConfig: (data: any) => void;
    configs: IResponseInfo;
};

const ConfigsListModal = ({ styles, setConfigsModal, t, configs, handleDeleteConfig, handleClickConfig }: Props) => {
    const [is_open, setOpen] = useState(false);
    const [selected_id, setId] = useState('');
    const configs_wrapper = useRef<HTMLDivElement>(null);
    useClickOutside(configs_wrapper, () => {
        setConfigsModal(false);
    });
    useEffect(() => {
        if (configs?.data.length < 1) setConfigsModal(false);
    }, [configs, setConfigsModal]);
    return (
        <>
            <div className={styles.modal} onClick={() => setConfigsModal(false)}></div>
            {is_open && (
                <div className={classNames(styles['modal-main'], styles['config-name'])}>
                    <div className={styles.message}>
                        <img alt="remove" src="img/icons/save-remove.svg" />
                        {t('comparison.remove_question')}
                    </div>
                    <div className={styles['button-container']}>
                        <button
                            className={classNames(styles.apply, 'button-primary', 'button-40')}
                            onClick={() => {
                                handleDeleteConfig(selected_id);
                                setOpen(false);
                            }}
                        >
                            {t('submit')}
                        </button>
                        <button
                            className={classNames(styles.apply, 'button-outline', 'button-40')}
                            onClick={() => {
                                setOpen(false);
                                setId('');
                            }}
                        >
                            {t('close')}
                        </button>
                    </div>
                </div>
            )}
            {!is_open && (
                <div ref={configs_wrapper} className={classNames(styles['modal-main'], styles['configs'])}>
                    <img
                        alt="close modal"
                        src="img/icons/close_menu_icon.svg"
                        className={styles.close}
                        onClick={() => setConfigsModal(false)}
                    />
                    <div className={classNames(styles.header, 'box-header')}>
                        {t('comparison.configs.select_config')}
                    </div>
                    <div className={styles['configs-container']}>
                        {configs?.data?.map((config) => {
                            const data = JSON.parse(config.data);
                            return (
                                <div key={nanoid()} className={styles.card}>
                                    <img
                                        onClick={() => {
                                            setId(config.id);
                                            setOpen(true);
                                        }}
                                        alt="delete"
                                        src="img/icons/close_menu_icon.svg"
                                    />
                                    <div
                                        onClick={() => handleClickConfig(data)}
                                        key={config.id}
                                        className={styles['config-card']}
                                    >
                                        <div className={styles['config-card-name']}>{config.name}</div>
                                        <div className={styles['config-card-line']}>
                                            <div>{t('comparison.configs.created_at')}</div>
                                            <div>{getISODateForDatePicker(new Date(config.created_at))}</div>
                                        </div>
                                        <div className={styles['config-card-line']}>
                                            <div>{t('comparison.configs.fund_number')}</div>
                                            <div>{data.funds.length}</div>
                                        </div>
                                        <div className={styles['config-card-line']}>
                                            <div>{t('comparison.configs.index_number')}</div>
                                            <div>{data.indices.length}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

export default ConfigsListModal;
