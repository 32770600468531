import { T } from 'types/common';

import styles from './index.module.scss';

type TermsPolicyProps = {
    t: T;
};

export default function TermsPolicy({ t }: TermsPolicyProps) {
    return (
        <div>
            <div className={styles['terms-privacy']}>
                <a
                    className={styles.terms}
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://eveince.com/terms-conditions"
                >
                    {t('terms')}
                </a>
                <a
                    className={styles.privacy}
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://eveince.com/privacy-policy"
                >
                    {t('privacy')}
                </a>
            </div>
        </div>
    );
}
