export const histogram = (arr, binRange, min, max) => {
    //inclusive of the first number
    var len = max - min + 1;
    var numberOfBins = Math.ceil(len / binRange);
    var bins = new Array(numberOfBins).fill(0);
    //-min to normalise values for the array
    arr.forEach((x) => bins[Math.ceil((x - min) / binRange)]++);
    return bins;
};
export const bins_array = (min, max, binRange) => {
    let tmp = [];
    for (let i = min; i < max + 1; i += binRange) {
        tmp.push(Math.ceil(i));
    }
    return tmp;
};
