import styles from './index.module.scss';

type LoaderSpinnerProps = {
    is_dark_mode: boolean;
};

const LoaderSpinner = ({ is_dark_mode }: LoaderSpinnerProps) => (
    <img
        src={`/img/icons/refresh${is_dark_mode ? '-dark' : ''}.svg`}
        alt="refresh icon"
        data-testid="loader"
        className={styles.loader}
    />
);

export default LoaderSpinner;
