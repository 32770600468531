import { takeEvery, takeLatest } from 'redux-saga/effects';

import { getAccounts } from './accounts_saga';
import { postLogin, postGoogleLogin, postRefresh, postConfirmRegistration } from './auth_saga';
import { changePassword, getMe, changeEmail } from './users_saga';
import { setGoogleClientParams } from './platform_saga';

function* rootSaga() {
    yield takeLatest('user/getMe', getMe);
    yield takeLatest('user/changeEmail', changeEmail);
    yield takeLatest('user/changePassword', changePassword);
    yield takeEvery('client/postLogin', postLogin);
    yield takeEvery('client/postGoogleLogin', postGoogleLogin);
    yield takeEvery('client/postConfirmRegistration', postConfirmRegistration);
    yield takeLatest('client/getAccounts', getAccounts);
    yield takeLatest('client/refresh', postRefresh);
    yield takeLatest('platform/setGoogleClientParams', setGoogleClientParams);
}

export default rootSaga;
