import { BASE_API_URL, headers } from 'api/common';

const path = 'comparison-config';

export const getComparisonConfigs = ({ token }) =>
    fetch(`${BASE_API_URL}/${path}`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    });

export const postComparisonConfig = ({ token, body }: { token: string; body: { [x: string]: string } }) =>
    fetch(`${BASE_API_URL}/${path}`, {
        method: 'POST',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    });
export const deleteComparisonConfig = ({ token, id }) =>
    fetch(`${BASE_API_URL}/${path}/${id}`, {
        method: 'DELETE',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    });

export const postComparisonLog = ({ token, body }: { token: string; body: { data: string } }) =>
    fetch(`${BASE_API_URL}/comparison-log`, {
        method: 'POST',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    });
