import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getHistoricalNAV } from 'api/accounts';
import { useFetcher } from 'api/fetcher';
import LineChart from 'components/line_chart';
import PeriodSelect from 'components/period_select';
import { RootState } from 'store';
import { T } from 'types/common';
import { CURRENCY_FONT } from 'utilities/constants';
import { getISODateForDatePicker } from 'utilities/date_time';
import { strQueryParams } from 'utilities/obj_utils';
import Tooltip from 'components/info_tooltip';
import { Trans } from 'react-i18next';

type NavProps = {
    quote: string;
    t: T;
};
const TODAY = new Date();
const QuoteSpan = ({ quote }) => {
    return <span>{quote}</span>;
};
const Nav = ({ quote, t }: NavProps) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const [full_screen, setFullScreen] = React.useState(false);

    const { active_account_id } = useSelector((state: RootState) => state.client);
    const [nav_filter_params, setNAVFilterParams] = React.useState({
        start_time: getISODateForDatePicker(new Date(new Date().setDate(TODAY.getDate() - 365))),
        end_time: getISODateForDatePicker(TODAY),
    });
    const [nav_query_params, setNAVQueryParams] = React.useState(strQueryParams(nav_filter_params));
    const { response: historical_nav } = useFetcher(getHistoricalNAV, active_account_id, null, nav_query_params);
    const applyNAVPeriod = (period) => {
        const tmp = {
            start_time: getISODateForDatePicker(period.start_time),
            end_time: getISODateForDatePicker(period.end_time),
        };
        setNAVFilterParams(tmp);
        setNAVQueryParams(strQueryParams(tmp));
    };
    const nav_points = { NAV: { data: historical_nav?.data?.per_currency?.[quote] } };
    if (!historical_nav?.data?.per_currency[quote]) return null;
    return (
        <div>
            <div className={classNames('box', { 'full-screen': full_screen })}>
                <div className={'box-header'}>
                    <div className={'box-header_main'}>
                        {t(`overview.${quote}_nav`)} <div className={'box-header_info'}>({CURRENCY_FONT[quote]})</div>
                    </div>
                    <div className={'box-header_icons'}>
                        <Tooltip
                            info={
                                <Trans i18nKey="docs.overview.nav" t={t} components={[<QuoteSpan quote={quote} />]} />
                            }
                        />
                        {full_screen && (
                            <img
                                alt="close menu icon"
                                className={'full-screen-close'}
                                src="img/icons/close_menu_icon.svg"
                                onClick={() => setFullScreen(false)}
                            />
                        )}
                        {!full_screen && (
                            <img
                                alt="full screen icon"
                                className={'full-screen-icon'}
                                onClick={() => setFullScreen(!full_screen)}
                                src={`img/icons/maximize${is_dark_mode ? '-dark' : ''}.svg`}
                            />
                        )}
                    </div>
                </div>
                <div className={'period-position'}>
                    <PeriodSelect full_screen={full_screen} set_period={applyNAVPeriod} />
                </div>
                <div style={{ height: full_screen ? '75vw' : '20rem', overflow: 'hidden' }}>
                    <LineChart points={nav_points} decimal={quote === 'BTC' ? 5 : 2} />
                </div>
            </div>
        </div>
    );
};

export default Nav;
