import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { IResponseInfo } from 'api/fetcher';
import LineChart from 'components/line_chart';
import PeriodSelect from 'components/period_select';
import { RootState } from 'store';
import { T } from 'types/common';
import Tooltip from 'components/info_tooltip';

type NavpsComparisonProps = {
    applyNAVPSPeriod: (period: any) => void;
    fund_colors: any;
    funds: any;
    navps_chart_data: any;
    navps_list: IResponseInfo;
    styles: { readonly [key: string]: string };
    t: T;
};

const NavpsComparison = ({
    applyNAVPSPeriod,
    fund_colors,
    funds,
    navps_chart_data,
    navps_list,
    styles,
    t,
}: NavpsComparisonProps) => {
    const { is_dark_mode, is_mobile } = useSelector((state: RootState) => state.platform);
    const [full_screen, setFullScreen] = React.useState(false);
    if (navps_chart_data && !Object.keys(navps_chart_data).length) return null;
    return (
        <div>
            <div className={classNames('box', { 'full-screen': full_screen })}>
                <div className={'box-header'}>
                    <div className={'box-header_main'}>{t('overview.comparison')}</div>
                    <div className={'box-header_icons'}>
                        <Tooltip info={t('docs.overview.navps_comparison')} />
                        {full_screen && (
                            <img
                                alt="close menu icon"
                                className={'full-screen-close'}
                                src="img/icons/close_menu_icon.svg"
                                onClick={() => setFullScreen(false)}
                            />
                        )}
                        {!full_screen && (
                            <img
                                alt="full screen icon"
                                className={classNames('full-screen-icon')}
                                onClick={() => setFullScreen(true)}
                                src={`img/icons/maximize${is_dark_mode ? '-dark' : ''}.svg`}
                            />
                        )}
                    </div>
                </div>
                <div className={'period-position'}>
                    <PeriodSelect full_screen={full_screen} set_period={applyNAVPSPeriod} />
                </div>
                <div className={styles['legend-container']}>
                    {navps_chart_data &&
                        fund_colors &&
                        Object.keys(navps_chart_data).every((key) => key !== 'undefined') &&
                        Object.keys(fund_colors).every((key) => fund_colors[key] === navps_chart_data[key]?.color) &&
                        Object.keys(navps_chart_data).map((fund) => (
                            <div className={styles.legend} key={fund}>
                                <div
                                    className={styles['fund-color']}
                                    style={{ background: navps_chart_data[fund].color }}
                                ></div>
                                <div className={styles['fund-name']} style={{ color: navps_chart_data[fund].color }}>
                                    {fund}
                                </div>
                            </div>
                        ))}
                </div>
                <div
                    style={{
                        height: full_screen ? '65vw' : is_mobile && !full_screen ? '20rem' : '24rem',
                        overflow: 'hidden',
                    }}
                >
                    {navps_chart_data &&
                        fund_colors &&
                        Object.keys(fund_colors).every((key) => fund_colors[key] === navps_chart_data[key]?.color) &&
                        funds?.length === navps_list?.data?.length &&
                        Object.keys(navps_chart_data).every((key) => key !== 'undefined') && (
                            <LineChart points={navps_chart_data} />
                        )}
                </div>
            </div>
        </div>
    );
};

export default NavpsComparison;
