import React from 'react';
import { useSelector } from 'react-redux';

import { getAvailableAssets } from 'api/available_assets';
import { useFetcher } from 'api/fetcher';
import { getAllFundsHistoricalNAVPS, getFundAssets, getFundsList } from 'api/funds';
import { useListFetcher } from 'api/listFetcher';
import { getTotalTradedShares } from 'api/share_transactions';
import { RootState } from 'store';
import { getISODateForDatePicker } from 'utilities/date_time';
import { strQueryParams } from 'utilities/obj_utils';
import { getDecimalFormat } from 'utilities/str_utils';

import styles from './index.module.scss';
import OverviewDesktop from './overviewDesktop';
import OverviewMobile from './overviewMobile';
import Decimal from 'decimal.js';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TODAY = new Date();
const FUND_COLORS = ['#B57BFF', '#FEC37B', '#FF7B7B', '#7B9EFD', '#7BFD9F'];

const Overview = () => {
    const { t } = useTranslation();
    const { accounts, active_account_id, is_loaded } = useSelector((state: RootState) => state.client);
    const [navps_filter_params, setNAVPSFilterParams] = React.useState({
        start_time: getISODateForDatePicker(new Date(new Date().setDate(TODAY.getDate() - 365))),
        end_time: getISODateForDatePicker(TODAY),
    });
    const [navps_query_params, setNAVPSQueryParams] = React.useState(strQueryParams(navps_filter_params));
    const { response: funds_list } = useFetcher(getFundsList);
    const getfundName = (id) => {
        return funds_list?.data?.find((obj) => id === obj.id)?.name || id;
    };
    const { response: fund_asset } = useFetcher(getFundAssets, active_account_id);
    const funds = fund_asset?.data && fund_asset?.data.map((obj) => obj.fund.id);
    const fund_colors = funds?.reduce((acc, curr, i) => {
        acc[getfundName(curr)] = FUND_COLORS[i];
        return acc;
    }, {});
    const { response: traded_shares } = useFetcher(getTotalTradedShares, active_account_id);
    const funds_chart_data =
        fund_asset &&
        fund_asset.data &&
        fund_asset.data.map((fund) => ({
            value: new Decimal(fund.nav.USDT),
            name: getfundName(fund.fund.id),
            color: fund_colors[getfundName(fund.fund.id)],
        }));
    const { response: available_assets } = useFetcher(getAvailableAssets, active_account_id);
    const available_chart_data =
        available_assets &&
        available_assets.data?.assets?.reduce(
            (acc, curr) => ({
                name: 'Available Asset',
                value: new Decimal(acc.value).plus(new Decimal(curr.value)),
            }),
            {
                name: 'Available Asset',
                value: 0,
            },
        );
    const pie_chart_data = funds_chart_data && available_chart_data && funds_chart_data.concat(available_chart_data);
    const table_data =
        fund_asset &&
        fund_asset.data &&
        fund_asset.data.map((fund) => {
            return {
                [t('fund_assets.fund_name')]: (
                    <div className={styles['table-fund-cell']}>
                        <div
                            className={styles['fund-color-chart']}
                            style={{ background: fund_colors[getfundName(fund.fund.id)] }}
                        ></div>
                        <div style={{ marginLeft: '1rem' }}>{getfundName(fund.fund.id)}</div>
                    </div>
                ),
                [t('fund_assets.share_count')]: fund.share_count,
                [t('fund_assets.value')]: `₮ ${getDecimalFormat(fund.nav.USDT)}`,
            };
        });
    const total_fund_value =
        fund_asset &&
        fund_asset.data &&
        fund_asset.data.reduce((acc, curr) => new Decimal(acc).plus(new Decimal(curr.nav.USDT)), 0);
    const total_available_value =
        available_assets &&
        available_assets.data?.assets?.reduce((acc, curr) => new Decimal(acc).plus(new Decimal(curr.value)), 0);

    const { response: navps_list } = useListFetcher(getAllFundsHistoricalNAVPS, funds, navps_query_params, [
        fund_asset,
        active_account_id,
    ]);
    const [navps_chart_data, setNAVPSChartData] = React.useState(null);

    React.useEffect(() => {
        const tmp = navps_list?.data?.reduce((acc, curr, i) => {
            const first_point = curr[0];
            acc[getfundName(funds[i])] = {
                data: curr.map(({ time, value }) => ({
                    time,
                    value: new Decimal(value).dividedBy(new Decimal(first_point?.value)),
                })),
                color: fund_colors[getfundName(funds[i])],
            };
            return acc;
        }, {});
        setNAVPSChartData(tmp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navps_list, navps_query_params]);

    const applyNAVPSPeriod = (period) => {
        const tmp = {
            start_time: getISODateForDatePicker(period.start_time),
            end_time: getISODateForDatePicker(period.end_time),
        };
        setNAVPSFilterParams(tmp);
        setNAVPSQueryParams(strQueryParams(tmp));
    };
    if (!is_loaded) return null;
    if (Array.isArray(accounts) && !accounts?.length) return <Navigate to="/funds" replace />;
    return (
        <>
            <OverviewDesktop
                styles={styles}
                pie_chart_data={pie_chart_data}
                table_data={table_data}
                total_available_value={total_available_value}
                total_fund_value={total_fund_value}
                applyNAVPSPeriod={applyNAVPSPeriod}
                fund_colors={fund_colors}
                funds={funds}
                navps_chart_data={navps_chart_data}
                navps_list={navps_list}
                getfundName={getfundName}
                traded_shares={traded_shares}
                t={t}
            />
            <OverviewMobile
                styles={styles}
                pie_chart_data={pie_chart_data}
                table_data={table_data}
                total_available_value={total_available_value}
                total_fund_value={total_fund_value}
                applyNAVPSPeriod={applyNAVPSPeriod}
                fund_colors={fund_colors}
                funds={funds}
                navps_chart_data={navps_chart_data}
                navps_list={navps_list}
                getfundName={getfundName}
                traded_shares={traded_shares}
                t={t}
            />
        </>
    );
};

export default Overview;
