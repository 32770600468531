import { BASE_API_URL, headers } from 'api/common';

const path = 'stats';

export const getstats = ({ token }) =>
    fetch(`${BASE_API_URL}/${path}`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    });
