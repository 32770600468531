import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';
import { RootState } from 'store';
import styles from './index.module.scss';

type Props = {
    info: string | ReactElement;
    is_small?: boolean;
};

interface CustomComponentProps extends React.ComponentPropsWithoutRef<'div'> {
    onMouseEnter(): void;
    onMouseLeave(): void;
}
const CustomComponent = React.forwardRef<HTMLDivElement, CustomComponentProps>((props, ref) => (
    <div ref={ref} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
        {props.children}
    </div>
));
const Tooltip = ({ info, is_small }: Props) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <div className={styles.container}>
            <Popover
                isOpen={isOpen}
                positions={['bottom']}
                content={
                    <div
                        onMouseEnter={() => setIsOpen(true)}
                        onMouseLeave={() => setIsOpen(false)}
                        className={styles['tiny-custom-popover']}
                    >
                        {info}
                    </div>
                }
            >
                <CustomComponent onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
                    <img
                        alt="box info"
                        className={styles[`img${is_small ? '--small' : ''}`]}
                        src={`/img/icons/info-circle${is_dark_mode ? '' : '-light'}.svg`}
                    />
                </CustomComponent>
            </Popover>
        </div>
    );
};

export default Tooltip;
