import { headers, INDICES_API_URL } from 'api/common';

type IndexRequest = {
    query_string: string;
};

export const getIndices = () =>
    fetch(`${INDICES_API_URL}/`, {
        method: 'GET',
        headers: {
            ...headers,
        },
    });

export const getHistoricalIndices = ({ query_string }: IndexRequest) =>
    fetch(`${INDICES_API_URL}/historical${query_string}`, {
        method: 'GET',
        headers: {
            ...headers,
        },
    });

export const getIndicesMetrics = ({ query_string }: IndexRequest) =>
    fetch(`${INDICES_API_URL}/metrics${query_string}`, {
        method: 'GET',
        headers: {
            ...headers,
        },
    });
