import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import { useFetcher } from 'api/fetcher';
import { getSimulationConfigs } from 'api/simulation';
import { T } from 'types/common';
import { getFormattedDateTime, getISODateForDatePicker } from 'utilities/date_time';
import { useClickOutside } from 'utilities/hooks';

type Props = {
    styles: { readonly [key: string]: string };
    t: T;
    setConfigsModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleClickConfig: (data: any) => void;
    asset_classes: Array<{ id: string; name: string }>;
};

const getAssetsNameByid = (id, arr: Array<{ id: string; name: string }>) => {
    return arr.find((asset) => asset.id === id)?.name;
};
const ConfigsListModal = ({ styles, setConfigsModal, t, handleClickConfig, asset_classes }: Props) => {
    const configs_wrapper = useRef<HTMLDivElement>(null);
    const { response: configs } = useFetcher(getSimulationConfigs);
    useClickOutside(configs_wrapper, () => {
        setConfigsModal(false);
    });
    useEffect(() => {
        if (configs?.data?.length < 1) setConfigsModal(false);
    }, [configs, setConfigsModal]);
    return (
        <>
            <div className={styles.modal} onClick={() => setConfigsModal(false)}></div>
            <div ref={configs_wrapper} className={classNames(styles['modal-main'], styles['configs'])}>
                <img
                    alt="close modal"
                    src="img/icons/close_menu_icon.svg"
                    className={styles.close}
                    onClick={() => setConfigsModal(false)}
                />
                <div className={classNames(styles.header, 'box-header')}>{t('simulation.configs.select_config')}</div>
                <div className={styles['configs-container']}>
                    <div className={classNames(styles.card, styles['card-header'])}>
                        <div className={styles['card-col--med']}>{t('simulation.configs.created_at')}</div>
                        <div className={styles['card-col']}>{t('start_date')}</div>
                        <div className={styles['card-col']}>{t('end_date')}</div>
                        <div className={styles['card-col--med']}>Portfolio 1</div>
                        <div className={styles['card-col--med']}>Portfolio 2</div>
                        <div className={styles['card-col--med']}>Portfolio 3</div>
                    </div>
                    {configs?.data?.map((config) => {
                        const { date, time } = getFormattedDateTime(config.created_at);
                        return (
                            <div
                                className={classNames(styles.card, styles['card-hoverable'])}
                                onClick={() => handleClickConfig(config.id)}
                                key={config.id}
                            >
                                <div className={styles['card-col--med']}>{date + ' ' + time}</div>
                                <div className={styles['card-col']}>
                                    {getISODateForDatePicker(new Date(config.start_time))}
                                </div>
                                <div className={styles['card-col']}>
                                    {getISODateForDatePicker(new Date(config.end_time))}
                                </div>
                                <div className={styles['card-col--med']}>
                                    {config.portfolios.portfolio_1 &&
                                        Object.keys(config.portfolios.portfolio_1)
                                            .map((key) => ({
                                                name: getAssetsNameByid(key, asset_classes),
                                                value: config.portfolios.portfolio_1[key],
                                            }))
                                            .map((asset) => (
                                                <div className={styles['card-col-line']} key={asset.name}>
                                                    <div className={styles['card-col-name']}>{asset.name}</div>
                                                    <div className={styles['card-col-value']}>{asset.value}%</div>
                                                </div>
                                            ))}
                                </div>
                                <div className={styles['card-col--med']}>
                                    {config.portfolios.portfolio_2 &&
                                        Object.keys(config.portfolios.portfolio_2)
                                            .map((key) => ({
                                                name: getAssetsNameByid(key, asset_classes),
                                                value: config.portfolios.portfolio_2[key],
                                            }))
                                            .map((asset) => (
                                                <div className={styles['card-col-line']} key={asset.name}>
                                                    <div className={styles['card-col-name']}>{asset.name}</div>
                                                    <div className={styles['card-col-value']}>{asset.value}%</div>
                                                </div>
                                            ))}
                                </div>
                                <div className={styles['card-col--med']}>
                                    {config.portfolios.portfolio_3 &&
                                        Object.keys(config.portfolios.portfolio_3)
                                            .map((key) => ({
                                                name: getAssetsNameByid(key, asset_classes),
                                                value: config.portfolios.portfolio_3[key],
                                            }))
                                            .map((asset) => (
                                                <div className={styles['card-col-line']} key={asset.name}>
                                                    <div className={styles['card-col-name']}>{asset.name}</div>
                                                    <div className={styles['card-col-value']}>{asset.value}%</div>
                                                </div>
                                            ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default ConfigsListModal;
