import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from 'store';
import ShareTransactionDesktop from './ShareTransactionDesktop';
import ShareTransactionMobile from './shareTransactionMobile';

const ShareTransaction = () => {
    const { accounts, active_account_id } = useSelector((state: RootState) => state.client);

    if (Array.isArray(accounts) && !accounts?.length) return <Navigate to="/funds" replace />;
    return (
        <>
            <ShareTransactionDesktop active_account_id={active_account_id} />
            <ShareTransactionMobile active_account_id={active_account_id} />
        </>
    );
};

export default ShareTransaction;
