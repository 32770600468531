import { useSearchParams } from 'react-router-dom';

const ALLOWED_QUERY_PARAMS = ['account', 'lang'];

export default function useQueryString() {
    const [search_params] = useSearchParams();
    const search_obj = {};
    search_params.forEach((value, key) => (search_obj[key] = value));
    let query_string = '';
    Object.keys(search_obj)
        .filter((k) => ALLOWED_QUERY_PARAMS.includes(k))
        .forEach((k) => (query_string += `${k}=${search_obj[k]}&`));
    return '?' + query_string.slice(0, -1);
}
