import Decimal from 'decimal.js';
import ReactEcharts from 'echarts-for-react';
import { EChartsOption } from 'echarts/types/dist/echarts';
import { useSelector } from 'react-redux';

import { RootState } from 'store';
import { PieChartProps } from 'types/pie_chart';
import { getDecimalFormat } from 'utilities/str_utils';

import styles from './index.module.scss';
const PieChart = ({ data, configs, t }: PieChartProps) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);

    const total_value = data?.reduce((acc, curr) => new Decimal(acc).plus(new Decimal(curr.value)).toNumber(), 0);
    const percented = data?.map(({ name, value, color }) => ({
        itemStyle: { color },
        name,
        value: new Decimal(value).dividedBy(new Decimal(total_value)).times(100).toNumber(),
    }));
    const option: EChartsOption = {
        backgroundColor: 'transparent',
        title: {
            text: t('total_value'),
            textStyle: {
                fontSize: '2.1rem',
                fontWeight: 400,
            },
            subtext: `₮ ${getDecimalFormat(total_value)}`,
            subtextStyle: {
                fontSize: '1.5rem',
                fontWeight: 400,
            },
            textAlign: 'center',
            top: configs.title_position.top || 'auto',
            left: configs.title_position.left || 'auto',
            bottom: configs.title_position.bottom || 'auto',
            right: configs.title_position.right || 'auto',
        },
        tooltip: {
            formatter: '{d}%',
            borderColor: `${is_dark_mode ? '#111018' : '#F7F9FB'}`,
            backgroundColor: `${is_dark_mode ? '#111018' : '#F7F9FB'}`,
            textStyle: { color: `${is_dark_mode ? '#CED4DA' : '#868E96'}`, fontSize: '1rem' },
        },
        legend: {
            type: 'scroll',
            top: configs.legend_position.top || 'auto',
            left: configs.legend_position.left || 'auto',
            bottom: configs.legend_position.bottom || 'auto',
            right: configs.legend_position.right || 'auto',
            orient: 'vertical',
            icon: 'circle',
            formatter: (name) => {
                const item = percented.filter((i) => i.name === name);
                const value = getDecimalFormat(item[0]['value'] || 0);
                return `${name}  ${value}%`;
            },
            pageIcons: {
                vertical: ['image://img/icons/arrow-up.svg', 'image://img/icons/arrow-down.svg'],
            },
            pageTextStyle: {
                fontSize: 10,
            },
        },
        series: [
            {
                type: 'pie',
                center: configs.chart_center,
                radius: configs.chart_radius,
                avoidLabelOverlap: true,
                itemStyle: {
                    borderRadius: 100,
                    borderColor: `${is_dark_mode ? '#21202F' : '#fff'}`,
                    borderWidth: 5,
                },
                label: {
                    show: false,
                },
                emphasis: {
                    label: {
                        show: false,
                    },
                },
                labelLine: {
                    show: false,
                },
                data: percented,
            },
        ],
    };

    return <ReactEcharts theme={is_dark_mode ? 'dark' : 'light'} option={option} className={styles.chart} />;
};

export default PieChart;
