import classNames from 'classnames';
import { nanoid } from 'nanoid';

import { usePagination } from 'utilities/hooks';

import styles from './index.module.scss';

type PaginationProps = {
    onPageChange: (n: number) => void;
    currentPage: number;
    total_pages: number;
};

const Pagination = ({ onPageChange, currentPage, total_pages }: PaginationProps) => {
    const paginationRange = usePagination({
        currentPage,
        total_pages,
    });

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    return (
        <ul className={classNames(styles['pagination-container'])}>
            {paginationRange.map((pageNumber) => {
                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === 'DOTS') {
                    return (
                        <li key={nanoid()} className={classNames(styles['pagination-item'], styles.dots)}>
                            &#8230;
                        </li>
                    );
                }

                // Render our Page Pills
                return (
                    <li
                        className={classNames(styles['pagination-item'], {
                            [styles.selected]: pageNumber === currentPage,
                        })}
                        onClick={() => onPageChange(pageNumber)}
                        key={nanoid()}
                    >
                        {pageNumber}
                    </li>
                );
            })}
        </ul>
    );
};

export default Pagination;
