import React from 'react';
import { useSelector } from 'react-redux';

import Header from 'components/header';
import Reports from 'components/reports';
import Sidebar from 'components/sidebar/Sidebar';
import NavigationButton from 'components/navigation_button/NavigationButton';
import { LayoutProps } from 'types/layout';
import { useQueryString, useRefreshToken } from 'utilities/hooks';
import { RootState } from 'store';

import styles from './index.module.scss';

const Layout = (props: LayoutProps) => {
    const { accounts } = useSelector((state: RootState) => state.client);
    const query_string = useQueryString();

    /*
     * This hook will catches any API call with status 401 and tries to obtain
     * a new JWT token. Then it recovers any API calls which failed by re-calling.
     */
    useRefreshToken();

    React.useEffect(() => {
        localStorage.removeItem('client.landing_page_path');
    }, []);

    return (
        <div className={styles['layout-container']}>
            <Reports unprotected={props.unprotected}>{props.children}</Reports>
            {!props.unprotected && (
                <>
                    <Sidebar accounts={accounts} current_page={props.current_page} query_string={query_string} />
                    <NavigationButton
                        accounts={accounts}
                        current_page={props.current_page}
                        query_string={query_string}
                    />
                </>
            )}
            <Header unprotected={props.unprotected} query_string={query_string} />
        </div>
    );
};

export default Layout;
