import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { clearRetries, refresh } from 'store/client_slice';

const useRefreshToken = () => {
    const { err_code, refresh_token, jwt_token, retries } = useSelector((state: RootState) => state.client);

    const dispatch = useDispatch();

    // Catch 401 on any API call
    React.useEffect(() => {
        if (err_code === 401) {
            dispatch(refresh({ refresh: refresh_token }));
        }
    }, [dispatch, err_code, refresh_token]);

    // Recover from 401
    React.useEffect(() => {
        // Re-dispatch failed API calls
        retries.forEach((item) => {
            dispatch({ type: item.type, payload: { ...item.args, token: jwt_token } });
        });
        // Clear the state
        dispatch(clearRetries());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwt_token]);
};

export default useRefreshToken;
