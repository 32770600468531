import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IResponseInfo } from 'api/fetcher';
import CustomInput from 'components/custom_input';
import CustomRangePicker from 'components/custom_range_picker';
import DropdownSelector from 'components/dropdown_selector';
import { RootState } from 'store';
import { getISODateForDatePicker } from 'utilities/date_time';
import { COMPARISON_DEFAULT_PERIOD } from 'utilities/constants';
import { indicesData } from 'types/common';

type Filter = { funds: Array<string>; indices: Array<string>; initial: number; range: Array<Date> };
type FilterProps = {
    styles: { readonly [key: string]: string };
    funds: IResponseInfo;
    indices: { data: indicesData; has_error: boolean };
    filters: Filter;
    setFilters: React.Dispatch<React.SetStateAction<Filter>>;
    setSelectedFunds: React.Dispatch<React.SetStateAction<string[]>>;
    handleApplyFilter: () => void;
};

const get_index_id = (name, obj: indicesData) => {
    return Object.entries(obj).find(([, value]) => value.label === name)[0];
};

const MAXIMUM_SELECTED = 13;
function MaxSelectedSpan({ value }) {
    return <span>{value}</span>;
}
const Filters = ({ styles, funds, indices, filters, setFilters, setSelectedFunds, handleApplyFilter }: FilterProps) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const { t } = useTranslation();
    const [filteredIndices, setFilteredIndices] = useState([]);
    const [valid_start_time, setValidStartTime] = useState(new Date());
    const valid_funds = funds?.data?.filter((fund) => fund.quote === 'USDT');
    useEffect(() => {
        if (valid_funds) {
            setFilters((old) => ({
                ...old,
                funds: valid_funds.map((fund) => fund.id),
            }));
            const valid_time =
                valid_funds.length > 0
                    ? new Date(
                          Math.max(...valid_funds.map(({ first_navps_time }) => new Date(first_navps_time).getTime())),
                      )
                    : new Date();
            setValidStartTime(valid_time);
            setSelectedFunds(valid_funds.map((fund) => fund.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [funds]);

    useEffect(() => {
        if (indices.data)
            setFilteredIndices(
                Object.entries(indices.data)
                    .filter(([index]) => !filters.indices.includes(index))
                    .map(([, value]) => value.label),
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indices]);

    const handleClickFund = (fund) => {
        let tmp = [...filters.funds];
        if (tmp.includes(fund.id)) {
            tmp.splice(tmp.indexOf(fund.id), 1);
        } else {
            tmp.push(fund.id);
        }
        setFilters((old) => ({ ...old, funds: tmp }));
    };

    const handleIndicesChange = (e, index) => {
        let tmp = [...filters.indices];
        let filtered_tmp = [...filteredIndices];
        if (!e) {
            tmp.push(get_index_id(index, indices.data));
            filtered_tmp.splice(filtered_tmp.indexOf(index), 1);
            setFilteredIndices(filtered_tmp);
        } else {
            const filtered = Object.values(indices.data)
                .map((ind: { label: string }) => ind.label)
                .filter((item) => !tmp.includes(get_index_id(item, indices.data)))
                .filter((item) => item.toLowerCase().includes(e.target.value.toLowerCase()));
            setFilteredIndices(filtered);
        }

        setFilters((old) => ({ ...old, indices: tmp }));
    };
    const handleDeleteIndex = (index) => {
        let tmp = [...filters.indices];
        tmp.splice(tmp.indexOf(index), 1);
        const filtered = Object.values(indices.data)
            .map((index: { label: string }) => index.label)
            .filter((item) => !tmp.includes(get_index_id(item, indices.data)));

        setFilteredIndices(filtered);
        setFilters((old) => ({ ...old, indices: tmp }));
    };

    const handle_change_initial_investment = (e) => {
        const tmp = { ...filters };
        tmp['initial'] = e.target.value;
        setFilters(tmp);
    };

    const handleRange = (range) => {
        setFilters((old) => ({ ...old, range }));
    };

    useEffect(() => {
        const all_selected_dates = [
            ...filters.funds.map((fund) => valid_funds?.find((el) => el.id === fund).first_navps_time),
            ...filters.indices.map((index) => indices?.data?.[index].start_time),
        ];
        const valid_time =
            all_selected_dates.length > 0
                ? new Date(Math.max(...all_selected_dates.map((date) => new Date(date).getTime())))
                : COMPARISON_DEFAULT_PERIOD;
        setValidStartTime(valid_time);
        if (valid_time.getTime() > new Date(filters.range[0]).getTime()) {
            setFilters((old) => ({ ...old, range: [valid_time, filters.range[1]] }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.funds, filters.indices]);

    const disabled_apply = filters.indices.length > MAXIMUM_SELECTED || !filters.initial;
    return (
        <div className="box">
            <div className={styles.header}>{t('comparison.filters.funds')}</div>
            {valid_funds?.map((fund) => {
                return (
                    <div key={fund.id} onClick={() => handleClickFund(fund)} className={styles.items}>
                        <img
                            alt="check item"
                            src={`img/icons/${
                                filters.funds.includes(fund.id)
                                    ? 'selected'
                                    : `mobile-unchecked${is_dark_mode ? '-dark' : ''}`
                            }.svg`}
                        />
                        <div>{fund.name}</div>
                    </div>
                );
            })}
            <div className={styles.header}>{t('comparison.filters.indices')}</div>
            {filteredIndices && (
                <DropdownSelector
                    icon={<img width="16px" alt="search" src="img/icons/search-normal.svg" />}
                    is_light={!is_dark_mode}
                    items={filteredIndices}
                    placeholder={t('comparison.filters.search_indices')}
                    type="text"
                    onChange={handleIndicesChange}
                    required={false}
                />
            )}
            <div className={styles['indices-container']}>
                {filters.indices.map((index) => {
                    return (
                        <div key={index} className={styles['selected-index']}>
                            <span>{indices.data[index].label}</span>
                            <img
                                onClick={() => handleDeleteIndex(index)}
                                width="10px"
                                alt="trash icon"
                                src="img/icons/close_menu_icon.svg"
                            />
                        </div>
                    );
                })}
            </div>
            <div className={styles.header}>{t('comparison.filters.period')}</div>
            <div className={styles['available-period-container']}>
                <div>{t('comparison.filters.available_period')}</div>
                <div className={styles.dates}>
                    <div className={styles['dates-item']}>
                        <div>{t('start_date')}</div>
                        <div>{getISODateForDatePicker(valid_start_time)}</div>
                    </div>
                    <div className={styles['dates-item']}>
                        <div>{t('end_date')}</div>
                        <div>{getISODateForDatePicker(new Date()) + ` (${t('comparison.filters.today')})`}</div>
                    </div>
                </div>
            </div>
            <CustomRangePicker dropdown min_date={valid_start_time} range={filters.range} setRange={handleRange} />
            <div className={styles.header}>{t('comparison.filters.initial')}</div>
            <CustomInput
                icon={<img width="16px" alt="tether" src="img/icons/dollar-circle.svg" />}
                placeholder="Enter Initial Value"
                type="number"
                onChange={handle_change_initial_investment}
                value={filters.initial}
            />

            <div className={styles['button-container']}>
                <button
                    className={classNames(styles.apply, 'button-primary', 'button-48')}
                    onClick={handleApplyFilter}
                    disabled={disabled_apply}
                >
                    {t('apply')}
                </button>
            </div>
            {disabled_apply && (
                <>
                    {filters.indices.length > MAXIMUM_SELECTED && (
                        <div className={styles['message-disabled']}>
                            <img alt="error" src="img/icons/Error.svg" />
                            <div>
                                <Trans
                                    i18nKey="comparison.button_msg"
                                    t={t}
                                    components={[<MaxSelectedSpan value={MAXIMUM_SELECTED} />]}
                                />
                            </div>
                        </div>
                    )}
                    {!filters.initial && (
                        <div className={styles['message-disabled']}>
                            <img alt="error" src="img/icons/Error.svg" />
                            <div>{t('comparison.initial_value_msg')}</div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Filters;
