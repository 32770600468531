import classNames from 'classnames';
import CustomInput from 'components/custom_input';
import CustomSlider from 'components/custom_slider';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { AssetRowProps } from 'types/simulation';

const AssetRow = ({ styles, asset_class_name, asset_class_id, assets, setAssets }: AssetRowProps) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const [is_open, setOpen] = useState(true);
    const handleDeleteAsset = (asset) => {
        let tmp = { ...assets };
        delete tmp[asset];
        setAssets(tmp);
    };
    const handleChangeSlider = (key, value) => {
        const valid_value = value?.toString().replace(/^0+(?!\.|$)/, '') || 0;
        if (Number(value) >= 0 && Number(value) <= 100) {
            let tmp = { ...assets };
            tmp[asset_class_id][key] = valid_value;
            setAssets(tmp);
        }
    };

    return (
        <div className={classNames(styles.row, styles['row--assets'], { [styles['row--open']]: is_open })}>
            <div className={styles.col}>
                <div className={styles.asset_class}>{asset_class_name}</div>
                <div>
                    <img
                        onClick={() => setOpen((prev) => !prev)}
                        width="10px"
                        alt="trash icon"
                        src={`img/icons/arrow-down${is_dark_mode ? '-dark' : ''}.svg`}
                        className={classNames(styles.arrow, { [styles['arrow--open']]: is_open })}
                    />
                    <img
                        onClick={() => handleDeleteAsset(asset_class_id)}
                        width="10px"
                        alt="trash icon"
                        src="img/icons/close_menu_icon.svg"
                        className={styles.delete}
                    />
                </div>
            </div>
            <div className={styles.col}>
                <div style={{ width: '65%' }}>
                    <CustomSlider
                        portfo="portfolio-1"
                        onChange={(e) => handleChangeSlider('portfolio_1', e.target.value)}
                        value={assets[asset_class_id].portfolio_1 || '0'}
                        handleClickMinus={() => {
                            handleChangeSlider('portfolio_1', Number(assets[asset_class_id].portfolio_1) - 1);
                        }}
                        handleClickPlus={() => {
                            handleChangeSlider('portfolio_1', Number(assets[asset_class_id].portfolio_1) + 1);
                        }}
                    />
                </div>
                <div style={{ width: '30%' }}>
                    <CustomInput
                        type="number"
                        value={assets[asset_class_id].portfolio_1}
                        onChange={(e) => handleChangeSlider('portfolio_1', e.target.value)}
                        is_small
                        icon={<div style={{ lineHeight: '16px' }}>%</div>}
                    />
                </div>
            </div>
            <div className={styles.col}>
                <div style={{ width: '65%' }}>
                    <CustomSlider
                        portfo="portfolio-2"
                        onChange={(e) => handleChangeSlider('portfolio_2', e.target.value)}
                        value={assets[asset_class_id].portfolio_2 || '0'}
                        handleClickMinus={() => {
                            handleChangeSlider('portfolio_2', Number(assets[asset_class_id].portfolio_2) - 1);
                        }}
                        handleClickPlus={() => {
                            handleChangeSlider('portfolio_2', Number(assets[asset_class_id].portfolio_2) + 1);
                        }}
                    />
                </div>
                <div style={{ width: '30%' }}>
                    <CustomInput
                        type="number"
                        value={assets[asset_class_id].portfolio_2}
                        onChange={(e) => handleChangeSlider('portfolio_2', e.target.value)}
                        is_small
                        icon={<div style={{ lineHeight: '16px' }}>%</div>}
                    />
                </div>
            </div>
            <div className={styles.col}>
                <div style={{ width: '65%' }}>
                    <CustomSlider
                        portfo="portfolio-3"
                        onChange={(e) => handleChangeSlider('portfolio_3', e.target.value)}
                        value={assets[asset_class_id].portfolio_3 || '0'}
                        handleClickMinus={() => {
                            handleChangeSlider('portfolio_3', Number(assets[asset_class_id].portfolio_3) - 1);
                        }}
                        handleClickPlus={() => {
                            handleChangeSlider('portfolio_3', Number(assets[asset_class_id].portfolio_3) + 1);
                        }}
                    />
                </div>
                <div style={{ width: '30%' }}>
                    <CustomInput
                        type="number"
                        value={assets[asset_class_id].portfolio_3}
                        onChange={(e) => handleChangeSlider('portfolio_3', e.target.value)}
                        is_small
                        icon={<div style={{ lineHeight: '16px' }}>%</div>}
                    />
                </div>
            </div>
        </div>
    );
};

export default AssetRow;
