import classNames from 'classnames';
import Tooltip from 'components/info_tooltip';
import Decimal from 'decimal.js';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from 'store';
import { T } from 'types/common';
import { CONSTANT_RETURN_BARS, CONSTANT_RETURN_POINTS } from 'utilities/constants';
import { getISODateForDatePicker } from 'utilities/date_time';
import { getDecimalFormat } from 'utilities/str_utils';

export type Traces = {
    [x: string]: { color?: string; data: Array<{ time: string; value: number | Decimal }> };
};

export type MonthlyReturnChartProps = {
    points: Traces;
    bars: Traces;
    decimal?: number;
    show_legend?: boolean;
    styles: { readonly [key: string]: string };
    t: T;
    initial_investment: number;
};
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
function CustomHeader({ value }) {
    return <span>{value}</span>;
}
const MonthlyReturnChart = ({
    points,
    bars,
    decimal = 2,
    styles,
    t,
    show_legend = false,
    initial_investment,
}: MonthlyReturnChartProps) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const show_monthly_chart =
        Object.keys(bars).every((key) => key !== 'undefined') &&
        Object.values(bars).every((value) => value.data.length > 0);

    const valid_bars = show_monthly_chart ? bars : CONSTANT_RETURN_BARS;
    const valid_points = show_monthly_chart ? points : CONSTANT_RETURN_POINTS;
    const categories =
        valid_bars && Object.values(valid_bars)[0]?.data?.map((point) => monthNames[new Date(point.time).getMonth()]);
    if (Object.keys(points).length < 1) {
        return null;
    } else {
        const barChartData = Object.keys(valid_bars).map((trace) => {
            const values = valid_bars[trace].data?.map((point) => getDecimalFormat(point.value, decimal));
            const color = valid_bars[trace].color;

            return {
                name: trace,
                data: values,
                type: 'bar',
                emphasis: {
                    focus: 'none',
                },
                ...(color ? { lineStyle: { color }, itemStyle: { color } } : {}),
                showSymbol: false,
                smooth: true,
                barMaxWidth: 40,
                xAxisIndex: 0,
                yAxisIndex: 0,
            };
        });
        const lineChartData = Object.keys(valid_points).map((trace) => {
            const values = valid_points[trace].data?.map((point) => {
                const date = new Date(point.time);
                return [getISODateForDatePicker(date, 'not splitted'), getDecimalFormat(point.value, decimal)];
            });
            const color = valid_points[trace].color;
            return {
                name: trace,
                data: values,
                type: 'line',
                emphasis: {
                    focus: 'none',
                },
                ...(color ? { lineStyle: { color }, itemStyle: { color } } : {}),
                showSymbol: false,
                smooth: true,
                xAxisIndex: 1,
                yAxisIndex: 1,
            };
        });
        const option: EChartsOption = {
            grid: [
                {
                    x: '7%',
                    y: '10%',
                    left: 'auto',
                    right: 'auto',
                    width: 'auto',
                    height: '50%',
                    containLabel: true,
                },
                {
                    x: '7%',
                    y: '67%',
                    left: 'auto',
                    right: 'auto',
                    width: 'auto',
                    height: '30%',
                    containLabel: true,
                },
            ],
            backgroundColor: 'transparent',
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    animation: false,
                },
                textStyle: { color: `${is_dark_mode ? '#CED4DA' : '#868E96'}`, fontSize: '1rem' },
                borderColor: `${is_dark_mode ? '#111018' : '#F7F9FB'}`,
                backgroundColor: `${is_dark_mode ? '#111018' : '#F7F9FB'}`,
            },
            xAxis: [
                {
                    type: 'category',
                    data: categories,
                    gridIndex: 1,
                },
                {
                    type: 'time',
                    splitLine: {
                        show: false,
                    },
                    boundaryGap: false,
                    axisPointer: { label: { show: false } },
                    axisTick: { show: false },
                    axisLine: { show: false },
                    axisLabel: { margin: 20 },
                    gridIndex: 0,
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine: {
                        lineStyle: {
                            color: `${is_dark_mode ? '#495057' : '#E9ECEF'}`,
                            type: 'dashed',
                        },
                    },
                    axisPointer: { label: { show: false } },
                    scale: true,
                    axisLabel: {
                        formatter: '{value} %',
                    },
                    gridIndex: 1,
                },
                {
                    type: 'value',
                    splitLine: {
                        lineStyle: {
                            color: `${is_dark_mode ? '#495057' : '#E9ECEF'}`,
                            type: 'dashed',
                        },
                    },
                    axisPointer: { label: { show: false } },
                    scale: true,
                    gridIndex: 0,
                },
            ],
            dataZoom: [{ type: 'inside' }],
            series: [...barChartData, ...lineChartData],
            color: ['#B57BFF', '#FEC37B', '#FF7B7B', '#7B9EFD', '#7BFD9F'],
            ...(show_legend
                ? {
                      legend: {
                          show: show_legend,
                          padding: [0, 0, 250, 0],
                          icon: 'circle',
                          width: '80%',
                          itemWidth: 8,
                          textStyle: { fontSize: 10 },
                          data: Object.keys(points).map((trace) => ({
                              name: trace,
                              textStyle: { color: points[trace].color },
                          })),
                      },
                  }
                : {}),
        };

        return (
            <div className={classNames(styles.box, 'box')}>
                <div className={classNames(styles.header, 'box-header')}>
                    <div className={'box-header_main'}>
                        <Trans
                            i18nKey="comparison.monthly_return"
                            t={t}
                            components={[<CustomHeader value={initial_investment} />]}
                        />
                    </div>
                    <Tooltip
                        info={
                            <>
                                <div style={{ marginBottom: '0.5rem' }}>
                                    {t('docs.comparison.hypothetical_growth.up')}
                                </div>
                                <div>{t('docs.comparison.hypothetical_growth.below')}</div>
                            </>
                        }
                    />
                </div>

                <div className={classNames({ [styles.blurred]: !show_monthly_chart })} style={{ height: '45rem' }}>
                    <ReactEcharts
                        theme={is_dark_mode ? 'dark' : 'light'}
                        option={option}
                        notMerge={true}
                        style={{ height: '100%', width: '100%' }}
                    />
                </div>
                {!show_monthly_chart && <div className={styles['no-return']}>{t('simulation.no_return')}</div>}
            </div>
        );
    }
};

export default MonthlyReturnChart;
