import Decimal from 'decimal.js';

export const valuesToFix = (values, precision = 8) => values?.map((value) => getDecimalFormat(value, precision));

export const getDecimalFormat = (number, precision = 2) => {
    if (number != null) {
        const decimal_string = new Decimal(number).toFixed();
        if (decimal_string === 'NaN') return '0';
        const splitted_by_dot = decimal_string.split('.');
        const decimal_part = splitted_by_dot[1]?.substring(0, precision) || '';
        const formated_number = new Decimal(splitted_by_dot[0] + '.' + decimal_part).toFixed();
        return formated_number.toString();
    }
    return null;
};
export const getNumberWithCommas = (number) => {
    if (number != null) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return null;
};

export const snake_to_readable = (str) => {
    return str
        .replaceAll('_', ' ')
        .split(' ')
        .filter((w) => !!w)
        .map((w) => w[0].toUpperCase() + w.substr(1))
        .join(' ');
};
