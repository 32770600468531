import styles from './index.module.scss';

type CapsuleProps = {
    key_string: string;
    value: string;
};

const Capsule = ({ key_string, value }: CapsuleProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.capsule}>
                <div className={styles.key}>{key_string}</div>
                <div className={styles.value}>{value}</div>
            </div>
        </div>
    );
};

export default Capsule;
