import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { refresh } from 'store/client_slice';
import { deleteEmptyValues } from 'utilities/obj_utils';

export interface IResponseInfo {
    data: any;
    has_error: boolean;
}
export interface IInfiniteResponseInfo {
    data: Array<any>;
    total_pages: number;
}

export const useFetcher = (getter, account_id = '', fund_id = '', params = '', fetch_refresh = true) => {
    const [response, setResponse] = React.useState({} as IResponseInfo);
    const [count, setCount] = React.useState(0);
    const { jwt_token, refresh_token } = useSelector((state: RootState) => state.client);
    const dispatch = useDispatch();
    const args = deleteEmptyValues({ token: jwt_token, account_id, fund_id });
    const do_fetch = () => setCount((old) => old + 1);
    React.useEffect(() => {
        const fetch = async ({ getter, args = {}, params = '' }) => {
            const res = await getter({
                ...args,
                query_string: params,
            });
            if (res) {
                switch (res.status) {
                    case 200:
                        const res_json = await res.json();
                        setResponse({ data: res_json, has_error: false });
                        break;
                    case 401:
                        dispatch(refresh({ refresh: refresh_token }));
                        break;
                }
            }
        };
        fetch({ getter, args, params });
        if (fetch_refresh) {
            const interval = setInterval(() => {
                fetch({ getter, args, params });
            }, 120000);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwt_token, params, account_id, fund_id, count]);

    return { response, do_fetch };
};
export const useInfiniteFetcher = (getter, account_id = '', fund_id = '', params = '', page = 1) => {
    const [response, setResponse] = React.useState({ data: [] } as IInfiniteResponseInfo);
    const { jwt_token, refresh_token } = useSelector((state: RootState) => state.client);
    const dispatch = useDispatch();
    const args = deleteEmptyValues({ token: jwt_token, account_id, fund_id });

    const fetch = async ({ getter, args = {}, params = '' }) => {
        const res = await getter({
            ...args,
            query_string: params,
        });
        if (res) {
            switch (res.status) {
                case 200:
                    const res_json = await res.json();
                    setResponse({
                        data: [res_json?.results],
                        total_pages: res_json?.total_pages,
                    });
                    break;
                case 401:
                    dispatch(refresh({ refresh: refresh_token }));
                    break;
            }
        }
    };
    const fetchMore = async ({ getter, args = {}, params = '' }) => {
        const res = await getter({
            ...args,
            query_string: params,
        });
        if (res) {
            switch (res.status) {
                case 200:
                    const res_json = await res.json();
                    setResponse({
                        data: [...response.data, res_json?.results],
                        total_pages: res_json?.total_pages,
                    });
                    break;
                case 401:
                    dispatch(refresh({ refresh: refresh_token }));
                    break;
            }
        }
    };
    React.useEffect(() => {
        if (page === 1) {
            fetch({ getter, args, params });
        } else {
            fetchMore({ getter, args, params });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwt_token, params, account_id, fund_id]);

    return { response };
};

export const usePostFetcher = (poster, body) => {
    const [response, setResponse] = React.useState<any>(null);
    const [start, setStart] = React.useState<boolean>(false);
    const { jwt_token, refresh_token } = useSelector((state: RootState) => state.client);
    const dispatch = useDispatch();
    const args = { token: jwt_token, body };
    const do_fetch = () => setStart(true);
    React.useEffect(() => {
        const fetch = async ({ poster, args = {} }) => {
            const res = await poster({
                ...args,
            });
            if (res) {
                switch (res.status) {
                    case 201:
                        const res_json = await res.json();
                        setResponse({ ...res_json });
                        setStart(false);
                        break;
                    case 401:
                        dispatch(refresh({ refresh: refresh_token }));
                        break;
                    default:
                        setStart(false);
                        break;
                }
            }
        };
        if (start) fetch({ poster, args });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [start, jwt_token]);

    return { response, setResponse, do_fetch };
};

export const useGetById = () => {
    const fetch = async (getter, args) => {
        const res = await getter({
            ...args,
        });
        if (res) {
            switch (res.status) {
                case 200:
                    const res_json = await res.json();
                    return res_json;
            }
        }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return (getter, args) => fetch(getter, args);
};
