import classNames from 'classnames';

import { IResponseInfo } from 'api/fetcher';
import { T } from 'types/common';
import Tooltip from 'components/info_tooltip';

type InvestDivestProps = {
    getfundName: (id: any) => any;
    styles: { readonly [key: string]: string };
    traded_shares: IResponseInfo;
    fund_colors: any;
    t: T;
};

const InvestDivest = ({ getfundName, styles, traded_shares, fund_colors, t }: InvestDivestProps) => {
    return (
        <div>
            <div className={classNames('box')}>
                <div className={classNames('box-header', 'box-header-mobile')}>
                    <div className={classNames('box-header_main', 'box-header_main-mobile')}>
                        {t('overview.shares')}
                    </div>
                    <Tooltip info={t('docs.overview.traded_shares')} />
                </div>
                <div className={styles['trade-box-container']}>
                    {traded_shares &&
                        traded_shares.data &&
                        Object.keys(traded_shares.data).map((key) => {
                            const total_investment = traded_shares.data[key].invested;
                            const total_divestment = traded_shares.data[key].divested;
                            const invest_width = (total_investment / (total_divestment + total_investment)) * 75;
                            const divest_width = (total_divestment / (total_divestment + total_investment)) * 75;

                            return (
                                <div key={key} className={styles['trade-container']}>
                                    <div className={styles['data-container']}>
                                        <div className={styles['trade-header-container']}>
                                            <div
                                                className={styles['fund-color-trade']}
                                                style={{ background: fund_colors?.[getfundName(key)] }}
                                            ></div>
                                            <div className={styles['trade-header']}>{getfundName(key)}</div>
                                        </div>
                                        <div>
                                            <div className={styles['bar-container']}>
                                                <div
                                                    className={classNames(styles.bar, styles['bar-invest'])}
                                                    style={{ width: `${invest_width + 2}%` }}
                                                ></div>
                                                <span className={styles['bar-value']}>{`${t(
                                                    'invested',
                                                )} ${total_investment}`}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className={styles['bar-container']}>
                                                <div
                                                    className={classNames(styles.bar, styles['bar-divest'])}
                                                    style={{ width: `${divest_width + 1}%` }}
                                                ></div>
                                                <span className={styles['bar-value']}>{`${t(
                                                    'divested',
                                                )} ${total_divestment}`}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default InvestDivest;
