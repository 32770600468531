import { useEffect, useState } from 'react';

const useCountdown = (targetDate) => {
    const count_down_date = new Date(targetDate).getTime();

    const [count_down, setCountDown] = useState(count_down_date - new Date().getTime());

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(count_down_date - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [count_down_date]);

    return getReturnValues(count_down);
};

const getReturnValues = (count_down) => {
    // calculate time left
    const hours = Math.floor((count_down % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((count_down % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((count_down % (1000 * 60)) / 1000);

    return [hours, minutes, seconds];
};

export default useCountdown;
