import Decimal from 'decimal.js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { getAvailableAssets } from 'api/available_assets';
import { useFetcher } from 'api/fetcher';
import { RootState } from 'store';
import { getCurrencyImg } from 'utilities/currency';
import { getDecimalFormat } from 'utilities/str_utils';

import AvailableAssetsDesktop from './AvailableAssetsDesktop';
import AvailableAssetsMobile from './AvailableAssetsMobile';

import styles from './index.module.scss';
import Tooltip from 'components/info_tooltip';

const AvailableAssets = () => {
    const { t } = useTranslation();
    const { accounts, active_account_id } = useSelector((state: RootState) => state.client);
    const { response: available_assets } = useFetcher(getAvailableAssets, active_account_id);

    const table_data = available_assets?.data?.assets.map((row) => {
        return {
            [t('available_assets.currency')]: (
                <div className={styles.currency}>
                    <img className={styles['currency-img']} src={getCurrencyImg(row.currency)} alt={row.currency} />
                    <div className={styles['currency-name']}>{row.currency}</div>
                </div>
            ),
            [t('available_assets.volume')]: (
                <div className={styles['currency-value']}>{getDecimalFormat(row.volume, 5)}</div>
            ),
            [t('available_assets.value')]: (
                <div className={styles['currency-value']}>{`₮ ${getDecimalFormat(row.value, 5)}`}</div>
            ),
        };
    });
    const chart_data = available_assets?.data?.assets.map((asset) => ({
        value: new Decimal(asset.value),
        name: asset.currency,
    }));
    const total_value = available_assets?.data?.assets
        .reduce((acc, curr) => new Decimal(acc).plus(new Decimal(curr.value)), 0)
        .toString();
    const available_asset = available_assets?.data?.assets.length > 0;

    if (Array.isArray(accounts) && !accounts?.length) return <Navigate to="/funds" replace />;
    return (
        <>
            {available_assets?.data?.assets && (
                <>
                    <div className={styles.tooltip}>
                        <Tooltip info={t('docs.available_assets')} />
                    </div>
                    <AvailableAssetsDesktop
                        total_value={total_value}
                        chart_data={chart_data}
                        table_data={table_data}
                        available_asset={available_asset}
                        t={t}
                    />
                    <AvailableAssetsMobile
                        total_value={total_value}
                        chart_data={chart_data}
                        table_data={table_data}
                        available_asset={available_asset}
                        t={t}
                    />
                </>
            )}
        </>
    );
};

export default AvailableAssets;
