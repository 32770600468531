import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import LanguagePicker from 'components/i18n';
import ThemeToggle from 'components/theme_toggle';
import UnprotectedRouteWrapper from 'components/unprotected_route_wrapper';

import styles from './index.module.scss';

type SignupLoginWrapperProps = {
    children: JSX.Element;
    is_dark_mode: boolean;
    should_hide_rights?: boolean;
    use_small_logo?: boolean;
};

export default function SignupLoginWrapper({
    children,
    is_dark_mode,
    should_hide_rights,
    use_small_logo = false,
}: SignupLoginWrapperProps) {
    const { t } = useTranslation();

    return (
        <UnprotectedRouteWrapper>
            <>
                <div className={styles.main}>
                    <div className={styles.container}>
                        <div
                            className={classNames(styles.box, {
                                [styles['box--small']]: use_small_logo,
                            })}
                        >
                            <div
                                className={classNames(styles.logo, {
                                    [styles['logo--small']]: use_small_logo,
                                })}
                            >
                                <img
                                    alt="Eveince header"
                                    src={`img/logos/header_logo_${is_dark_mode ? 'dark' : 'light'}.svg`}
                                />
                            </div>
                            {children}
                            {!use_small_logo && (
                                <div className={styles['terms-privacy']}>
                                    <a
                                        className={styles.terms}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://eveince.com/terms-conditions"
                                    >
                                        {t('terms')}
                                    </a>
                                    <a
                                        className={styles.privacy}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://eveince.com/privacy-policy"
                                    >
                                        {t('privacy')}
                                    </a>
                                </div>
                            )}
                        </div>
                        <div
                            className={classNames(styles['theme-lang-container'], {
                                [styles['theme-lang-container--small']]: use_small_logo,
                            })}
                        >
                            <LanguagePicker should_update_query_string={false} />
                            <ThemeToggle />
                        </div>
                    </div>
                </div>
                {!should_hide_rights && (
                    <div className={classNames(styles.rights, { [styles['rights--small']]: use_small_logo })}>
                        © Eveince Tech GmbH 2022
                    </div>
                )}
            </>
        </UnprotectedRouteWrapper>
    );
}
