import classNames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import BottomSidebar from 'components/bottom_side_bar';
import { RootState } from 'store';
import { useClickOutside } from 'utilities/hooks';

import styles from './index.module.scss';

type InputProps = {
    value: string;
    on_change: (e: any) => void;
    input_placeholder: string;
    items: Array<string>;
    mobile_selected?: string | null;
    setMobileSelected?: Dispatch<SetStateAction<string | null>>;
};

const CustomSelectInput = ({
    value,
    on_change,
    input_placeholder,
    items,
    mobile_selected,
    setMobileSelected,
}: InputProps) => {
    const { t } = useTranslation();
    const [is_open, setOpen] = React.useState(false);
    const wrapper_ref = React.useRef<HTMLDivElement>(null);
    useClickOutside(wrapper_ref, () => setOpen(false));
    const { is_dark_mode, is_mobile } = useSelector((state: RootState) => state.platform);

    return (
        <div className={styles.date} ref={wrapper_ref}>
            <div className={styles['input-container']}>
                <div
                    className={styles['fund-input']}
                    onClick={() => setOpen((old) => !old)}
                    style={{ pointerEvents: is_mobile && is_open ? 'none' : 'auto' }}
                >
                    {value ? (
                        <div className={styles.value}>{value}</div>
                    ) : (
                        <div className={styles.placeholder}>{input_placeholder}</div>
                    )}
                    <img
                        alt="down arrow"
                        className={classNames(styles.input_icon, `arrow${is_open ? '-open' : ''}`)}
                        src={`img/icons/arrow-down${is_dark_mode ? '-dark' : ''}.svg`}
                    />
                </div>
                <div
                    className={classNames(styles['items-container'], { [styles['items-container-visible']]: is_open })}
                >
                    <ul className={styles.list}>
                        {items.map((item) => (
                            <li
                                onClick={() => {
                                    on_change(item);
                                    setOpen(false);
                                }}
                                className={styles.item}
                                key={item}
                            >
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
                {is_mobile && (
                    <BottomSidebar is_open={is_open} setOpen={setOpen} height={500}>
                        <div className={styles['items-container-mobile']}>
                            <ul className={styles.list}>
                                {items.map((item) => (
                                    <li
                                        onClick={() => setMobileSelected(item)}
                                        className={classNames(styles.item, {
                                            [styles['item-selected']]: item === mobile_selected,
                                        })}
                                        key={item}
                                    >
                                        {item}
                                    </li>
                                ))}
                            </ul>
                            <button
                                className={classNames(styles.submit, 'button-primary', 'button-48')}
                                onClick={() => {
                                    on_change(mobile_selected);
                                    setOpen(false);
                                }}
                            >
                                {t('select')}
                            </button>
                        </div>
                    </BottomSidebar>
                )}
            </div>
        </div>
    );
};

export default CustomSelectInput;
