import styles from './index.module.scss';

const CustomErrorMessage = ({ msg }: { msg?: string }) => {
    return (
        <div className={styles['form-error-message']}>
            <img alt="error" src="img/icons/Error.svg" />
            {msg}
        </div>
    );
};

export default CustomErrorMessage;
