import { GoogleOAuthProvider } from '@react-oauth/google';
import { useSelector } from 'react-redux';

import GoogleLogin from 'components/google_login';
import { RootState } from 'store';

export default function GoogleOAuth() {
    const { is_loading, is_google_login_in_progress } = useSelector((state: RootState) => state.client);
    const { google_client_params, is_google_loading } = useSelector((state: RootState) => state.platform);

    return (
        <div>
            {!is_google_loading && google_client_params?.client_id && (
                <GoogleOAuthProvider clientId={google_client_params.client_id}>
                    <GoogleLogin
                        is_loading={is_google_login_in_progress}
                        is_normal_login_loading={is_loading}
                        is_signup
                        scopes={google_client_params.scope}
                    />
                </GoogleOAuthProvider>
            )}
        </div>
    );
}
