type ThemeType = 'light' | 'dark';

export const setTheme = (theme: ThemeType = 'light') => {
    if (theme === 'light') {
        document.body.classList.remove('theme--dark');
        document.body.classList.add('theme--light');
    } else {
        document.body.classList.remove('theme--light');
        document.body.classList.add('theme--dark');
    }
};
