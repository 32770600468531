import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';

import SettingsModal from 'components/settings_modal';
import { RootState } from 'store';
import { getAccounts, setActiveAccount, setActiveFund, setLoggedOut } from 'store/client_slice';
import { clearInfo, getMe } from 'store/user_slice';
import { useClickOutside } from 'utilities/hooks';

import styles from './index.module.scss';

const AccountSwitcher = () => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const [is_open, setOpen] = React.useState(false);
    const [accounts_open, setAccountsOpen] = React.useState(false);
    const [is_settings_open, setSettingsOpen] = React.useState(false);
    const { accounts, jwt_token, active_account_id } = useSelector((state: RootState) => state.client);
    const { info } = useSelector((state: RootState) => state.user);
    const [search_params, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    const wrapper_ref = React.useRef<HTMLDivElement>(null);
    useClickOutside(wrapper_ref, () => setOpen(false));

    const is_dispatched = React.useRef(false); // Prevent retriggering API call
    const { t } = useTranslation();

    React.useEffect(() => {
        if (search_params.get('account')) {
            // This makes sharing a special account via direct URLs possible
            dispatch(setActiveAccount(search_params.get('account')));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!is_dispatched.current) {
            dispatch(getMe({ token: jwt_token }));
            dispatch(getAccounts({ token: jwt_token }));
            is_dispatched.current = true;
        }
    }, [dispatch, jwt_token]);

    React.useEffect(() => {
        if (active_account_id) {
            const search_obj = {};
            search_params.forEach((value, key) => (search_obj[key] = value));
            setSearchParams({ ...search_obj, account: active_account_id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active_account_id]);

    if (!localStorage.getItem('client.get_started_form')) {
        if (info?.id && !info.is_filled_get_started_form) return <Navigate to="/get-started" replace />;
    }

    return (
        <div className={styles['account-switcher']} ref={wrapper_ref}>
            {is_settings_open && <SettingsModal handleClose={() => setSettingsOpen((old) => !old)} />}
            <div className={styles['user-section']} onClick={() => setOpen((old_state) => !old_state)}>
                <div className={styles['user-info']}>
                    <div className={styles['user-name']}>{info?.name}</div>
                    <div className={styles['current-account']}>{active_account_id}</div>
                </div>

                <img
                    alt="down arrow"
                    className={`arrow${is_open ? '-open' : ''}`}
                    src={`img/icons/arrow-down${is_dark_mode ? '-dark' : ''}.svg`}
                />
            </div>
            <div className={classNames(styles['account-switcher-content'], { [styles.visible]: is_open })}>
                {/* User accounts section */}
                {accounts?.length > 0 && (
                    <section className={styles['account-section']}>
                        <div
                            className={classNames(styles['account-section-title'], styles.general)}
                            onClick={() => setAccountsOpen((old_state) => !old_state)}
                        >
                            <img
                                alt="active user"
                                src={`img/icons/user-square-active${is_dark_mode ? '-dark' : ''}.svg`}
                            />
                            <span>{t('header.accounts')}</span>
                            <img
                                alt="down arrow"
                                className={classNames(
                                    styles['account-section-arrow'],
                                    `arrow${accounts_open ? '-open' : ''}`,
                                )}
                                src="img/icons/arrow-down-accounts.svg"
                            />
                        </div>
                        <div
                            className={classNames(
                                styles[`account-section-content-${accounts_open ? 'block' : 'none'}`],
                            )}
                        >
                            {accounts
                                .filter((account) => account.id !== active_account_id)
                                .map((account) => {
                                    return (
                                        <p
                                            key={account.id}
                                            className={styles.account}
                                            onClick={() => {
                                                dispatch(setActiveAccount(account.id));
                                                dispatch(setActiveFund(''));
                                                setOpen(false);
                                            }}
                                        >
                                            <span className={classNames(styles.radio)}></span>
                                            <span className={classNames(styles['radio-label'])}>{account.id}</span>
                                        </p>
                                    );
                                })}
                        </div>
                    </section>
                )}
                {/* User settings section */}
                {info?.is_editable_by_user && (
                    <section
                        className={classNames(styles.section, styles.general)}
                        onClick={() => {
                            setSettingsOpen((old) => !old);
                            setOpen(false);
                        }}
                    >
                        <img alt="settings" src="img/icons/setting-2.svg" />
                        <span>{t('header.settings')}</span>
                    </section>
                )}
                {/* Logout section */}
                <section
                    onClick={() => {
                        dispatch(setLoggedOut());
                        dispatch(clearInfo());
                    }}
                    className={classNames(styles.section, styles.general, styles['log-out'])}
                >
                    <img alt="log out" src="img/icons/logout.svg" />
                    <span>{t('header.log_out')}</span>
                </section>
            </div>
        </div>
    );
};

export default AccountSwitcher;
