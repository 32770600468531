import classNames from 'classnames';

import Capsule from 'components/capsule/Capsule';
import PieChart from 'components/pie_chart';
import Table from 'components/table/Table';
import { AvailableAssetsProps } from 'types/available_assets';
import { getDecimalFormat } from 'utilities/str_utils';

import styles from './index.module.scss';

const AvailableAssetsDesktop = ({ table_data, total_value, chart_data, available_asset, t }: AvailableAssetsProps) => {
    return (
        <div className={'desktop-view'}>
            <div className={classNames('box', styles.container)}>
                {available_asset ? (
                    <>
                        <div className={styles['table-container']}>
                            <Table data={table_data} />
                            <Capsule
                                key_string={t('available_assets.asset_value')}
                                value={`₮ ${getDecimalFormat(total_value, 5)}`}
                            />
                        </div>
                        <div className={classNames(styles['chart-container'], styles['chart-desktop-view'])}>
                            <PieChart
                                t={t}
                                data={chart_data}
                                configs={{
                                    chart_center: ['50%', 120],
                                    chart_radius: [90, 110],
                                    legend_position: { left: 'center', top: 270, bottom: 0, right: 'auto' },
                                    title_position: { top: 100, left: '49%', bottom: 'auto', right: 'auto' },
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <div className={styles['no-asset']}>{t('available_assets.no_available')}</div>
                )}
            </div>
        </div>
    );
};

export default AvailableAssetsDesktop;
