import classNames from 'classnames';
import { i18n } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGlobe } from 'react-icons/fa';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

import { ReactComponent as EnSVG } from 'assets/flags/en.svg';
import { ReactComponent as DeSVG } from 'assets/flags/de.svg';
import { ReactComponent as FrSVG } from 'assets/flags/fr.svg';
import { useClickOutside } from 'utilities/hooks';

import styles from './index.module.scss';

const ENABLED_LANGS = ['en', 'fr', 'de'];
const FLAGS = [EnSVG, FrSVG, DeSVG];
const LANGUAGE_FULL_NAMES = ['English', 'Français', 'Deutsch'];

type ChangeLanguage = {
    language: string;
    i18n: i18n;
    options: { should_update_search: boolean; should_update_lang: boolean };
    search_params: globalThis.URLSearchParams;
    setSearchParams: (
        nextInit: URLSearchParamsInit,
        navigateOptions?: {
            replace?: boolean;
            state?: any;
        },
    ) => void;
};

const changeLanguage = ({ language, i18n, options, search_params, setSearchParams }: ChangeLanguage) => {
    if (options.should_update_lang) i18n.changeLanguage(language);
    if (options.should_update_search) {
        const current_search_obj = {};
        search_params.forEach((value, key) => (current_search_obj[key] = value));
        setSearchParams({ ...current_search_obj, lang: language });
    }
};

type LanguagePickerProps = {
    should_update_query_string?: boolean;
};

const LanguagePicker = ({ should_update_query_string = true }: LanguagePickerProps) => {
    const [is_open, setOpen] = useState(false);
    const [search_params, setSearchParams] = useSearchParams();
    const { i18n } = useTranslation();

    const wrapper_ref = useRef<HTMLDivElement>(null);
    useClickOutside(wrapper_ref, () => setOpen(false));

    useEffect(() => {
        const lang_search_param = search_params.get('lang');
        if (ENABLED_LANGS.includes(lang_search_param)) {
            changeLanguage({
                language: lang_search_param,
                i18n,
                search_params,
                setSearchParams,
                options: {
                    should_update_search: false,
                    should_update_lang: lang_search_param !== i18n.language,
                },
            });
        } else {
            changeLanguage({
                language: i18n.language,
                i18n,
                search_params,
                setSearchParams,
                options: { should_update_search: should_update_query_string, should_update_lang: false },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search_params]);

    const supported_langs = ENABLED_LANGS.map((lang, idx) => ({
        lng: lang,
        Component: FLAGS[idx],
        lng_string: LANGUAGE_FULL_NAMES[idx],
    }));
    return (
        <div className={styles.dropdown} ref={wrapper_ref}>
            <div className={styles['button-container']} onClick={() => setOpen((old_state) => !old_state)}>
                <FaGlobe className={styles['dropdown-btn']} size="1.6rem" />
                <div>{supported_langs.find((lang) => i18n.language === lang.lng).lng_string}</div>
            </div>
            <div
                className={classNames(styles['dropdown-content'], {
                    [styles.visible]: is_open,
                })}
            >
                {supported_langs
                    .filter(({ lng }) => i18n.language !== lng)
                    .map(({ lng, Component }) => (
                        <span
                            key={lng}
                            className={classNames(styles.a, {
                                [styles.active]: i18n.language === lng,
                            })}
                            onClick={() => {
                                changeLanguage({
                                    language: lng,
                                    i18n,
                                    search_params,
                                    setSearchParams,
                                    options: {
                                        should_update_search: should_update_query_string,
                                        should_update_lang: true,
                                    },
                                });
                                setOpen(false);
                            }}
                        >
                            <Component width="22px" height="22px" />
                            <span className={styles.span}>{lng.toUpperCase()}</span>
                        </span>
                    ))}
            </div>
        </div>
    );
};

export default LanguagePicker;
