import classNames from 'classnames';

import PieChart from 'components/pie_chart';
import Table from 'components/table/Table';
import { AvailableAssetsProps } from 'types/available_assets';
import { getDecimalFormat } from 'utilities/str_utils';

import styles from './index.module.scss';

const AvailableAssetsMobile = ({ table_data, total_value, chart_data, available_asset, t }: AvailableAssetsProps) => {
    return (
        <>
            <div className={'mobile-view'}>
                {available_asset ? (
                    <>
                        <div className={classNames(styles.container)}>
                            <div className={styles['mobile-capsule']}>
                                <div className={styles['mobile-capsule-key']}>{t('available_assets.asset_value')}</div>
                                <div className={styles['mobile-capsule-value']}>{`₮ ${getDecimalFormat(
                                    total_value,
                                    5,
                                )}`}</div>
                            </div>
                            <div className={styles['table-container']}>
                                <Table data={table_data} />
                            </div>
                            <div className={classNames(styles['chart-container'], styles['chart-mobile-view'])}>
                                <PieChart
                                    t={t}
                                    data={chart_data}
                                    configs={{
                                        chart_center: ['50%', 100],
                                        chart_radius: [80, 95],
                                        legend_position: { left: 'center', top: 210, bottom: 0, right: 'auto' },
                                        title_position: { top: 80, left: '49%', bottom: 'auto', right: 'auto' },
                                    }}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <div className={classNames(styles.container)}>
                        <div className={styles['no-asset']}>{t('available_assets.no_available')}</div>
                    </div>
                )}
            </div>
        </>
    );
};

export default AvailableAssetsMobile;
