import classNames from 'classnames';
import Tooltip from 'components/info_tooltip';

import LineChart from 'components/line_chart';
import PeriodSelect from 'components/period_select';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { CURRENCY_FONT } from 'utilities/constants';

type NavProps = {
    applyNAVPeriod: (period: any) => void;
    nav_points: {
        NAV: {
            data: any;
        };
    };
    styles: { readonly [key: string]: string };
    quote: string;
    t;
};
const NAV = ({ applyNAVPeriod, nav_points, styles, quote, t }: NavProps) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const [full_screen, setFullScreen] = React.useState(false);
    return (
        <div style={{ width: '100%' }}>
            <div className={classNames('box', styles['box-second'], { 'full-screen': full_screen })}>
                <div className={'box-header'}>
                    <div className={'box-header_main'}>
                        {t('fund.historical_investor_nav')}
                        <div className={'box-header_info'}>({CURRENCY_FONT[quote]})</div>
                    </div>
                    <div className={'box-header_icons'}>
                        {full_screen && (
                            <img
                                alt="close menu icon"
                                className={'full-screen-close'}
                                src="img/icons/close_menu_icon.svg"
                                onClick={() => setFullScreen(false)}
                            />
                        )}
                        {!full_screen && (
                            <>
                                <Tooltip info={t('docs.funds.historical_nav')} />
                                <img
                                    alt="full screen icon"
                                    className={'full-screen-icon'}
                                    onClick={() => setFullScreen(!full_screen)}
                                    src={`img/icons/maximize${is_dark_mode ? '-dark' : ''}.svg`}
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className={'period-position'}>
                    <PeriodSelect full_screen={full_screen} set_period={applyNAVPeriod} />
                </div>
                <div style={{ height: full_screen ? '70vw' : '20rem', overflow: 'hidden' }}>
                    <LineChart points={nav_points} decimal={quote === 'BTC' ? 5 : 2} />
                </div>
            </div>
        </div>
    );
};

export default NAV;
