import classNames from 'classnames';
import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styles from './index.module.scss';

type Props = {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    portfo?: string;
    handleClickPlus: () => void;
    handleClickMinus: () => void;
    [x: string]: any;
};

const CustomSlider = ({ value, onChange, portfo, handleClickMinus, handleClickPlus, ...props }: Props) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    return (
        <div className={styles.container}>
            {!props.disabled && (
                <img
                    alt="subtract value"
                    src={`img/icons/minus-square${is_dark_mode ? '-dark' : ''}.svg`}
                    onClick={handleClickMinus}
                />
            )}
            <div
                className={classNames(styles['range-slider'], {
                    [styles[`range-slider-${portfo}`]]: !!portfo,
                    [styles['range-slider--disabled']]: props.disabled,
                })}
            >
                <input type="range" min={0} max={100} step={1} value={value} onChange={onChange} {...props} />
                <div {...(props.disabled ? { 'aria-diabled': true } : {})} style={{ width: `${value}%` }}></div>
            </div>
            {!props.disabled && (
                <img
                    alt="add value"
                    src={`img/icons/add-square${is_dark_mode ? '-dark' : ''}.svg`}
                    onClick={handleClickPlus}
                />
            )}
        </div>
    );
};

export default CustomSlider;
