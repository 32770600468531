import React, { Dispatch, SetStateAction, TouchEvent } from 'react';

import { useClickOutside } from 'utilities/hooks';

import styles from './index.module.scss';

type BottomSidebarProps = {
    children: JSX.Element;
    height: number;
    is_open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    full_screen?: boolean;
};

const BottomSidebar = ({ children, height, is_open, setOpen, full_screen }: BottomSidebarProps) => {
    const wrapper_ref = React.useRef<HTMLDivElement>(null);
    useClickOutside(wrapper_ref, () => setOpen(false));
    const [touch_start, setTouchStart] = React.useState(null);
    const [touch_end, setTouchEnd] = React.useState(null);

    const min_swipe_distance = 30;

    const onTouchStart = (e: TouchEvent<HTMLDivElement>) => {
        setTouchEnd(null);
        if (full_screen) {
            setTouchStart(e.targetTouches[0].clientX);
        } else {
            setTouchStart(e.targetTouches[0].clientY);
        }
    };

    const onTouchMove = (e: TouchEvent<HTMLDivElement>) => {
        if (full_screen) {
            setTouchEnd(e.targetTouches[0].clientX);
        } else {
            setTouchEnd(e.targetTouches[0].clientY);
        }
    };

    const onTouchEnd = () => {
        if (!touch_start || !touch_end) return;
        const distance = touch_start - touch_end;
        let isDownSwipe;
        if (!full_screen) {
            isDownSwipe = distance < -min_swipe_distance;
        } else {
            isDownSwipe = distance > min_swipe_distance;
        }
        if (isDownSwipe) setOpen(false);
    };

    return (
        <div className={styles.container} style={{ maxHeight: is_open ? height : 0 }} ref={wrapper_ref}>
            <div
                style={{ paddingTop: '1.5rem', paddingBottom: '3rem' }}
                onTouchMove={onTouchMove}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
            >
                <div className={styles.separator}></div>
            </div>
            {children}
        </div>
    );
};

export default BottomSidebar;
