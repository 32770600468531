import React from 'react';
import { Popover } from 'react-tiny-popover';
import styles from './index.module.scss';

type PopoverProps = {
    children: JSX.Element;
    popover_content: JSX.Element;
};
interface CustomComponentProps extends React.ComponentPropsWithoutRef<'div'> {
    onMouseEnter(): void;
    onMouseLeave(): void;
}
const CustomComponent = React.forwardRef<HTMLDivElement, CustomComponentProps>((props, ref) => (
    <div ref={ref} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
        {props.children}
    </div>
));
const CustomPopover = ({ children, popover_content }: PopoverProps) => {
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <>
            <Popover
                isOpen={isOpen}
                positions={['top']}
                content={
                    <div
                        onMouseEnter={() => setIsOpen(true)}
                        onMouseLeave={() => setIsOpen(false)}
                        className={styles['tiny-custom-popover']}
                    >
                        {popover_content}
                    </div>
                }
            >
                <CustomComponent onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
                    {children}
                </CustomComponent>
            </Popover>
        </>
    );
};

export default CustomPopover;
