import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { toggleTheme } from 'store/platform_slice';
import { setTheme } from 'utilities/DOM';

import styles from './index.module.scss';

const ThemeToggle = () => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    React.useEffect(() => {
        if (is_dark_mode) {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    }, [is_dark_mode]);

    const handleToggleTheme = () => {
        dispatch(toggleTheme());
    };

    return (
        <div className={styles.container}>
            <input
                type="checkbox"
                className={styles.checkbox}
                id="checkbox"
                readOnly
                checked={is_dark_mode}
                onClick={handleToggleTheme}
            />
            <label htmlFor="checkbox" className={styles.label}>
                <div className={classNames(styles.theme, styles.light)}>
                    <img alt="light mode" className={classNames(styles.icon)} src="img/icons/sun.svg" />
                    <div className={classNames({ [styles['theme-word']]: !is_dark_mode })}>{t('theme.light')}</div>
                </div>
                <div className={classNames(styles.theme, styles.dark)}>
                    <img alt="dark mode" className={classNames(styles.icon)} src="img/icons/moon.svg" />
                    <div className={classNames({ [styles['theme-word']]: is_dark_mode })}>{t('theme.dark')}</div>
                </div>
                <div className={styles.ball}></div>
            </label>
        </div>
    );
};

export default ThemeToggle;
