// import { graphic } from 'echarts';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import { useSelector } from 'react-redux';

import { RootState } from 'store';
import { getISODateForDatePicker } from 'utilities/date_time';
import { getDecimalFormat } from 'utilities/str_utils';

export type Traces = {
    [x: string]: { color?: string; data: Array<{ time: string; value: number }> };
};

export type LineChartProps = {
    points: Traces;
    decimal?: number;
    show_legend?: boolean;
};
const LineChart = ({ points, decimal = 2, show_legend = false }: LineChartProps) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);

    if (Object.keys(points).length < 1) {
        return null;
    } else {
        const chartData = Object.keys(points).map((trace) => {
            const values = points[trace].data?.map((point) => {
                const date = new Date(point.time);
                return [getISODateForDatePicker(date, 'not splitted'), getDecimalFormat(point.value, decimal)];
            });
            const color = points[trace].color;
            // if (trace === 'NAV' || trace === 'NAVPS')
            //     return {
            //         name: trace,
            //         data: values,
            //         type: 'line',
            //         emphasis: {
            //             focus: 'none',
            //         },
            //         lineStyle: { color: points[trace].color },
            //         showSymbol: false,
            //         smooth: true,
            //         areaStyle: {
            //             color: new graphic.LinearGradient(0, 0, 0, 1, [
            //                 {
            //                     offset: 0,
            //                     color: 'rgba(116, 89, 217, 0.31)',
            //                 },
            //                 {
            //                     offset: 1,
            //                     color: 'rgba(255, 255, 255, 0)',
            //                 },
            //             ]),
            //         },
            //     };
            return {
                name: trace,
                data: values,
                type: 'line',
                emphasis: {
                    focus: 'none',
                },
                ...(color ? { lineStyle: { color }, itemStyle: { color } } : {}),
                showSymbol: false,
                smooth: true,
            };
        });
        const option: EChartsOption = {
            grid: {
                top: show_legend ? 50 : 15,
                left: 0,
                right: 5,
                bottom: 10,
                width: 'auto',
                containLabel: true,
            },
            backgroundColor: 'transparent',
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'line',
                    animation: false,
                },
                textStyle: { color: `${is_dark_mode ? '#CED4DA' : '#868E96'}`, fontSize: '1rem' },
                borderColor: `${is_dark_mode ? '#111018' : '#F7F9FB'}`,
                backgroundColor: `${is_dark_mode ? '#111018' : '#F7F9FB'}`,
            },
            xAxis: {
                type: 'time',
                splitLine: {
                    show: false,
                },
                boundaryGap: false,
                axisPointer: { label: { show: false } },
                axisTick: { show: false },
                axisLine: { show: false },
                axisLabel: { margin: 20 },
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    lineStyle: {
                        color: `${is_dark_mode ? '#495057' : '#E9ECEF'}`,
                        type: 'dashed',
                    },
                },
                axisPointer: { label: { show: false } },
                scale: true,
            },
            dataZoom: [{ type: 'inside' }],
            series: chartData,
            color: ['#B57BFF', '#FEC37B', '#FF7B7B', '#7B9EFD', '#7BFD9F'],
            ...(show_legend
                ? {
                      legend: {
                          show: show_legend,
                          padding: [0, 0, 250, 0],
                          icon: 'circle',
                          width: '80%',
                          itemWidth: 8,
                          textStyle: { fontSize: 10 },
                          data: Object.keys(points).map((trace) => ({
                              name: trace,
                              textStyle: { color: points[trace].color },
                          })),
                      },
                  }
                : {}),
        };

        return (
            <ReactEcharts
                theme={is_dark_mode ? 'dark' : 'light'}
                option={option}
                notMerge={true}
                style={{ height: '100%', width: '100%' }}
            />
        );
    }
};

export default LineChart;
