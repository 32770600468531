import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { IResponseInfo } from 'api/fetcher';
import CustomInput from 'components/custom_input';
import CustomRangePicker from 'components/custom_range_picker';
import { getISODateForDatePicker } from 'utilities/date_time';
import { COMPARISON_DEFAULT_PERIOD } from 'utilities/constants';
import { Assets } from 'types/simulation';
import { indicesData, T } from 'types/common';

type Filter = { initial: number; range: Array<Date> };
type FilterProps = {
    styles: { readonly [key: string]: string };
    funds: IResponseInfo;
    indices: { data: indicesData; has_error: boolean };
    filters: Filter;
    assets: Assets;
    t: T;
};

const Filters = ({ styles, funds, indices, filters, assets, t }: FilterProps) => {
    const [valid_start_time, setValidStartTime] = useState(new Date());
    const valid_funds = funds?.data?.filter((fund) => fund.quote === 'USDT');
    const [need_login, setNeedLogin] = useState(false);
    const handleChange = () => {
        setNeedLogin(true);
        setTimeout(() => {
            setNeedLogin(false);
        }, 20000);
    };
    const all_assets = valid_funds &&
        indices.data && [
            ...valid_funds?.map(({ id, first_navps_time }) => ({ id, time: first_navps_time })),
            ...Object.keys(indices.data).map((index) => ({ id: index, time: indices?.data?.[index].start_time })),
        ];
    const selected_assets = Object.keys(assets);
    useEffect(() => {
        const all_selected_dates = all_assets
            ?.filter(({ id }) => selected_assets.includes(id))
            .map((asset) => asset.time);
        const valid_time =
            all_selected_dates?.length > 0
                ? new Date(Math.max(...all_selected_dates.map((date) => new Date(date).getTime())))
                : COMPARISON_DEFAULT_PERIOD;
        setValidStartTime(valid_time);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assets]);

    return (
        <div className="box">
            <div className={styles.header}>{t('simulation.filters.period')}</div>
            <div className={styles['available-period-container']}>
                <div>{t('simulation.filters.available_period')}</div>
                <div className={styles.dates}>
                    <div className={styles['dates-item']}>
                        <div>{t('start_date')}</div>
                        <div>{getISODateForDatePicker(valid_start_time)}</div>
                    </div>
                    <div className={styles['dates-item']}>
                        <div>{t('end_date')}</div>
                        <div>{getISODateForDatePicker(new Date()) + ` (${t('simulation.filters.today')})`}</div>
                    </div>
                </div>
            </div>
            <CustomRangePicker dropdown min_date={valid_start_time} range={filters.range} setRange={handleChange} />
            <div className={styles.header}>{t('simulation.filters.initial')}</div>
            <CustomInput
                icon={<img width="16px" alt="tether" src="img/icons/dollar-circle.svg" />}
                placeholder="Enter Initial Value"
                type="number"
                onChange={handleChange}
                value={filters.initial}
            />
            <div className={styles['button-container']}>
                <button
                    className={classNames(styles.apply, 'button-primary', 'button-48')}
                    onClick={handleChange}
                    disabled
                >
                    {t('apply')}
                </button>
            </div>
            <div className={styles['need-login']}>
                {need_login ? (
                    <>
                        <img alt="error" src="img/icons/Error.svg" />
                        {t('simulation.config_error')}
                    </>
                ) : (
                    ' '
                )}
            </div>
        </div>
    );
};

export default Filters;
