import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getHistoricalNAV } from 'api/accounts';
import { useFetcher } from 'api/fetcher';
import { getFund, getFundAssets, getFundHistoricalNAVPS, getFundMetrics, getFundsList } from 'api/funds';
import { getHistoricalIndices } from 'api/indices';
import CircularProgressBar from 'components/circular_progress_bar';
import CustomSelectInput from 'components/custom_select_input';
import { RootState } from 'store';
import { getISODateForDatePicker, getZeroTime } from 'utilities/date_time';
import { getDecimalFormat } from 'utilities/str_utils';
import { strQueryParams } from 'utilities/obj_utils';

import styles from './index.module.scss';
import NAV from './nav';
import NavpsComparison from './navps_comparison';
import { CURRENCY_FONT } from 'utilities/constants';
import Decimal from 'decimal.js';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/info_tooltip';

const TODAY = new Date();
const READABLE_SYMBOLS = {
    SP500: 'S&P 500',
    CryptocurrencyTradersIndex: 'Cryptocurrency Traders Index',
    BinanceBTCUSDT: 'Binance BTCUSDT',
};
const YEAR_AGO = new Date(new Date().setDate(TODAY.getDate() - 365));
const Portfolio = () => {
    const { t } = useTranslation();
    const { is_mobile } = useSelector((state: RootState) => state.platform);
    const { active_account_id } = useSelector((state: RootState) => state.client);
    const { response: funds } = useFetcher(getFundsList, null, null, null, false);
    const fund_items = funds?.data?.map((obj) => obj.name || obj.id);
    const [mobile_selected_fund, setMobileSelectedFund] = React.useState(null);
    const [selected_fund, setSelectedFund] = React.useState(null);
    const [selected_fund_name, setSelectedFundName] = React.useState(null);
    const { response: fund_info } = useFetcher(getFund, null, selected_fund);
    const fund_quote = fund_info?.data?.quote;
    const fund_indices = fund_info?.data?.indices;
    const { response: fund_asset } = useFetcher(getFundAssets, active_account_id);
    const [nav_filter_params, setNAVFilterParams] = React.useState({
        start_time: getISODateForDatePicker(YEAR_AGO),
        end_time: getISODateForDatePicker(TODAY),
    });
    const [navps_filter_params, setNAVPSFilterParams] = React.useState({
        start_time: getISODateForDatePicker(YEAR_AGO),
        end_time: getISODateForDatePicker(TODAY),
    });
    const [indices_filter_params] = React.useState({
        start_time: getISODateForDatePicker(getZeroTime(YEAR_AGO), 'not splitted'),
        end_time: getISODateForDatePicker(getZeroTime(TODAY), 'not splitted'),
    });
    const [nav_query_params, setNAVQueryParams] = React.useState(strQueryParams(nav_filter_params));
    const [navps_query_params, setNAVPSQueryParams] = React.useState(strQueryParams(navps_filter_params));
    const [indices_query_params, setIndicesQueryParams] = React.useState(strQueryParams({ ...indices_filter_params }));
    React.useEffect(() => {
        funds?.data && setSelectedFund(funds.data[0]?.id);
        funds?.data && setSelectedFundName(funds.data[0]?.name);
    }, [funds]);
    React.useEffect(() => {
        fund_info?.data &&
            setIndicesQueryParams(strQueryParams({ ...indices_filter_params, symbols: fund_info.data.indices }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fund_info]);
    const { response: historical_nav } = useFetcher(getHistoricalNAV, active_account_id, null, nav_query_params);
    const { response: historical_navps } = useFetcher(getFundHistoricalNAVPS, null, selected_fund, navps_query_params);
    const { response: fund_metrics } = useFetcher(getFundMetrics, null, selected_fund, navps_query_params);
    const { response: indices } = useFetcher(getHistoricalIndices, null, null, indices_query_params);
    const indices_points =
        indices?.data &&
        Object.keys(indices.data).reduce((acc, curr) => {
            acc[READABLE_SYMBOLS[curr]] = indices.data[curr].data;
            return acc;
        }, {});
    const nav_points = {
        NAV: { data: historical_nav?.data?.per_fund[selected_fund] || null },
    };
    const raw_points = {
        NAVPS: {
            data: historical_navps?.data?.map(({ time, value }) => ({
                time,
                value: new Decimal(value),
            })),
        },
    };
    const navps_points = { NAVPS: historical_navps?.data, ...indices_points };
    const navps_first_point = historical_navps?.data && historical_navps?.data[0];
    const chart_data = Object.keys(navps_points).reduce((acc, curr) => {
        const valid_data = navps_points[curr]?.filter(
            (point) => new Date(point.time).getTime() >= new Date(navps_first_point?.time).getTime(),
        );
        const first_point = valid_data && valid_data[0];
        acc[curr] = {
            data: valid_data?.map(({ time, value }) => ({
                time,
                value: new Decimal(value).dividedBy(new Decimal(first_point?.value)),
            })),
        };
        return acc;
    }, {});
    const metrics = fund_metrics?.data;
    const investor_share_count = fund_asset?.data?.find((el) => el.fund.id === selected_fund)?.share_count || 0;
    const investor_nav_for_fund = fund_asset?.data?.find((el) => el.fund.id === selected_fund)?.nav?.[fund_quote] || 0;
    const investor_navps_for_fund = fund_info?.data?.last_navps.value;
    const applyNAVPeriod = (period) => {
        const tmp = {
            start_time: getISODateForDatePicker(period.start_time),
            end_time: getISODateForDatePicker(period.end_time),
        };
        setNAVFilterParams(tmp);
        setNAVQueryParams(strQueryParams(tmp));
    };
    const applyNAVPSPeriod = (period) => {
        const tmp = {
            start_time: getISODateForDatePicker(period.start_time),
            end_time: getISODateForDatePicker(period.end_time),
        };
        const indicesTmp = {
            start_time: getISODateForDatePicker(getZeroTime(period.start_time), 'not splitted'),
            end_time: getISODateForDatePicker(getZeroTime(period.end_time), 'not splitted'),
        };
        setNAVPSFilterParams(tmp);
        setNAVPSQueryParams(strQueryParams(tmp));
        setIndicesQueryParams(strQueryParams({ ...indicesTmp, symbols: fund_indices }));
    };
    const handleChangeFund = (item) => {
        const tmp = funds?.data?.find((el) => el.name === item || el.id === item);
        setSelectedFund(tmp.id);
        setSelectedFundName(tmp.name);
    };
    const fund_navps = getDecimalFormat(investor_navps_for_fund, fund_quote === 'BTC' ? 5 : 2);
    const hide_values = investor_nav_for_fund === 0 && investor_share_count === 0;
    return (
        <>
            {fund_items && (
                <div className={styles.select_fund}>
                    <div className={styles.label}>{t('fund.select')}</div>
                    <CustomSelectInput
                        value={selected_fund_name}
                        on_change={(item) => handleChangeFund(item)}
                        items={fund_items}
                        input_placeholder={t('fund.select')}
                        mobile_selected={mobile_selected_fund}
                        setMobileSelected={setMobileSelectedFund}
                    />
                </div>
            )}
            {selected_fund && (
                <div className={styles.container}>
                    {!hide_values && (
                        <div className={classNames({ box: !is_mobile }, styles['box-first'])}>
                            <div className={styles.info_box}>
                                <p className={styles['info_box-title']}>
                                    {t('fund.investor_share_count')}
                                    <Tooltip info={t('docs.funds.share_count')} is_small />
                                </p>
                                <p className={styles['info_box-value']}>{investor_share_count}</p>
                                <img
                                    alt="refresh bitcoin"
                                    src={`img/icons/share_count_icon.svg`}
                                    className={styles['info_box-image']}
                                />
                            </div>
                            <div className={styles.info_box}>
                                <p className={styles['info_box-title']}>
                                    NAVPS
                                    <Tooltip info={t('docs.funds.navps')} is_small />
                                </p>
                                <p className={styles['info_box-value']}>
                                    {CURRENCY_FONT[fund_quote]} {fund_navps}
                                </p>
                                <img
                                    alt="refresh bitcoin"
                                    src={`img/icons/navps_icon.svg`}
                                    className={styles['info_box-image']}
                                />
                            </div>
                            <div className={styles.info_box}>
                                <p className={styles['info_box-title']}>
                                    {t('fund.investor_nav')}
                                    <Tooltip info={t('docs.funds.nav')} is_small />
                                </p>
                                <p className={styles['info_box-value']}>
                                    {CURRENCY_FONT[fund_quote]}{' '}
                                    {getDecimalFormat(investor_nav_for_fund, fund_quote === 'BTC' ? 5 : 2)}
                                </p>
                                <img
                                    alt="refresh bitcoin"
                                    src={`img/icons/nav_icon.svg`}
                                    className={styles['info_box-image']}
                                />
                            </div>
                        </div>
                    )}
                    {historical_nav?.data?.per_fund[selected_fund] && (
                        <NAV
                            applyNAVPeriod={applyNAVPeriod}
                            nav_points={nav_points}
                            styles={styles}
                            quote={fund_quote}
                            t={t}
                        />
                    )}
                    <div className={classNames({ box: !is_mobile }, styles['pl-section-container'])}>
                        <div className={classNames('box-header', 'box-header--no-border')}>
                            <div className={'box-header_main'}>{t('fund.performance_header')}</div>
                        </div>
                        <div className={styles['pl-section']}>
                            <div className={classNames(styles['pl-box'])}>
                                <div>
                                    <div className={styles['pl-header']}>
                                        {t('fund.performance.total')}
                                        <Tooltip info={t('docs.funds.performance.total')} is_small />
                                    </div>
                                    <div
                                        className={classNames(styles.pl, {
                                            [styles['pl-profit']]: fund_info?.data?.pl.total > 0,
                                        })}
                                    >
                                        {getDecimalFormat(fund_info?.data?.pl.total, 3)} %
                                    </div>
                                </div>
                                <div className={styles['pl-value-container']}>
                                    <CircularProgressBar
                                        percentage={fund_info?.data?.pl.total}
                                        is_rise={fund_info?.data?.pl.total > 0}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles['pl-section']}>
                            <div className={classNames(styles['pl-box'])}>
                                <div>
                                    <div className={styles['pl-header']}>
                                        {t('fund.performance.yearly')}
                                        <Tooltip info={t('docs.funds.performance.yearly')} is_small />
                                    </div>
                                    <div
                                        className={classNames(styles.pl, {
                                            [styles['pl-profit']]: fund_info?.data?.pl.days365 > 0,
                                        })}
                                    >
                                        {getDecimalFormat(fund_info?.data?.pl.days365, 3)} %
                                    </div>
                                </div>
                                <div className={styles['pl-value-container']}>
                                    <CircularProgressBar
                                        percentage={fund_info?.data?.pl.days365}
                                        is_rise={fund_info?.data?.pl.days365 > 0}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles['pl-section']}>
                            <div className={classNames(styles['pl-box'])}>
                                <div>
                                    <div className={styles['pl-header']}>
                                        {t('fund.performance.monthly')}
                                        <Tooltip info={t('docs.funds.performance.monthly')} is_small />
                                    </div>
                                    <div
                                        className={classNames(styles.pl, {
                                            [styles['pl-profit']]: fund_info?.data?.pl.days30 > 0,
                                        })}
                                    >
                                        {getDecimalFormat(fund_info?.data?.pl.days30, 3)} %
                                    </div>
                                </div>
                                <div className={styles['pl-value-container']}>
                                    <CircularProgressBar
                                        percentage={fund_info?.data?.pl.days30}
                                        is_rise={fund_info?.data?.pl.days30 > 0}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles['pl-section']}>
                            <div className={classNames(styles['pl-box'])}>
                                <div>
                                    <div className={styles['pl-header']}>
                                        {t('fund.performance.weekly')}
                                        <Tooltip info={t('docs.funds.performance.weekly')} is_small />
                                    </div>
                                    <div
                                        className={classNames(styles.pl, {
                                            [styles['pl-profit']]: fund_info?.data?.pl.days7 > 0,
                                        })}
                                    >
                                        {getDecimalFormat(fund_info?.data?.pl.days7, 3)} %
                                    </div>
                                </div>
                                <div className={styles['pl-value-container']}>
                                    <CircularProgressBar
                                        percentage={fund_info?.data?.pl.days7}
                                        is_rise={fund_info?.data?.pl.days7 > 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {chart_data && metrics && raw_points && (
                        <NavpsComparison
                            {...(hide_values ? { navps: fund_navps } : {})}
                            applyNAVPSPeriod={applyNAVPSPeriod}
                            chart_data={chart_data}
                            navps_points={raw_points}
                            metrics={metrics}
                            styles={styles}
                            t={t}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default Portfolio;
