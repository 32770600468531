import { useGoogleLogin } from '@react-oauth/google';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import LoaderSpinner from 'components/loader_spinner';
import { RootState } from 'store';
import { postGoogleLogin } from 'store/client_slice';

import styles from './index.module.scss';

type GoogleLoginProps = {
    is_loading: boolean;
    is_normal_login_loading: boolean;
    is_signup: boolean;
    setGoogleLoginClicked?: React.Dispatch<React.SetStateAction<boolean>>;
    scopes: Array<string>;
};

export default function GoogleLogin({
    is_loading,
    is_normal_login_loading,
    is_signup,
    setGoogleLoginClicked,
    scopes,
}: GoogleLoginProps) {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const [search_params, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const is_dispatched = useRef(false);

    useEffect(() => {
        const code = search_params.get('code');
        if (code && !is_dispatched.current) {
            const authorization_code = code;
            const { location } = window;
            const redirect_uri = location.protocol + '//' + location.host + location.pathname;
            dispatch(postGoogleLogin({ authorization_code, redirect_uri }));
            setSearchParams({}, { replace: true });
            is_dispatched.current = true;
        }
    }, [dispatch, search_params, setSearchParams]);

    const login = useGoogleLogin({
        flow: 'auth-code',
        redirect_uri: window.location.href,
        scope: scopes.join(' '),
        ux_mode: 'redirect',
    });

    let google_login_btn_content: JSX.Element | string;
    if (is_loading) {
        google_login_btn_content = (
            <span className={styles['btn-content']}>
                <LoaderSpinner is_dark_mode={is_dark_mode} />
            </span>
        );
    } else {
        google_login_btn_content = is_signup ? t('signup.google') : t('login.google');
    }

    return (
        <button
            className={styles.login}
            disabled={is_loading || is_normal_login_loading}
            onClick={() => {
                setGoogleLoginClicked?.(true);
                login();
            }}
        >
            <img alt="Google login" src="img/icons/google-login.svg" />
            <span>{google_login_btn_content}</span>
        </button>
    );
}
