import TermsPolicy from 'components/terms_policy';
import { T } from 'types/common';

import styles from './index.module.scss';

type SignupIntroProps = {
    is_dark_mode: boolean;
    show_term_privacy?: boolean;
    t: T;
};

export default function SignupIntro({ is_dark_mode, t, show_term_privacy = true }: SignupIntroProps) {
    return (
        <div className={styles.container}>
            <div>
                <img
                    className={styles.logo}
                    alt="Eveince header"
                    src={`img/logos/header_logo_${is_dark_mode ? 'dark' : 'light'}.svg`}
                />
            </div>
            <p className={styles.title}>{t('signup.title')}:</p>
            <ul className={styles.list}>
                <li>{t('signup.item_1')}</li>
                <li>{t('signup.item_2')}</li>
                <li>{t('signup.item_3')}</li>
            </ul>
            {show_term_privacy && <TermsPolicy t={t} />}
        </div>
    );
}
