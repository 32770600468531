import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import LineChart from 'components/line_chart';
import { Traces } from 'components/line_chart/LineChart';
import PeriodSelect from 'components/period_select';
import { RootState } from 'store';
import { T } from 'types/common';
import { snake_to_readable } from 'utilities/str_utils';
import Tooltip from 'components/info_tooltip';
import ToggleButton from 'components/toggle_button';

type Metrics = {
    [x: string]: string | null;
};
type NavpsComparisonProps = {
    applyNAVPSPeriod: (period: any) => void;
    chart_data: Traces;
    metrics: Metrics;
    styles: { readonly [key: string]: string };
    navps?: string;
    navps_points: Traces;
    t: T;
};

const FUND_METRICS = ['calmar_ratio', 'max_drawdown', 'sharpe_ratio', 'sortino_ratio'];

const NavpsComparison = ({
    applyNAVPSPeriod,
    styles,
    metrics,
    chart_data,
    navps,
    navps_points,
    t,
}: NavpsComparisonProps) => {
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const [chart_state, setChartState] = React.useState('relative');
    const [full_screen, setFullScreen] = React.useState(false);
    const fund_metrics = FUND_METRICS.reduce((acc, curr) => {
        if (metrics[curr]) acc[curr] = metrics[curr];
        return acc;
    }, {});

    return (
        <>
            <div style={{ width: '100%' }}>
                <div className={classNames('box', styles['box-third'], { 'full-screen': full_screen })}>
                    <div className={'box-header'}>
                        <div className={'box-header_main'}>{t('fund.historical_navps')}</div>
                        <div className={'box-header_icons'}>
                            <Tooltip info={t('docs.funds.historical_navps')} />
                            {full_screen && (
                                <img
                                    alt="close menu icon"
                                    className={'full-screen-close'}
                                    src="img/icons/close_menu_icon.svg"
                                    onClick={() => setFullScreen(false)}
                                />
                            )}
                            {!full_screen && (
                                <img
                                    alt="full screen icon"
                                    className={'full-screen-icon'}
                                    onClick={() => setFullScreen(!full_screen)}
                                    src={`img/icons/maximize${is_dark_mode ? '-dark' : ''}.svg`}
                                />
                            )}
                        </div>
                    </div>
                    <div className={'period-position'}>
                        <ToggleButton
                            left="relative"
                            right="raw"
                            left_label={t('fund.relative')}
                            right_label={t('fund.raw')}
                            state={chart_state}
                            setState={setChartState}
                        />
                        <PeriodSelect full_screen={full_screen} set_period={applyNAVPSPeriod} />
                    </div>
                    <div
                        className={classNames(styles['chart-metrics-container'], {
                            [styles['chart-metrics-container-fullscreen']]: full_screen,
                        })}
                    >
                        <div className={styles['chart-container']}>
                            <LineChart
                                decimal={chart_state === 'relative' ? 2 : 5}
                                points={chart_state === 'relative' ? chart_data : navps_points}
                            />
                        </div>
                        <div className={styles['metrics-container']}>
                            {fund_metrics &&
                                Object.keys(fund_metrics).map((key) => (
                                    <div className={styles['metric-box']} key={key}>
                                        <span className={styles['metric-name']}>{snake_to_readable(key)}</span>
                                        <span className={styles['metric-value']}>{fund_metrics[key] || '-'}</span>
                                    </div>
                                ))}
                            {navps && (
                                <div className={styles['metric-box']}>
                                    <span className={styles['metric-name']}>NAVPS</span>
                                    <span className={styles['metric-value']}>{navps}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NavpsComparison;
