import classNames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import { useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { resendConfirmationEmail } from 'api/auth';
import LoaderSpinner from 'components/loader_spinner';
import UnprotectedRouteWrapper from 'components/unprotected_route_wrapper';
import Timer from 'components/timer';
import { RootState } from 'store';
import { RECAPTCHA_SITE_KEY } from 'utilities/constants';
import { setTheme } from 'utilities/DOM';

import styles from './index.module.scss';

function EmailSpan({ email }) {
    return <span>{email}</span>;
}

export default function FinishSignup() {
    const timer = localStorage.getItem('platform.timer');
    const [is_timer_running, setTimerRunning] = useState<boolean>(!!timer);
    const [is_loading, setLoading] = useState<boolean>(false);
    const [error_message, setErrorMessage] = useState<string>('');
    const [should_show_alert, setShowAlert] = useState<boolean>(false);
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const { t, i18n } = useTranslation();

    const captcha_ref = useRef();

    useEffect(() => {
        if (is_dark_mode) {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    }, [is_dark_mode]);

    const signup_info_storage = localStorage.getItem('client.signup_info');
    const signup_info = signup_info_storage ? JSON.parse(signup_info_storage) : null;

    const handleResend = async () => {
        setTimerRunning(true);
        setLoading(true);
        setErrorMessage('');

        if (captcha_ref?.current) {
            const recaptcha = await (captcha_ref.current as any).executeAsync();
            (captcha_ref.current as any).reset();

            if (recaptcha) {
                const body = { email: signup_info?.email, recaptcha, callback_params: {} };

                try {
                    const response = await resendConfirmationEmail(body);
                    if (response.ok) {
                        // :))
                    } else setErrorMessage(response.statusText);
                } catch (error) {
                    setErrorMessage(error);
                } finally {
                    setLoading(false);
                    setErrorMessage('');
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 5000);
                }
            } else {
                setLoading(false);
                setErrorMessage(t('require_recaptcha'));
            }
        }
    };

    const captcha_language = i18n.language;
    const captcha_config = {
        theme: is_dark_mode ? 'dark' : 'light',
        hl: captcha_language,
        size: 'invisible',
    };

    const total_time = timer ? parseInt(timer) : 110;

    let submit_btn_content: JSX.Element | string;
    if (is_loading) {
        submit_btn_content = (
            <span className={styles['btn-content']}>
                <LoaderSpinner is_dark_mode={true} />
            </span>
        );
    } else {
        submit_btn_content = t('finish_signup.resend_email');
    }

    return (
        <UnprotectedRouteWrapper>
            <div className={styles.container}>
                <ReCAPTCHA
                    key={`${captcha_language}-${is_dark_mode ? 'dark' : 'light'}`}
                    ref={captcha_ref}
                    sitekey={RECAPTCHA_SITE_KEY}
                    {...captcha_config}
                />
                <div className={styles.welcome}>
                    {t('finish_signup.welcome')} {signup_info?.name}!
                </div>
                <div className={styles.verification}>
                    <p>{t('finish_signup.verify_email_intro')}</p>
                    <p>
                        <Trans
                            i18nKey="finish_signup.verify_email"
                            t={t}
                            components={[<EmailSpan email={signup_info?.email} />]}
                        />
                    </p>
                </div>
                <div className={styles.options}>
                    <div className={styles.resend}>
                        <button
                            className={classNames(styles.submit)}
                            onClick={handleResend}
                            disabled={is_timer_running}
                        >
                            {submit_btn_content}
                        </button>
                    </div>
                    <p className={styles.change}>
                        <Link to="/sign-up">
                            <span>{t('finish_signup.wrong_email')}</span>
                        </Link>
                    </p>
                </div>
                {is_timer_running && (
                    <p className={styles.timer}>
                        {t('in')} <Timer onStop={() => setTimerRunning(false)} t={t} total_time={total_time} />
                    </p>
                )}
                <div
                    className={classNames(styles.alert, {
                        [styles['alert--err']]: error_message,
                    })}
                    style={{ visibility: should_show_alert ? 'visible' : 'hidden' }}
                >
                    {error_message || t('finish_signup.email_sent')}
                </div>
            </div>
        </UnprotectedRouteWrapper>
    );
}
