import { combineReducers, configureStore } from '@reduxjs/toolkit';

import saga from './saga';
import rootSaga from './saga/root_saga';

import clientReducer from './client_slice';
import platformReducer from './platform_slice';
import userReducer from './user_slice';

export const store = configureStore({
    reducer: combineReducers({
        client: clientReducer,
        platform: platformReducer,
        user: userReducer,
    }),
    middleware: [saga],
});

saga.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
