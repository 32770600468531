import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from 'store';

import LanguagePicker from 'components/i18n';
import ThemeToggle from 'components/theme_toggle';
import { NavigationProps } from 'types/layout';

import styles from './index.module.scss';
import { ALLOWED_PAGES } from 'utilities/constants';
import CustomPopover from 'components/popover';
import { useState } from 'react';

interface IActive {
    name: string;
    icon: string;
    to: string;
}

const items: Array<IActive> = [
    { name: 'overview', icon: 'overview', to: '/overview' },
    { name: 'funds', icon: 'fund', to: '/funds' },
    {
        name: 'transactions',
        icon: 'share-transaction',
        to: '/transactions',
    },
    { name: 'wallet', icon: 'wallet', to: '/wallet' },
    { name: 'comparison', icon: 'comparison', to: '/comparison' },
    { name: 'simulation', icon: 'simulation', to: '/simulation' },
    { name: 'pipeline', icon: 'pipeline', to: '/pipeline' },
];
const Sidebar = ({ current_page, accounts, query_string }: NavigationProps) => {
    const [is_open, setFaqModalOpen] = useState(false);
    const { is_dark_mode } = useSelector((state: RootState) => state.platform);
    const { t } = useTranslation();
    return (
        <section className={styles['sidebar-container']}>
            {is_open && (
                <>
                    <div onClick={() => setFaqModalOpen(false)} className={'modal'}></div>
                    <div className={classNames('modal-main', styles.modal)}>
                        <img
                            onClick={() => setFaqModalOpen(false)}
                            src="img/icons/close_menu_icon.svg"
                            alt="close modal"
                            className={styles.close}
                        />
                        <h2 className={styles.title}>FAQ</h2>
                        <ul>
                            <li>
                                <div className={styles.question}>{t('faq.sources.question')}</div>
                                <div>{t('faq.sources.answer')}</div>
                            </li>
                            <li>
                                <div className={styles.question}>{t('faq.time_axis.question')}</div>
                                <div>{t('faq.time_axis.answer')}</div>
                            </li>
                            <li>
                                <div className={styles.question}>{t('faq.etfs.question')}</div>
                                <div>{t('faq.etfs.answer')}</div>
                            </li>

                            <li>
                                <div className={styles.question}>{t('faq.not_appeared_indices.question')}</div>
                                <div>{t('faq.not_appeared_indices.answer')}</div>
                            </li>

                            <li>
                                <div className={styles.question}>{t('faq.comparison_holidays.question')}</div>
                                <div>{t('faq.comparison_holidays.answer')}</div>
                            </li>

                            <li>
                                <div className={styles.question}>{t('faq.simulation_holidays.question')}</div>
                                <div>{t('faq.simulation_holidays.answer')}</div>
                            </li>

                            <li>
                                <div className={styles.question}>{t('faq.funds_holidays.question')}</div>
                                <div>{t('faq.funds_holidays.answer')}</div>
                            </li>
                        </ul>
                    </div>
                </>
            )}
            <ul className={styles['list']}>
                {items
                    .filter((item) => {
                        if (Array.isArray(accounts) && accounts?.length) return true;
                        else return ALLOWED_PAGES.includes(item.name);
                    })
                    .map((item: IActive) => {
                        return (
                            <NavLink
                                to={`${item.to}${query_string}`}
                                className={classNames(styles['link'])}
                                key={item.name}
                            >
                                <li
                                    className={classNames(styles['item'], {
                                        [styles.active]: item.to === current_page,
                                    })}
                                >
                                    <div className={styles['item-content']}>
                                        <img
                                            alt="item option"
                                            src={`img/icons/${item.icon}-icon${
                                                item.to === current_page
                                                    ? is_dark_mode
                                                        ? '-dark-active'
                                                        : '-light-active'
                                                    : ''
                                            }.svg`}
                                            className={styles['item-icon']}
                                        />
                                        {t(`sidebar.${item.name}`)}
                                    </div>
                                </li>
                            </NavLink>
                        );
                    })}
            </ul>
            <div className={styles['theme-toggle-position']}>
                <div className={styles['top-buttons']}>
                    <LanguagePicker />
                    <div className={styles.external_link}>
                        <CustomPopover popover_content={<>FAQ</>}>
                            <img
                                onClick={() => setFaqModalOpen(true)}
                                alt="email"
                                className={styles['link-img']}
                                src={`/img/icons/faq${is_dark_mode ? '' : '-light'}.png`}
                            />
                        </CustomPopover>
                        <CustomPopover popover_content={<>Discord</>}>
                            <a href="https://discord.gg/94tMue3taR" rel="noopener noreferrer" target="_blank">
                                <img
                                    alt="discord"
                                    className={styles['link-img']}
                                    src={`/img/icons/discord${is_dark_mode ? '' : '-light'}.svg`}
                                />
                            </a>
                        </CustomPopover>
                        <CustomPopover popover_content={<>Email</>}>
                            <a href="mailto:hi@eveince.com" rel="noopener noreferrer" target="_blank">
                                <img
                                    alt="email"
                                    className={styles['link-img']}
                                    src={`/img/icons/sms${is_dark_mode ? '' : '-light'}.svg`}
                                />
                            </a>
                        </CustomPopover>
                    </div>
                </div>
                <ThemeToggle />
            </div>
        </section>
    );
};

export default Sidebar;
