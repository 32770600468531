import classNames from 'classnames';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from 'store';
import { getISODateForDatePicker } from 'utilities/date_time';

import 'react-calendar/dist/Calendar.css';
import styles from './index.module.scss';
import { useRef, useState } from 'react';
import { useClickOutside } from 'utilities/hooks';
import BottomSidebar from 'components/bottom_side_bar';

type DatePickerProps = {
    range: Array<Date>;
    setRange: (e: Array<Date>) => void;
    min_date?: Date;
    dropdown?: boolean;
};

const input_value = (value) => (value ? getISODateForDatePicker(value) : '');

const CustomRangePicker = ({ range, setRange, min_date, dropdown = false }: DatePickerProps) => {
    const { t } = useTranslation();
    const { is_dark_mode, is_mobile } = useSelector((state: RootState) => state.platform);
    const [is_open, setOpen] = useState(false);

    const wrapper_ref = useRef<HTMLDivElement>(null);
    useClickOutside(wrapper_ref, () => setOpen(false));
    return (
        <div ref={wrapper_ref} className={styles.date}>
            <div className={styles['input-container']}>
                <div
                    className={classNames(styles.input, { [styles.pointer]: dropdown })}
                    {...(dropdown ? { onClick: () => setOpen(true) } : {})}
                >
                    {input_value(range[0]) ? (
                        <span className={styles.value}>{input_value(range[0])}</span>
                    ) : (
                        <span className={styles.placeholder}>{t('start_date')}</span>
                    )}
                </div>
                <div
                    className={classNames(styles.input, { [styles.pointer]: dropdown })}
                    {...(dropdown ? { onClick: () => setOpen(true) } : {})}
                >
                    {input_value(range[1]) ? (
                        <span className={styles.value}>{input_value(range[1])}</span>
                    ) : (
                        <span className={styles.placeholder}>{t('end_date')}</span>
                    )}
                </div>
            </div>
            <div
                className={classNames(styles.calendar, {
                    [styles.dropdown]: dropdown && is_open,
                    [styles.dropdown_close]: dropdown && !is_open,
                })}
            >
                <Calendar
                    className={'range-calendar'}
                    onChange={setRange}
                    value={range}
                    maxDate={new Date()}
                    {...(min_date ? { minDate: new Date(min_date) } : {})}
                    prev2Label={null}
                    next2Label={null}
                    prevLabel={
                        <img alt="left side" src={`img/icons/caret-circle-left${is_dark_mode ? '-dark' : ''}.svg`} />
                    }
                    nextLabel={
                        <img alt="right side" src={`img/icons/caret-circle-right${is_dark_mode ? '-dark' : ''}.svg`} />
                    }
                    selectRange
                />
            </div>
            {dropdown && is_mobile && (
                <BottomSidebar is_open={is_open} setOpen={setOpen} height={515}>
                    <div className={styles['calendar-container-mobile']}>
                        <div style={{ padding: '0 2rem' }}>
                            <Calendar
                                className={'range-calendar'}
                                onChange={setRange}
                                value={range}
                                maxDate={new Date()}
                                {...(min_date ? { minDate: new Date(min_date) } : {})}
                                prev2Label={null}
                                next2Label={null}
                                prevLabel={
                                    <img
                                        alt="left side"
                                        src={`img/icons/caret-circle-left${is_dark_mode ? '-dark' : ''}.svg`}
                                    />
                                }
                                nextLabel={
                                    <img
                                        alt="right side"
                                        src={`img/icons/caret-circle-right${is_dark_mode ? '-dark' : ''}.svg`}
                                    />
                                }
                                selectRange
                            />
                            <button
                                className={classNames(styles.submit, 'button-outline', 'button-48')}
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                {t('close')}
                            </button>
                        </div>
                    </div>
                </BottomSidebar>
            )}
        </div>
    );
};
export default CustomRangePicker;
