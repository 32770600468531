// import classNames from 'classnames';
// import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import GoogleOAuth from 'components/google_oauth';
import LanguagePicker from 'components/i18n';
// import SignupForm from 'components/signup_form';
import SignupIntro from 'components/signup_intro';
import TermsPolicy from 'components/terms_policy';
import ThemeToggle from 'components/theme_toggle';
// import { T } from 'types/common';
import { RIGHTS } from 'utilities/constants';

import styles from './index.module.scss';

// type IntroModalProps = {
//     handleClose: () => void;
//     children: JSX.Element;
//     t: T;
// };
// const IntroModal = ({ handleClose, children, t }: IntroModalProps) => {
//     const [is_checked, setChecked] = useState(false);

//     return (
//         <>
//             <div className={styles.modal} onClick={handleClose}></div>
//             <div className={styles['modal-main']}>
//                 {children}
//                 <div className={styles['intro-options']}>
//                     <div className={styles['checkbox-item']} onClick={() => setChecked((old) => !old)}>
//                         <img alt="checkbox icon" src={`/img/icons/${is_checked ? 'selected' : 'unselected'}.svg`} />
//                         <span>{t('dont_show_again')}</span>
//                     </div>
//                     <button
//                         type="submit"
//                         className={classNames(styles.submit, 'button-primary', 'button-48')}
//                         onClick={() => {
//                             if (is_checked) localStorage.setItem('client.accepted_intro', '1');
//                             handleClose();
//                         }}
//                     >
//                         <span>{t('ok')}</span>
//                     </button>
//                 </div>
//             </div>
//         </>
//     );
// };

type MobileSignupProps = {
    is_dark_mode: boolean;
};

export default function MobileSignup({ is_dark_mode }: MobileSignupProps) {
    const { t } = useTranslation();
    // const [is_modal_open, setModalOpen] = useState(() => {
    //     const accepted_intro = localStorage.getItem('client.accepted_intro');
    //     return !accepted_intro;
    // });
    return (
        <>
            {/* {is_modal_open && (
                <IntroModal t={t} handleClose={() => setModalOpen(false)}>
                </IntroModal>
            )} */}
            <div className={styles.container}>
                <SignupIntro is_dark_mode={is_dark_mode} t={t} show_term_privacy={false} />
                {/* <div className={styles['form-container']}>
                    <SignupForm is_dark_mode={is_dark_mode} />
                    <div className={styles['form-separator']}>
                        <div className={styles['form-separator-line']} />
                        <span>{t('or')}</span>
                        <div className={styles['form-separator-line']} />
                    </div>
                </div> */}
                <GoogleOAuth />
                <div className={styles['already-have-account']}>
                    <p>
                        <Link to="/log-in">
                            <span>{t('signup.local.have_account')}</span>
                        </Link>
                    </p>
                </div>
                <div className={styles['terms-policy-container']}>
                    <TermsPolicy t={t} />
                </div>
                <div className={styles.options}>
                    <LanguagePicker should_update_query_string={false} />
                    <ThemeToggle />
                </div>
                <span className={styles.rights}>{RIGHTS}</span>
            </div>
        </>
    );
}
